<div class="h100">
    <div class="wrapper">
        <h2 mat-dialog-title>{{ 'Auth' |translate}}</h2>

        <p>Пожалуйста, заполните следующие поля для входа в систему:</p>

        <!-- <mat-dialog-content> -->
        <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
            <mat-form-field appearance="outline">
                <mat-label>{{'Email'|translate}}</mat-label>
                <input matInput [placeholder]="'Email'|translate" formControlName="email" required>
                <mat-error *ngIf="checkoutForm.controls['email'].invalid && checkoutForm.controls['email'].touched">
                    {{'Invalid'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'Password'|translate}}</mat-label>
                <input matInput [placeholder]="'Password'|translate" formControlName="password" required type="password"
                       autocomplete="on">
                <mat-error
                        *ngIf="checkoutForm.controls['password'].invalid && checkoutForm.controls['password'].touched">
                    {{'Required'|translate}}
                </mat-error>
            </mat-form-field>

            <div *ngIf="cnt>5">
                <re-captcha formControlName="recaptchaReactive" required></re-captcha>
                <div class="mat-mdc-form-field-error" *ngIf="checkoutForm.controls['recaptchaReactive'].invalid">
                    {{'Required'|translate}}
                </div>
            </div>

            <div class="gray">Если забыли пароль, можете его <a routerLink="/password-reset">сбросить</a></div>

            <mat-dialog-actions>
                <button mat-raised-button color="primary" type="submit">{{'Login'|translate}}</button>
            </mat-dialog-actions>

        </form>
        <!-- </mat-dialog-content> -->

    </div>
</div>
