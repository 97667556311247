import {AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AppState} from "../core/ngrx/app.state";
import {Store} from "@ngrx/store";
import {LoadingService} from "../core/components/loader/loading.service ";
import {delay, Subscription} from "rxjs";
import {AuthService} from "../core/services/auth.service";
import {AuthInfo} from "../core/models/auth-info";
import {USER_INIT} from "../core/ngrx/store/action/user.action";
import {UserGetService} from "../core/ngrx/services/user/user-get.service";
import {
  ChangeUserSettingsParams,
  ChangeUserSettingsService
} from "../core/ngrx/services/user/change-user-settings.service";
import {ChageSettingsEffect} from "../core/shared/chage-settings.effect";
import {User} from "../core/models/user";
import {TickerGetService} from "../core/ngrx/services/ticker/ticker-get.service";
import {Settings} from "../core/models/settings";
import {verifySupportedTypeScriptVersion} from "@angular/compiler-cli/src/typescript_support";
import {SET_SETTING, UPDATE_SETTING} from "../core/ngrx/store/action/setting.action";

@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [TickerGetService, UserGetService, ChangeUserSettingsService],
})

export class MainComponent implements OnInit, AfterContentInit, OnDestroy {
  title: string = "β TRADER";
  loading: boolean = true;
  email: string = "";
  // private user: User = new User()
  private userInfo: AuthInfo | any;
  private isShowLoader: any;
  isAuth: boolean = false;
  isPayed: boolean = false;
  private _sub1: Subscription | undefined;
  private _sub2: Subscription | undefined;
  private _sub3: Subscription | undefined;
  private _sub4: Subscription | undefined;
  private _sub5: Subscription | undefined;
  private user: Readonly<User> | undefined;


  constructor(
    private _store: Store<AppState>,
    private _loading: LoadingService,
    public authService: AuthService,
    private _changeSettings: ChangeUserSettingsService,
    private _userGetService: UserGetService,
    private _tickerGetService: TickerGetService,
    private _effect: ChageSettingsEffect
  ) {
    this._userGetService.execute(Object);
    this._tickerGetService.execute(Object);
  }

  //для переключения темы
  public isLightTheme = true;

  onThemeSwitchChange() {
    this.isLightTheme = !this.isLightTheme;

    if ((this.user?.id ?? 0) > 0) {
      let settings = Object.assign(new Settings(), JSON.parse(this.user?.settings ?? ""))
      let needUpdate = false;
      if (settings.isLightTheme != this.isLightTheme)
        needUpdate = true;
      settings.isLightTheme = this.isLightTheme;
      if (needUpdate) {
        this._store.dispatch(SET_SETTING({settings: JSON.stringify(settings)}));
      }
    }
    setTimeout(() => {
      document.body.setAttribute(
        'data-theme',
        this.isLightTheme ? 'light' : 'dark'
      );
    }, 100);

  }

  //для переключения темы

  ngOnInit() {
    if (!this._sub1)
      this._sub1 = this.authService.item$.subscribe(u => {
        this.populate();
      })

    if (!this._sub2)
      this._sub2 = this._store.select('user').subscribe(user => {
        if (user) {
          this.isAuth = user.id > 0;
          let now = new Date();
          this.isPayed = user.active_to * 1000 >= now.getTime();
          this.user = user;
          if (user.settings) {
            const settings = JSON.parse(user.settings);
            this.isLightTheme = settings.isLightTheme ?? true;
            if (!this.isLightTheme) {
              document.body.setAttribute(
                'data-theme',
                this.isLightTheme ? 'light' : 'dark'
              );
            }
          }


          if (this.isAuth) {
            this._store.dispatch(UPDATE_SETTING({settings: user.settings}));
          }

        }
      });

    if (!this._sub3)
      this._sub3 = this._store.select('showLoader').subscribe(m => {
        this.isShowLoader = m;
      });

    if (!this._sub4)
      this._sub4 = this._effect.chageSettingsEffect.subscribe(x => {
        const user_id = (this.user?.id ?? 0);
        if (user_id > 0 && this.isAuth && x.settings) {
          //console.log('db update', x.settings);
          this._changeSettings.execute(new ChangeUserSettingsParams(user_id, x.settings));
        }
      });
  }

  ngAfterContentInit(): void {
    if (!this._sub5)
      this._sub5 = this._loading.loadingSub
        .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
        .subscribe((loading) => {
          if (loading && this.isShowLoader)
            this.loading = loading;
          else
            this.loading = false;
        });

  }

  private populate() {
    if (this.authService.isAuth) {
      this.userInfo = this.authService.authUserInfo();
      this.email = this.userInfo.email
      if (this.isPayed) {

      }
      // if (this.user.id == 0)
      //   this._userGetService.execute(Object).subscribe(u => {
      //     u.settings = JSON.parse(u.settings)
      //     this._store.dispatch(USER_INIT({user: u}))
      //   });
    } else {
      // this.user = new User()
      this._store.dispatch(USER_INIT({user: new User()}))
    }
  }

  ngOnDestroy(): void {
    this._sub1?.unsubscribe();
    this._sub2?.unsubscribe();
    this._sub3?.unsubscribe();
    this._sub4?.unsubscribe();
    this._sub5?.unsubscribe();
  }
}
