import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Ticker} from "../../core/models/ticker";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/ngrx/app.state";
import {User} from "../../core/models/user";
import {UserGetService} from "../../core/ngrx/services/user/user-get.service";
import {Settings} from "../../core/models/settings";
import {SET_SETTING} from "../../core/ngrx/store/action/setting.action";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  providers: [UserGetService]
})
export class AlertComponent implements OnInit, OnDestroy {
  get isValid(): boolean {
    return this._isValid;
  }

  alertForm: any;
  tickerList: Ticker[] | any;
  stepList: any[] = [
    {value: 300, name: '5М'},
    {value: 600, name: '10М'},
    {value: 900, name: '15М'},
    {value: 1200, name: '20М'},
    {value: 1800, name: '30М'},
    {value: 3600, name: '1Ч'},
    {value: 86400, name: '1Д'},
  ];
  directions: any = [
    {'code': 'S', 'name': 'Короткие позиции'},
    {'code': 'L', 'name': 'Длинные позиции'},
  ];
  positions: any = [
    {'code': 'FIZ', 'name': 'ФЛ', 'fullname': 'Физические лица'},
    {'code': 'YUR', 'name': 'ЮЛ', 'fullname': 'Юридические лица'},
  ];
  private _sub: Subscription | undefined;
  private _sub1: Subscription | undefined;
  private _sub2: Subscription | undefined;
  private _sub3: Subscription | undefined;
  private _settings: Settings | undefined;
  public u: User | any;
  hasTelegram: boolean = false;
  private _isValid: boolean = false;
  bot: string='';

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _store: Store<AppState>,
    private _userGetService: UserGetService
  ) {
    this.alertForm = this.formBuilder.group({
      id: new FormControl(),
      ticker: new FormControl(data.ticker),
      position: new FormControl(data.position),
      timeframe: new FormControl(data.timeframe),
      direction: new FormControl(null, [Validators.required]),
      value: new FormControl(null, [Validators.required]),
      name: new FormControl(""),
      content: new FormControl("", [Validators.required]),
      email: new FormControl(false),
      telegram: new FormControl(false),
    });
    this._sub3 = this.alertForm.valueChanges.subscribe((data: any) => {
      this._isValid = data['content'] && data['direction'] && data['position'] && data['ticker'] && data['timeframe']
        && (data['value']<0 ||  data['value']>0)
    })
    this._userGetService.execute(Object);
    this.bot = environment.telegram_bot
  }

  ngOnInit() {
    // подписываемся на изменение пользователя
    this._sub = this._store.select('user').subscribe(user => {
      this.u = user
      this.hasTelegram = user.telegram !== null && user.telegram !== undefined && user.telegram !== '';
      console.log(user,user.telegram, this.hasTelegram);
    });
    // подписываемся на изменение списка тикеров
    this._sub1 = this._store.select('tickers').subscribe(tickers => {
      this.tickerList = Object.assign([], tickers);
    });
    // подписываемся на изменение настроек
    this._sub2 = this._store.select('setting').subscribe(setting => {
      this._settings = Object.assign(new Settings(), setting);
    });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
    this._sub1?.unsubscribe();
    this._sub2?.unsubscribe();
    this._sub3?.unsubscribe();
  }

  onSubmit(value: any) {
    if(this._settings){
      let settings = this._settings;
      if (!settings.notify)
        settings.notify = [];
      for (let i = 0; i < settings.notify.length; i++){
        settings.notify[i].id = i;
      }
      value.id = settings.notify.length;
      settings.notify.push(value);
      this._store.dispatch(SET_SETTING({settings: JSON.stringify(settings)}));
      // console.log(settings);
    }
  }

  GetPositionName(value: any) {
    return this.positions.find((p: any) => p.code == value)?.name ?? "";
  }

  GetDirectionName(value: any) {
    return this.directions.find((p: any) => p.code == value)?.name ?? "";
  }


}
