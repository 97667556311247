import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {UtilService} from "../../../services/util.service";
import {Daily} from "../../../models/daily";
import {DAILY_INIT} from "../../store/action/daily.action";

@Injectable()
export class DailyGetService extends BaseService<DailyParam> {
    override execute(obj: DailyParam) {
        this.url = "/daily?ticker={code}&dt={dt}&days={days}"
        super.execute(obj)
        this.get(obj)
            .subscribe((r: any) => {
              let time = new Date();
              let daily = new Daily(time, obj.code, r);
              this._store.dispatch(DAILY_INIT({daily}));
              // console.log(daily)
            })
    }
}

export class DailyParam {
  code: string = "";
  dt:  string = "";
  d:  Date;
  days: number = 20;
  constructor(code: string, d: Date, days: number) {
    this.dt = UtilService.formatToSring(d)
    this.d = d
    this.code = code
    this.days = days
  }
}

