<!-- Заголовок окна -->
<div class="alert-head">
    <h2 mat-dialog-title>{{'Create Alert'|translate}}</h2>
    <mat-dialog-actions class="close" style="margin: 0">
        <label type="button" mat-flat-button [mat-dialog-close]="true" class="button-close">
            <mat-icon class="close-icon">close</mat-icon>
        </label>
    </mat-dialog-actions>
</div>
<!-- Форма -->
<form [formGroup]="alertForm" (ngSubmit)="onSubmit(alertForm.value)">
    <mat-dialog-content style="display: flex;flex-direction: column;">
        <div class="alert-set">
            <div class="alert-ticker">
                <section>
                    <mat-select formControlName="ticker" matTooltip="Инструмент">
                        <mat-select-trigger>
                            <span>{{alertForm.controls['ticker'].value}}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let ticker of tickerList" [value]="ticker.code">
                            {{ticker.code}} - {{ticker['name']}}
                        </mat-option>
                    </mat-select>
                </section>
            </div>

            <div class="alert-tf">
                <section>
                    <mat-select formControlName="timeframe" matTooltip="Таймфрейм">
                        <mat-option *ngFor="let step of stepList" [value]="step.value">
                            {{step.name}}
                        </mat-option>
                    </mat-select>
                </section>
            </div>

            <div class="alert-position">
                <section>
                    <mat-select formControlName="position" matTooltip="Позиции">
                        <mat-select-trigger>
                            <mat-icon>people</mat-icon>
                            <span>{{GetPositionName(alertForm.controls['position'].value)}}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let position of positions" [value]="position.code">
                            {{position.fullname}}
                        </mat-option>
                    </mat-select>
                </section>
            </div>

            <div class="alert-direction">
                <section>
                    <mat-select formControlName="direction" matTooltip="Направление">
                        <mat-select-trigger>
                            <mat-icon>swap_vert</mat-icon>
                            <span [style.color]="alertForm.controls['direction'].value =='L' ? 'green' : 'red'">{{alertForm.controls['direction'].value}}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let direction of directions" [value]="direction.code">
                            {{direction.code}} - {{direction.name}}
                        </mat-option>
                    </mat-select>
                </section>
            </div>

            <div class="alert-value">
                <section>
                    <input matInput matTooltip="Значение" autocomplete="off" tabindex="0" autofocus formControlName="value" type="number" class="font-color" min="0">
                </section>
            </div>

        </div>

        <mat-divider></mat-divider>
        <br>
        <mat-form-field appearance="outline">
            <mat-label>{{'Head'|translate}}</mat-label>
            <input matInput [placeholder]="'Email Head'|translate" formControlName="name" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'Content'|translate}}</mat-label>
            <textarea matInput [placeholder]="'Content'|translate" formControlName="content" required
                      autocomplete="off">
            </textarea>
            <mat-hint></mat-hint>
            <mat-error *ngIf="alertForm.controls['content'].invalid && alertForm.controls['content'].touched">
                <span *ngIf="alertForm.controls['content'].errors.required">{{'Required'|translate}}.</span>
            </mat-error>
        </mat-form-field>

        <mat-divider></mat-divider>

        <section>
            <mat-checkbox formControlName="email" labelPosition="after">{{'Email'|translate}}</mat-checkbox>
        </section>
        <section>
            <mat-checkbox *ngIf="!hasTelegram" formControlName="telegram" labelPosition="after" [disabled]="true" (click)="$event.preventDefault()">
                {{'Telegram'|translate}}
            </mat-checkbox>
            <mat-checkbox *ngIf="hasTelegram" formControlName="telegram" labelPosition="after">
                {{'Telegram'|translate}}
            </mat-checkbox>
            <a *ngIf="!hasTelegram" href="https://t.me/{{bot}}?start=subscribe_{{u?.id}}" target="_blank" style="margin-left: 25px">{{'Configure'|translate}}</a>
        </section>
    </mat-dialog-content>

    <mat-dialog-actions class="alert-create-buttons" align="end">
        <button mat-button [mat-dialog-close]="true">{{'Cancel'|translate}}</button>
        <button mat-raised-button [mat-dialog-close]="true" color="primary" type="submit" [disabled]="!isValid">
            {{'Create'|translate}}
        </button>
    </mat-dialog-actions>
</form>
