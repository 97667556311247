import { createAction, props } from "@ngrx/store";

export const NEED_LOAD = createAction(
  '[needLoad].NeedLoad',
  props<{needLoad: boolean}>()
)

export const PAGE_LOADED = createAction(
  "[Page].Loaded",
  props<{loadedCount:number}>()
)

