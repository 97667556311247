import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {TARIFFS_INIT} from "../../store/action/tariffs.action";
import {UtilService} from "../../../services/util.service";


@Injectable()
export class OrderService extends BaseService<CreateOrderParam> {
  override execute(obj: CreateOrderParam) {
    this.url = "/order";
    super.execute(obj);
    return this.post(obj);
  }
}

export class CreateOrderParam {
  constructor(order_num: string, user_id: number, tariff_id: number, payment_id: number) {
    this.order_num = order_num;
    this.user_id = user_id;
    this.tariff_id = tariff_id;
    this.payment_id = payment_id;
  }

  order_num: string = ""
  user_id: number = 0
  tariff_id: number = 0
  payment_id: number = 0
}
