import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {Timerange} from "../../../models/timerange";
import {TIMERANGE_INIT} from "../../store/action/timerange.action";
import {NEED_LOAD, PAGE_LOADED} from "../../store/action/need-load.action";

@Injectable()
export class GetService extends BaseService<Param> {
    override execute(obj: Param) {
        this._store.dispatch(NEED_LOAD({'needLoad': false}));
        this.url = "/timerange/{type}?ticker={code}&clgroup={clgroup}&page={page}"
        super.execute(obj, false)
        return this.get(obj)
            .subscribe((r: any) => {
                if (r) {
                    if (r.total_count > 0) {
                        let timerange = new Timerange(obj.clgroup, obj.code, r.results, obj.page, obj.type);
                        this._store.dispatch(TIMERANGE_INIT({timerange}));
                        this._store.dispatch(PAGE_LOADED({'loadedCount': obj.page}));
                    } else {
                        this._store.dispatch(PAGE_LOADED({'loadedCount': 0}));
                    }

                }
            });
    }
}

export class Param {
    type: string = "";
    code: string = "";
    clgroup: string = "";
    page: number = 1;

    constructor(type: string, code: string, clgroup: string, page: number) {
        this.type = type
        this.code = code
        this.clgroup = clgroup
        this.page = page
    }
}

