import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl} from "@angular/forms";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/ngrx/app.state";
import {Title} from "@angular/platform-browser";
import {TariffService} from "../../core/ngrx/services/tariff/tariff.service";
import {Tariff} from "../../core/models/tariff";
import {CreateOrderParam, OrderService} from "../../core/ngrx/services/order/order.service";
import {AuthService} from "../../core/services/auth.service";
import {Order} from "../../core/models/order";
import {Guid} from 'guid-typescript';
import {environment} from "../../../environments/environment";
import { sha256 } from 'js-sha256';
import { HttpClient } from "@angular/common/http";
import {MatRadioChange} from "@angular/material/radio";
import {Subscription} from "rxjs";

@Component({
  selector: 'pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
  providers: [OrderService, TariffService]
})

export class PayComponent implements OnInit, OnDestroy {

  @ViewChild('pay') payFormElement: any;

  payForm: any;
  tariffs!: Tariff[];
  orderId: string = "";
  private _sub1: Subscription | undefined;
  constructor(
    private _http: HttpClient,
    private _formBuilder: FormBuilder,
    private _store: Store<AppState>,
    private _titleService: Title,
    private _tariffService: TariffService,
    private _isAuthService: AuthService,
    private _order: OrderService
  ) {
    let user = this._isAuthService.authUserInfo();

    this.payForm = this._formBuilder.group({
      // tariff: new FormControl(),
      DATA: new FormControl({
        "Email": user.email
      }),
      tariff: new FormControl(),
      Amount: new FormControl(),
      Token: new FormControl(),
      TerminalKey: new FormControl(environment.terminal),
      OrderId: null,
      OrderNum: null,
      Description: new FormControl('"Оплата подписки < Betatrader.ru"'),
    })

  }

  ngOnInit(): void {
    this._sub1 = this._store.select('tariffs').subscribe(tariffs => {
      this.tariffs = Object.assign([], tariffs).filter((t: Tariff) => t.is_active)
      if (this.tariffs.length > 0) {
        const tf = this.tariffs[0];
        this.payForm.controls['Description'].setValue(`Оплата подписки "${tf.name}"`);
        this.payForm.controls['Amount'].setValue(tf.cost * 100);
        this.payForm.controls['tariff'].setValue(tf.id);
        let values = this.payForm.value;
        values['Password'] = environment.password;
        this.payForm.controls['Token'].setValue(this.getToken(values));
        //console.log(values, this.getToken(values))
      }
    })
    this._titleService.setTitle("Оплата подписки < Betatrader.ru");
    this._tariffService.execute(Object);
  }

  private getToken(values: any): string {
    const str = values['Amount'] + values['Description'] + values['OrderId'] + values['Password'] + values['TerminalKey'];
    return sha256(str);
  }

  onSubmit(value: any) {
    let user = this._isAuthService.authUserInfo();
    const headers = { 'content-type': 'application/json'}
    this.orderId = Guid.create().toString();
    console.log(this.orderId);
    value.OrderId = this.orderId;
    value.OrderNum = this.orderId;
    const body=JSON.stringify(value);
    this._http.post('https://securepay.tinkoff.ru/v2/Init', body, {'headers':headers}).subscribe((response:any)=>{
      if(response.Success){
        this._order.execute(new CreateOrderParam(this.orderId, user.user_id, value.tariff, response.PaymentId)).subscribe((o: Order) => {
          //this.payFormElement.nativeElement.submit();
          window.open(response.PaymentURL, "_blank");
        });
      }
    })
    return false;


  }

  change_tf($event: MatRadioChange) {
    const tf = this.tariffs.find(t => t.id == $event.value)
    if (tf) {
      this.payForm.controls['Description'].setValue(`Оплата подписки "${tf.name}"`);
      this.payForm.controls['Amount'].setValue(tf.cost * 100);
    }
  }

  ngOnDestroy(): void {
    this._sub1?.unsubscribe();
  }
}
