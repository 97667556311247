import {Injectable} from '@angular/core';
import {WebsocketService} from "./websocket.service";
import {environment} from "../../../environments/environment";
import {AuthService, TOKEN_NAME} from "../services/auth.service";
import {Store} from "@ngrx/store";
import {AppState} from "../ngrx/app.state";

@Injectable({
    providedIn: 'root'
})

export class BaseWebsocketService<T extends object> {
    private _apiUrl: string = "";
    public url: string = "";
    public urlPrepared: string = "";
    protected _model?: T;

    constructor(private _wsService: WebsocketService, protected _store: Store<AppState>) {
    }

    public setApiUrl(apiurl: string) {
        this._apiUrl = apiurl;
    }

    private getFullUrl(url: string): string {
        let token = AuthService.getCookie(TOKEN_NAME);
        if(token != null) {
            return this._apiUrl + url + '?token={' + token + '}';
        }
        return this._apiUrl + url;
    }

    public execute(obj: T | any) {
        this.setApiUrl(environment.baseWs);
        this.urlPrepared = this.url;
        if (this.isNeedPrepareUrl()) {
            this._model = obj;
            this.prepareUrl();
        }
        return this._wsService.connect(this.getFullUrl(this.urlPrepared));
    };

    public stop(obj: T | any) {
        this.setApiUrl(environment.baseWs);
        this.urlPrepared = this.url;
        if (this.isNeedPrepareUrl()) {
            this._model = obj;
            this.prepareUrl();
        }
        return this._wsService.disconnect(this.getFullUrl(this.urlPrepared));
    };
    private prepareUrl() {
        if (this._model != undefined) {
            for (let [element, value] of Object.entries(this._model)) {
                let pattern = '{' + element + '}';
                if (this.urlPrepared && this.urlPrepared.indexOf(pattern) > 0) {
                    this.urlPrepared = this.urlPrepared.replace(pattern, value);
                }
            }
        }
    }

    private isNeedPrepareUrl(): boolean {
        return this.url.indexOf('{') > 0;
    }
}
