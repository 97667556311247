import {Table2} from "./table2";
import {IData} from "./i-data";

export class Cumulative implements IData {
    constructor(dt?: Date, ticker?: string, table?: Table2[]) {
        this.dt = dt ?? new Date();
        this.tickerCode = ticker ?? "";
        this.table = table ?? [];
    }
    dt: Date = new Date();
    tickerCode: string = "";
    table: Table2[] = [];

}
