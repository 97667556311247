import {Pipe, PipeTransform} from "@angular/core";
import {UtilService} from "../services/util.service";

@Pipe({name: 'numberToDtStr'})
export class NumberToDtStrPipe implements PipeTransform {
    transform(value: number | undefined, type: string = 'shorTime') {
        if (value != undefined) {
            let d = new Date(value * 1000);
            UtilService.convertTZ(d, "Europe/Moscow")
            switch (type) {
                case 'date':
                    // d = new Date((value - 3*3600) * 1000);
                    return d.toLocaleDateString();
                case 'dateTime':
                    // d = new Date((value - 3*3600) * 1000);
                    return d.toLocaleString();
                case 'dateTimeShort':
                    // d = new Date((value - 3*3600) * 1000);
                    return d.toLocaleDateString() + this.addZero(d.getHours(), false) + ":" + this.addZero(d.getMinutes(), false);
                case 'fullTime':
                    // d = new Date((value - 3*3600) * 1000);
                    return this.addZero(d.getHours(), false) + ":" + this.addZero(d.getMinutes(), false) + ":" + this.addZero(d.getSeconds(), false)
                case 'shorTime':
                    // d = new Date((value - 3*3600) * 1000);
                    return this.addZero(d.getHours(), false) + ":" + this.addZero(d.getMinutes(), false)
            }
        }
        return "";
    }

    private addZero(i: number, nine: boolean = true): string {
        if (nine)
            return i < 9 ? "0" + i : i.toString();
        return i < 9 ? "0" + i : i.toString();
    }
}
