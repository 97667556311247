import {Injectable} from '@angular/core';
import {observable, Observable, Observer, Subject} from "rxjs";

@Injectable()
export class WebsocketService {

  constructor() {
  }

  private subject: Subject<MessageEvent> | undefined;
  private ws: any;

  public connect(url: string): Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      let urls = url.split('?')
      console.log("Successfully connected: " + urls[0]);
    }
    return this.subject;
  }

  public disconnect(url: string): void {
    if (this.subject) {
      this.subject.unsubscribe();
    }
    if (this.ws)
      this.ws.close();
    this.subject = undefined;
    // let urls = url.split('?')
    console.log("Successfully disconnected");
  }

  private create(url: string): Subject<MessageEvent> {
    this.ws = new WebSocket(url);
    let observable = Observable.create(
      (observer: Observer<MessageEvent>) => {
        this.ws.onmessage = observer.next.bind(observer);
        this.ws.onerror = observer.error.bind(observer);
        this.ws.onclose = observer.complete.bind(observer);
        return this.ws.close.bind(this.ws);
      }
    );

    let observer = {
      next: (data: Object) => {
        console.log(data)
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send(JSON.stringify(data));
        }
      }
    };
    return Subject.create(observer, observable);
  }
}
