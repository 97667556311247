import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs';
import jwt_decode from "jwt-decode";
import {AuthInfo} from "../models/auth-info";
import {Store} from "@ngrx/store";
import {AppState} from "../ngrx/app.state";
import {USER_INIT} from "../ngrx/store/action/user.action";
import {UserGetService} from "../ngrx/services/user/user-get.service";
import {User} from "../models/user";

export const TOKEN_NAME = 'token'
export const TOKEN_EXPIRED_PERIOD = 'tokenExpiredPeriod'

@Injectable()
export class AuthService {


  constructor(private _store: Store<AppState>) {
  }

  get isAuth(): boolean {

    let model = this.authUserInfo();
    let now = new Date();
    let hasToken = false;
    if (AuthService.getCookie(TOKEN_NAME))
      hasToken = true;
    // console.log( 'isAuth', (model?.expires * 1000 >= now.getTime()) && hasToken)
    return (model?.expires * 1000 >= now.getTime()) && hasToken;
  }

  get IsPayed(): boolean {

    let model = this.authUserInfo();
    let now = new Date();
    let hasToken = false;
    if (AuthService.getCookie(TOKEN_NAME))
      hasToken = true;
    // console.log( 'isAuth', (model?.expires * 1000 >= now.getTime()) && hasToken)
    return (model?.active_to * 1000 >= now.getTime()) && hasToken;
  }

  // Observable navItem source
  private _navIsAuth = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  item$ = this._navIsAuth.asObservable();


  // service command
  setIsAuth(n: boolean) {
    this._navIsAuth.next(n);
  }

  public authUserInfo(): AuthInfo {
    let token = AuthService.getCookie(TOKEN_NAME)
    //let token = localStorage.getItem(TOKEN_NAME)
    if (typeof token != 'undefined' && token) {
      return jwt_decode(token);
    }
    return new AuthInfo();
  }

  public getRemainingTime() {
    let model = this.authUserInfo()
    let now = new Date();
    return Math.ceil((model?.expires * 1000 - now.getTime()) / 1000);
  }

  public setConstTimeToLife(s: number) {
    localStorage.setItem(TOKEN_EXPIRED_PERIOD, s.toString())
  }

  public getConstTimeToLife() {
    let token_expired = localStorage?.getItem(TOKEN_EXPIRED_PERIOD)
    if (!token_expired)
      return 60;
    return +token_expired;
  }

  static getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;
    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  static setCookie(name: string, value: string, expireHours: number, path: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireHours * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}; Secure; IsEssential;`;
    document.cookie = `expires_${name}=${expires}; ${expires}${cpath}; Secure; IsEssential;`;
    // document.cookie = `${name}=${value}; ${expires}${cpath}; Secure; IsEssential; HttpOnly`;
    // document.cookie = `expires_${name}=${expires}; ${expires}${cpath}; Secure; IsEssential; HttpOnly`;
  }

  static deleteCookie(name: string) {
    AuthService.setCookie(name, '', -1);
    AuthService.setCookie(`expires_${name}`, '', -1);
  }

  public renewCookie(name: string) {
    let value = AuthService.getCookie(name);
    let expiresDate: Date = new Date(AuthService.getCookie(`expires_${name}`));
    let nowDate: Date = new Date();
    if ((expiresDate.getTime() + 60 * 60 * 1000) > nowDate.getTime()) {
      AuthService.deleteCookie(name);
      AuthService.setCookie(name, value, 30 * 24);
    }
  }
}
