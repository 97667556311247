import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {FooterComponent} from './footer/footer.component';
import {TranslateModule} from '@ngx-translate/core';
import {LoaderComponent} from './loader/loader.component';
import {MaterialModule} from "../shared/modules/material.module";
import {HeaderComponent} from "./header/header.component";
import {LineChartComponent} from './charts/line-chart/line-chart.component';
import {NgxEchartsModule} from "ngx-echarts";
import {BarChartComponent} from './charts/bar-chart/bar-chart.component';
import {NotifyComponent} from './notify/notify.component';
import {CandleStickChartComponent} from './charts/candle-stick-chart/candle-stick-chart.component';
import {LightWeightChartComponent} from './charts/light-weight-chart/light-weight-chart.component';
import {ConfirmDialogComponent} from './windows/confirm-dialog/confirm-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    TranslateModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    })
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    LineChartComponent,
    BarChartComponent,
    NotifyComponent,
    CandleStickChartComponent,
    LightWeightChartComponent,
    ConfirmDialogComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    LineChartComponent,
    BarChartComponent,
    NotifyComponent,
    CandleStickChartComponent,
    LightWeightChartComponent,
    ConfirmDialogComponent,
  ],
})
export class ComponentsModule {
}
