export class Order {
  id: number = 0
  order_num: string = ""
  order_dt: Date = new Date()
  user_id: number = 0
  ispayed: boolean = false
  summa: number = 0
  tarif_id: number = 0
  payed_dt: Date = new Date()
  operation_id: number = 0
}
