<mat-toolbar class="control-menu">
  <form class="d-flex">
    <!-- инструмент -->
    <div class="small-ticker-control flex-column">
      <section>
        <mat-select [formControl]="selectedTickerCodeControl" matTooltip="Инструмент">
          <mat-select-trigger><span class="active-ticker">{{ selectedTickerCode }}</span></mat-select-trigger>
          <mat-option *ngFor="let ticker of tickerList" [value]="ticker.code">{{ ticker.code }}- {{ ticker['name'] }}
          </mat-option>
        </mat-select>
      </section>
    </div>
    <div class="light-splitter"></div>
    <!-- дата -->
    <div class="small-date-control  flex-column">
      <section class="date-section">
        <input matInput [matDatepicker]="dp"
               class="date-input"
               autocomplete="off"
               matTooltip="Дата"
               (dateChange)="dateChange($event)"
               (click)="dp.open()"
               [min]="minDate" [max]="maxDate"
               [formControl]="tradeDateControl">
        <mat-datepicker-toggle class="date-icon" matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
      </section>
    </div>
    <div class="light-splitter" *ngIf="isAuth && isPayed"></div>
    <!-- исторический режим -->
    <div class="small-select-time flex-column" *ngIf="isAuth && isPayed">
      <section>
        <mat-checkbox matTooltip="Исторический режим" [formControl]="historicalControl"
                      *ngIf="isAuth && isPayed">
          <mat-icon [class]="historicalControl.value ? 'active-item' : ''">history</mat-icon>
        </mat-checkbox>
      </section>
    </div>
    <!-- обновить -->
    <div class="small-refresh flex-column">
      <section>
        <button mat-icon-button matTooltip="Обновить" (click)="autorenew()">
          <mat-icon aria-setsize="16">autorenew</mat-icon>
        </button>
      </section>
    </div>
  </form>
</mat-toolbar>


<div class="total-table" style="padding-top: 60px">
  <table style="margin-bottom: 10px;" class="table1" *ngIf="total.table[0]">
    <tbody>
    <tr>
      <th rowspan="2" class="td-info">
        <div class="td-text-uptime">{{ total.table[0].datetime | date: 'dd.MM.yyyy HH:mm' }}</div>
        <div class="td-text-ticker">{{ total.tickerCode }} фьючерс</div>
        <div>{{ selectedTickerName(total.tickerCode) }}</div>
      </th>
      <th colspan="2">Физические лица</th>
      <th colspan="2">Юридические лица</th>
      <th rowspan="2" class="td-summ">Сумма</th>
    </tr>
    <tr>
      <th class="td-longshort">Лонг</th>
      <th class="td-longshort">Шорт</th>
      <th class="td-longshort td-green">Лонг</th>
      <th class="td-longshort td-red">Шорт</th>
    </tr>
    <tr *ngFor="let row of total?.table; let even = even">
      <td>{{ row?.name }}</td>
      <td
        [class]="{'font-green': row.fpos_long>0 && !even, 'font-red': row.fpos_long<0 && !even}">{{ row.fpos_long | numberWithSign: !even }}
      </td>
      <td
        [class]="{'font-green': row.fpos_short>0 && !even, 'font-red': row.fpos_short<0 && !even}">{{ row.fpos_short | numberWithSign: !even }}
      </td>
      <td class="td-green"
          [class]="{'font-green': row.ypos_long>0 && !even, 'font-red': row.ypos_long<0 && !even}">{{ row.ypos_long | numberWithSign: !even }}
      </td>
      <td class="td-red"
          [class]="{'font-green': row.ypos_short>0 && !even, 'font-red': row.ypos_short<0 && !even}">{{ row.ypos_short | numberWithSign: !even }}
      </td>
      <td
        [class]="{'font-green': row.spos>0 && !even, 'font-red': row.spos<0 && !even}">{{ row.spos | numberWithSign: !even }}
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div class="complex-table">
  <div class="table-scroller">
    <table class="table1" *ngIf="total.table[0]">
      <tbody>
      <tr>
        <th colspan="5" style="text-align: center">
          {{ total.tickerCode }} фьючерс - {{ total.table[0].datetime | date: 'dd.MM.yyyy HH:mm' }}
        </th>
      </tr>
      <tr>
        <th colspan="2">Физические лица</th>
        <th rowspan="2" class="td-time">Время</th>
        <th colspan="2">Юридические лица</th>
      </tr>
      <tr>
        <th class="td-longshort">Лонг</th>
        <th class="td-longshort">Шорт</th>
        <th class="td-longshort td-green">Лонг</th>
        <th class="td-longshort td-red">Шорт</th>
      </tr>
      <tr *ngFor="let row of cumulative?.table">
        <td align="right"
            [class]="{'font-green': row.fpos_long>0, 'font-red': row.fpos_long<0}">{{ row.fpos_long | numberWithSign: true }}
        </td>
        <td align="right"
            [class]="{'font-green': row.fpos_short>0, 'font-red': row.fpos_short<0}">{{ row.fpos_short | numberWithSign: true }}
        </td>
        <td align="right">{{ row?.datetime | date: 'HH:mm' }}</td>
        <td align="right" class="td-green"
            [class]="{'font-green': row.ypos_long>0, 'font-red': row.ypos_long<0}">{{ row.ypos_long | numberWithSign: true }}
        </td>
        <td align="right" class="td-red"
            [class]="{'font-green': row.ypos_short>0, 'font-red': row.ypos_short<0}">{{ row.ypos_short | numberWithSign: true }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="charts">
    <light-weight-chart
      [data]='ydata'
      watermark={{yLegendTitle}}
      subWatermark='фьючерс на {{selectedTickerName(total.tickerCode)}}'
      [crosshairMode]="0"
      [showToolTip]="true"
      [type]="'line'"
      [timeVisible]="true"

    ></light-weight-chart>
    <light-weight-chart
      [data]='fdata'
      watermark={{fLegendTitle}}
      subWatermark='фьючерс на {{selectedTickerName(total.tickerCode)}}'
      [crosshairMode]="0"
      [showToolTip]="true"
      [type]="'line'"
      [timeVisible]="true"

    ></light-weight-chart>
  </div>
</div>
