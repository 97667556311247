import {IData} from "../models/i-data";
import {Total} from "../models/total";
import {BusinessDay, UTCTimestamp} from "lightweight-charts";
import * as Console from "console";

export class UtilService {
  public static formatTo(d: Date, type: string = "date") {
    // this.convertTZ(d, "Europe/Moscow")
    if (type == "date") {
      // return d.toLocaleDateString();
      let d1 = new Date(d.getTime()+d.getTimezoneOffset()*60*1000)
      return UtilService.addZero(d1.getDate(), false) + "." + UtilService.addZero(d1.getMonth() + 1, false);
    } else if (type == "dateWithYear") {
      let d1 = new Date(d.getTime()+d.getTimezoneOffset()*60*1000)
      return UtilService.addZero(d1.getDate(), false) + "." + UtilService.addZero(d1.getMonth() + 1, false) + "." + d1.getFullYear();
    } else if (type == "dateWithYear+time") {
      let d1 = new Date(d.getTime()+d.getTimezoneOffset()*60*1000)
      return UtilService.addZero(d1.getDate(), false) + "." + UtilService.addZero(d1.getMonth() + 1, false) + "." + d1.getFullYear()+" "+UtilService.addZero(d1.getHours(), false) + ":" + UtilService.addZero(d1.getMinutes(), false);
    } else if (type == "toLocaleString") {
      let d1 = new Date(d.getTime()+d.getTimezoneOffset()*60*1000)
      return d1.toLocaleString();
    } else {
      let d1 = new Date(d.getTime()+d.getTimezoneOffset()*60*1000)
      return UtilService.addZero(d1.getHours(), false) + ":" + UtilService.addZero(d1.getMinutes(), false);
    }
  }

  public static formatToFullSring(d: Date) {
    // this.convertTZ(d, "Europe/Moscow")
    return d?.getFullYear() + "-" + UtilService.addZero(d.getMonth() + 1, false) + "-" + UtilService.addZero(d.getDate(), false)
      + 'T' + UtilService.addZero(d.getHours(), false) + ":" + UtilService.addZero(d.getMinutes(), false) + ":" + UtilService.addZero(d.getSeconds(), false);
  }

  public static formatToSring(d: Date) {
    // this.convertTZ(d, "Europe/Moscow")
    return d?.getFullYear() + "-" + UtilService.addZero(d.getMonth() + 1, false) + "-" + UtilService.addZero(d.getDate(), false);
  }

  public static formatToSringWithTime(d: Date) {
    // this.convertTZ(d, "Europe/Moscow")
    return UtilService.addZero(d.getDate(), false) + "." + UtilService.addZero(d.getMonth() + 1, false) + "." + d?.getFullYear()
      + " " + UtilService.addZero(d.getHours(), false) + ":" + UtilService.addZero(d.getMinutes(), false);
  }

  public static getVal(value: number) {
    // let d = new Date((value - 3*3600) * 1000);
    let d = new Date(value * 1000);
    this.convertTZ(d, "Europe/Moscow")
    return d.getMonth() + "." + UtilService.addZero(d.getDate(), false);
  }

  public static formatNumToDateTime(value: number) {
    // let d = new Date((value - 3*3600) * 1000);
    let d = new Date(value * 1000);
    this.convertTZ(d, "Europe/Moscow")
    return d.toLocaleString();
  }

  public static formatNumToNumDate(value: number) {
    // let d = new Date((value - 3*3600) * 1000);
    let d = new Date(value * 1000);
    return new Date(UtilService.formatToSring(d)).getTime();
  }

  public static convertTZ(date: any, tzString: string) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
  }

  public static formatDtToNumDate(d: Date) {
    this.convertTZ(d, "Europe/Moscow")
    return new Date(UtilService.formatToSring(d)).getTime();
  }

  public static getFirst<T extends IData>(list: any, def: T): T {
    let itemList = Object.assign([], list).sort((a: T, b: T) => {
      if (a.dt < b.dt) return -1;
      if (a.dt > b.dt) return 1;
      return 0;
    });
    if (itemList.length > 0) {
      return itemList[0];
    }
    return def;
  }

  public static getLast<T extends IData>(list: any, def: T): T {
    let itemList = Object.assign([], list).sort((a: T, b: T) => {
      if (a.dt < b.dt) return 1;
      if (a.dt > b.dt) return -1;
      return 0;
    });
    if (itemList.length > 0) {
      return itemList[0];
    }
    return def;
  }

  public static formatFromSring(dateString: String) {
    const year = Number(dateString.substr(0, 4));
    const month = (Number(dateString.substr(5, 2) ?? 1) - 1);
    const day = Number(dateString.substr(8, 2));
    let d = new Date(year, month, day)
    if (dateString.includes('T')) {
      const hour = Number(dateString.substr(11, 2));
      const minute = Number(dateString.substr(14, 2));
      const second = Number(dateString.substr(17, 2));
      d = new Date(year, month, day, hour, minute, second)
    }
    this.convertTZ(d, "Europe/Moscow")
    return d;
  }

  public static formatUtcFromSring(dateString: string) {
    // let d = new Date(dateString)
    // const year = d.getUTCFullYear();
    // const month = d.getUTCMonth();
    // const day = d.getUTCDay();
    // const hour = d.getUTCHours();
    // const minute = d.getUTCMinutes();
    // const second = d.getUTCSeconds();

    const year = Number(dateString.toString().substr(0, 4));
    const month = (Number(dateString.toString().substr(5, 2) ?? 1) - 1);
    let day = Number(dateString.toString().substr(8, 2));
    let d1 = new Date(Date.UTC(year, month, day))
    // d.setTime(d.getTime()-3*60*60*1000)
    if (dateString.toString().includes('T')) {
      let hour = Number(dateString.toString().substr(11, 2));
      // if (hour<3){
      //   day = day - 1
      // }
      // hour = hour>=3 ? hour-3: (24 - hour + 3)
      const minute = Number(dateString.toString().substr(14, 2));
      const second = Number(dateString.toString().substr(17, 2));
      d1 = new Date(Date.UTC(year, month, day, hour, minute, second))
      // d.setTime(d.getTime()-3*60*60*1000)
    }
    // this.convertTZ(d, "UTC")
    return d1;
    // return new Date(Date.UTC(year, month, day, hour, minute, second))
  }

  private static convertUTCDateToLocalDate(date: Date): Date {

    let newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
    console.log(date,newDate, date.getTimezoneOffset());
    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  private static dateToUtc(date: Date): Date {
    let now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
      date.getUTCDate(), date.getUTCHours(),
      date.getUTCMinutes(), date.getUTCSeconds());
    return new Date(now_utc);
  }

  private static addZero(i: number, nine: boolean = true): string {
    if (nine)
      return i < 9 ? "0" + i : i.toString();
    return i < 10 ? "0" + i : i.toString();
  }
}
