import {createReducer, on} from "@ngrx/store";
import {Page} from "../../../models/page";
import {PAGE_INIT, PAGE_INITLIST} from "../action/page.action";
import {User} from "../../../models/user";
import {USER_INIT} from "../action/user.action";
import {TOTAL_CLEAR, TOTAL_INIT} from "../action/total.action";
import {Total} from "../../../models/total";

export const initialState: ReadonlyArray<Total> = []

export const totalReducer = createReducer(
  initialState,
  on(TOTAL_INIT, (state, {total}) => {
    if (total == null) return state
     if (!state.find(x => x.tickerCode == total.tickerCode && x.dt == total.dt)) {
       // console.log(state.indexOf(total))
       // if (state.indexOf(total) == -1) {
      return [...state, total]
    }
    return state.map(x => x.tickerCode == total.tickerCode && x.dt == total.dt ? total : x)
  }),
  on(TOTAL_CLEAR, (state, {}) => {
    return []
  })
)


