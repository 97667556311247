<mat-toolbar color="default" class="control-menu">
  <form class="d-flex">
    <!-- инструмент -->
    <div class="small-ticker-control flex-column">
        <section>
            <mat-select [formControl]="selectedTickerCodeControl" matTooltip="Инструмент">
                <mat-select-trigger><span class="active-ticker">{{selectedTickerCode}}</span></mat-select-trigger>
                <mat-option *ngFor="let ticker of tickerList" [value]="ticker.code">{{ticker.code}} - {{ticker['name']}}</mat-option>
            </mat-select>
        </section>
    </div>
    <div class="light-splitter"></div>
    <!-- Таймфрейм -->
    <div class="small-tf-control flex-column">
        <section>
            <mat-select matTooltip="Таймфрейм" [formControl]="selectedDirectionStepControl">
                <mat-select-trigger><span>{{GetDirectionStepName(direction.step)}}</span></mat-select-trigger>
                <mat-option *ngFor="let step of stepList" [value]="step.value">{{step.name}}</mat-option>
            </mat-select>
        </section>
    </div>
    <div class="light-splitter"></div>
    <!-- ЮЛ/ФЛ -->
    <div class="small-pos-control flex-column">
        <section>
            <mat-select matTooltip="Позиции" [formControl]="selectedPositionControl">
                <mat-select-trigger class="relative-5">
                    <mat-icon>people</mat-icon>
                    <span>{{selectedPositionName}}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let position of positions" [value]="position.code">{{position.fullname}}</mat-option>
            </mat-select>
        </section>
    </div>
    <div class="light-splitter"></div>
    <!-- Свечи/Бары -->
    <div class="small-switch-control flex-column">
        <mat-button-toggle-group class="switch-control-group" name="chartType" aria-label="Тип Графика" [formControl]="chartTypeControl">
            <mat-button-toggle value="candleStick" matTooltip="Японские свечи"><mat-icon>candlestick_chart</mat-icon></mat-button-toggle>
            <mat-button-toggle value="bar" matTooltip="Бары"><mat-icon style="position: relative; top:-4px" class="ohlc-bars"></mat-icon></mat-button-toggle>
        </mat-button-toggle-group>
    </div>
  <!-- обновить -->
  <div class="small-refresh flex-column" *ngIf="(this.isPayed)">
    <section>
      <button mat-icon-button matTooltip="Обновить" (click)="autorenew()">
        <mat-icon aria-setsize="16">autorenew</mat-icon>
      </button>
    </section>
  </div>
  </form>
</mat-toolbar>

<div class="charts" style="padding-top: 60px">
    <light-weight-chart watermark='{{watermark}}'
                        subWatermark="{{subWatermark}}"
                        [handleScroll]="true"
                        [crosshairMode]="direction.crossHairMode"
                        [timeVisible]="timeVisible"
                        [type]="direction.chartType"
                        [data]='lightWeightChartData'
                        [from]="direction.from" [to]="direction.to"
                        (updateVisibleRange)="updateVisibleRange($event)"
                        (onChanged)="update($event)"
                        height="600" width="1500">
    </light-weight-chart>


</div>


