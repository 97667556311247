import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../core/ngrx/app.state";
import {ActivatedRoute} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {Ticker} from "../../core/models/ticker";
import {FormControl, FormGroup} from "@angular/forms";
import {TotalGetService} from "../../core/ngrx/services/total/total-get.service";
import {BarParam, BarGetService} from "../../core/ngrx/services/bar/bar-get.service";
import {MaxGetService} from "../../core/ngrx/services/max/max-get.service";
import {Total} from "../../core/models/total";
import {Bar} from "../../core/models/bar";
import {CodeParam} from "../../core/models/code-param";
import {MaxDtService} from "../../core/websocket/max-dt.service";
import {DT_INIT} from "../../core/ngrx/store/action/dt.action";
import {CodeDtParam} from "../../core/models/code-dt-param";
import {Table2} from "../../core/models/table2";
import {UtilService} from "../../core/services/util.service";
import {Settings} from "../../core/models/settings";
import {SET_SETTING, UPDATE_SETTING} from "../../core/ngrx/store/action/setting.action";
import {UserGetService} from "../../core/ngrx/services/user/user-get.service";
import {MinGetService} from "../../core/ngrx/services/min/min-get.service";
import {LightWeightChartComponent} from "../../core/components/charts/light-weight-chart/light-weight-chart.component";
import {Alert} from "../../core/models/alert";
import {MatDialog} from "@angular/material/dialog";
import {AlertComponent} from "../alert/alert.component";
import {ConfirmDialogComponent} from "../../core/components/windows/confirm-dialog/confirm-dialog.component";
import {ConfirmDialogModel} from "../../core/components/windows/confirm-dialog/confirm-dialog-model";
import {LOADER_SHOW} from "../../core/ngrx/store/action/loader.action";
import {TraderHubServiceService} from "../../core/signal-r/trader-hub-service.service";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";


@Component({
    selector: 'bar',
    templateUrl: './bar.component.html',
    styleUrls: ['./bar.component.scss'],
    providers: [UserGetService, BarGetService, TotalGetService, MinGetService, MaxGetService]
})
export class BarComponent implements OnInit, OnDestroy {
    @ViewChild(LightWeightChartComponent) chart: LightWeightChartComponent | undefined;
    @ViewChild('notifyPopup', {read: ElementRef, static: false}) notifyPopup: ElementRef | undefined;

    private _tradedate: any;
    private _ticker: any;
    private _timeframe: any;
    private _barSub: Subscription | undefined;
    private _barSub1: Subscription | undefined;
    private _barSub2: Subscription | undefined;
    private _barSub3: Subscription | undefined;
    private _barSub4: Subscription | undefined;
    private _barSub5: Subscription | undefined;
    private _barSub6: Subscription | undefined;
    private _barSub7: Subscription | undefined;
    tickerList: Ticker[] = [];
    auto: boolean = false;
    total: Total = new Total();
    bar: Bar | undefined;

    priceMaxValue: number | undefined;
    priceMinValue: number | undefined;

    selectedTicker: Ticker | undefined;
    selectedTickerCode: string = "";
    selectedDt: Date = new Date();
    selectedStep: number = 300;
    yDataLong: any = {};
    yDataShort: any = {};
    fDataLong: any = {};
    fDataShort: any = {};
    private _settings: Settings | undefined;
    maxDate: Date = new Date();
    minDate: Date = new Date();
    isPayed: boolean = false;
    isAuth: boolean = false;
    hasTelegram: boolean = false;
    greenColor: string = '#77a45b';
    redColor: string = '#d46f6c';

    selectedTickerCodeControl: FormControl = new FormControl<string>('');
    selectedTimeFrameControl: FormControl = new FormControl<number>(3600);
    historicalControl: FormControl = new FormControl(true);
    tradeDateControl: FormControl = new FormControl<Date>(new Date());

    constructor(
        public _dialog: MatDialog,
        private _store: Store<AppState>,
        private _activatedRoute: ActivatedRoute,
        private _titleService: Title,
        private _totalGetService: TotalGetService,
        private _barGetService: BarGetService,
        private _maxGetService: MaxGetService,
        private _minGetService: MinGetService,
        private _userGetService: UserGetService,
        private _traderHubServiceService: TraderHubServiceService
    ) {


        this._userGetService.execute(Object);
    }

    /**
     * Initializes the component and subscribes to various store observables.
     *
     * @return {void}
     */
    ngOnInit(): void {
        setTimeout(() => {
            if (!this.isAuth)
                this._store.dispatch(UPDATE_SETTING({settings: JSON.stringify(new Settings())}));
            else
                this.populate();
        }, 500);
        if (!this._barSub4)
            this._barSub4 = this._store.select('user').subscribe(user => {
                // console.log(user)
                this.isAuth = user.id > 0;
                let now = new Date();
                this.isPayed = user.active_to * 1000 >= now.getTime();
                this.hasTelegram = user.telegram !== null && user.telegram !== undefined && user.telegram !== '';
                // this._settings = Object.assign(new Settings(), user.settings);
                // console.log(this._settings)
                // this.setAuto(this.isPayed && this.isAuth && this._settings.auto);
            });

        if (!this._barSub5)
            this._barSub5 = this._store.select('setting').subscribe(setting => {
                if (setting) {
                    this._settings = Object.assign(new Settings(), JSON.parse(setting)) //new Settings(setting);
                } else if (setting != undefined) {
                    this._settings = new Settings();
                }
                if (this._settings) {
                    this.auto = this._settings.auto;
                    this.historicalControl.setValue(!(this._settings.auto))
                    this.selectedStep = this._settings.selectedStep;
                    this.selectedTimeFrameControl.setValue(this._settings.selectedStep);
                    this.selectedTickerCode = this._settings.selectedTickerCode;
                    this.selectedTickerCodeControl.setValue(this._settings.selectedTickerCode);
                    if (this._settings.notify) {
                        this.alertList = this._settings.notify;
                        //console.log(this.alertList)
                    }
                }
            });

        if (!this._barSub)
            this._barSub = this._store.select('tickers').subscribe(tickers => {
                this.tickerList = Object.assign([], tickers);
                this.selectedTicker = this.tickerList[0];
                if (this.selectedTickerCode != '')
                    this.selectedTicker = this.tickerList.find((t: Ticker) => t.code == this.selectedTickerCode);
                if (this.selectedTicker != null) {
                    this.selectedTickerCode = this.selectedTicker.code;
                    this._minGetService.execute(new CodeParam(this.selectedTickerCode))

                    if (!this.selectedDt || this.selectedDt.getUTCDate() == new Date().getUTCDate())
                        this._maxGetService.execute(new CodeParam(this.selectedTickerCode))
                }
            });

        if (!this._barSub3)
            this._barSub3 = this._store.select('bars').subscribe(x => {
                // console.log(x)
                this.bar = UtilService.getLast<Bar>(x.filter((t: Bar) => t.tickerCode == this.selectedTickerCode), new Bar());
                // console.log(this.bar)
                if (this.bar != null) {
                    let b: Bar = this.bar;
                    let table: Table2[] = Object.assign([], b.table);
                    if (table[0]) {
                        let maxFLong = Math.max(...table.map(o => o.fpos_long), ...table.map(o => o.fpos_long))
                        let minFShort = Math.min(...table.map(o => o.fpos_long), ...table.map(o => o.fpos_short))
                        let maxYLong = Math.max(...table.map(o => o.ypos_long), ...table.map(o => o.ypos_short))
                        let minYShort = Math.min(...table.map(o => o.ypos_long), ...table.map(o => o.ypos_short))
                        this.priceMaxValue = Math.max(maxFLong, maxYLong);
                        this.priceMinValue = Math.min(minFShort, minYShort)

                        this.yDataLong = [
                            {
                                type: 'histogram',
                                data: table?.map((t: any) => {
                                    let date = new Date(t.datetime);
                                    let utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
                                    return {
                                        time: utc / 1000,
                                        value: t.ypos_long,
                                        color: t.ypos_long > 0 ? this.greenColor : this.redColor
                                    };
                                }).sort(function (a, b) {
                                    if (a.time < b.time) return -1
                                    if (a.time > b.time) return 1
                                    return 0
                                })
                            }];
                        this.yDataShort = [
                            {
                                type: 'histogram',
                                data: table?.map((t: any) => {
                                    let date = new Date(t.datetime);
                                    let utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
                                    return {
                                        time: utc / 1000,
                                        value: t.ypos_short,
                                        color: t.ypos_short > 0 ? this.greenColor : this.redColor
                                    };
                                }).sort(function (a, b) {
                                    if (a.time < b.time) return -1
                                    if (a.time > b.time) return 1
                                    return 0
                                })
                            }];
                        this.fDataShort = [
                            {
                                type: 'histogram',
                                data: table?.map((t: any) => {
                                    let date = new Date(t.datetime);
                                    let utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
                                    return {
                                        time: utc / 1000,
                                        value: t.fpos_short,
                                        color: t.fpos_short > 0 ? this.greenColor : this.redColor
                                    };
                                }).sort(function (a, b) {
                                    if (a.time < b.time) return -1
                                    if (a.time > b.time) return 1
                                    return 0
                                })
                            }];
                        this.fDataLong = [
                            {
                                // type: 'bar',
                                // data: table?.map(t => [t.datetime, t.fpos_long])
                                type: 'histogram',
                                data: table?.map((t: any) => {
                                    let date = new Date(t.datetime);
                                    let utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
                                    return {
                                        time: utc / 1000,
                                        value: t.fpos_long,
                                        color: t.fpos_long > 0 ? this.greenColor : this.redColor
                                    };
                                }).sort(function (a, b) {
                                    if (a.time < b.time) return -1
                                    if (a.time > b.time) return 1
                                    return 0
                                })
                            }];
                        // console.log(table)
                    }
                }
            });

        // подписываемся на изменение данных total
        if (!this._barSub2)
            this._barSub2 = this._store.select('totals').subscribe(totals => {
                let totalList = Object.assign([], totals);
                let totalListFiltered = totalList.filter((t: Total) => t.tickerCode == this.selectedTickerCode);
                this.total = UtilService.getLast<Total>(totalListFiltered, new Total());
            });

        // пописываемся на изменение даты
        if (!this._barSub1)
            this._barSub1 = this._store.select('dt').subscribe(dt => {
                if (dt.getTime() == this.selectedDt.getTime())
                    return
                this.selectedDt = Object.assign(dt);
                this.maxDate = Object.assign(dt);
                this.tradeDateControl = new FormControl(this.selectedDt);
                if (this.auto) {
                    this.tradeDateControl.disable();
                }
                this.populate();
            })

        if (!this._barSub6)
            this._barSub6 = this._store.select('maxDt').subscribe(dt => {
                this.maxDate = Object.assign(dt);
            })

        if (!this._barSub7)
            this._barSub7 = this._store.select('minDt').subscribe(dt => {
                this.minDate = Object.assign(dt);
            })

        // this.tradeDateControl.valueChanges.subscribe((value) => {
        //     this.selectedDt = value;
        //     this._store.dispatch(DT_INIT({dt: this.selectedDt}))
        //     this.populate();
        // });

        this.selectedTickerCodeControl.valueChanges.subscribe((value) => {
            this.selectedTickerCode = value;
            this.populate();
            setTimeout(() => {
                this.changeSettings();
            }, 300)
        });

        this.selectedTimeFrameControl.valueChanges.subscribe((value) => {
            this.selectedStep = value;
            setTimeout(() => {
                this.changeSettings();
            }, 300)
        })

        this.historicalControl.valueChanges.subscribe((value) => {
            this.auto = !value;
            if (this.auto) {
                if (this.tickerList.length > 0)
                    this._traderHubServiceService.connect();
                this.tradeDateControl.disable();
            } else {
                if (this.tickerList.length > 0)
                    this._traderHubServiceService.disconnect();
                this.tradeDateControl.enable();
            }
            setTimeout(() => {
                this.changeSettings();
            })
        })

    }

    dateChange($event: MatDatepickerInputEvent<any, any>) {
        this.selectedDt = $event.value;
        this._store.dispatch(DT_INIT({dt: this.selectedDt}))
        this.populate();
    }
    
    public autorenew() {
        this._store.dispatch(LOADER_SHOW({'show': true}));
        if ((!this.selectedDt || this.selectedDt.getUTCDate() == new Date().getUTCDate()) && this.selectedTicker)
            this._maxGetService.execute(new CodeParam(this.selectedTicker?.code))
        //this.populate()
        if (this.auto && this.selectedTickerCode) {
            this._traderHubServiceService.connect();
        }
    }

    private populate() {
        if (this.selectedDt && this.selectedTickerCode) {
            this.count = 15 * 3600 / this.selectedStep;
            this._titleService.setTitle(`${this.selectedTickerCode} - Побарный - ${this.selectedDt.toLocaleDateString()} < Betatrader.ru`);
            this._totalGetService.execute(new CodeDtParam(this.selectedTickerCode, this.selectedDt));
            this._barGetService.execute(new BarParam(this.selectedTickerCode, this.selectedDt, this.selectedStep));
        }
    }

    ngOnDestroy(): void {
        this._barSub?.unsubscribe();
        this._barSub1?.unsubscribe();
        this._barSub2?.unsubscribe();
        this._barSub3?.unsubscribe();
        this._barSub4?.unsubscribe();
        this._barSub5?.unsubscribe();
        this._barSub6?.unsubscribe();
        this._barSub7?.unsubscribe();
        this._traderHubServiceService.disconnect(); // disconnect traderHub
    }

    selectedTickerName(ticker: string) {
        for (let x of this.tickerList) {
            if (x.code == ticker) {
                return x.name
            }
        }
        return ""
    }

    stepList: any[] = [
        {value: 300, name: '5М'},
        {value: 600, name: '10М'},
        {value: 900, name: '15М'},
        {value: 1200, name: '20М'},
        {value: 1800, name: '30М'},
        {value: 3600, name: '1Ч'},
        {value: 86400, name: '1Д'},
    ];

    stepName(value: number) {
        for (let x of this.stepList) {
            if (x.value == value) {
                return x.name
            }
        }
        return ""
    }

    positions: any = [
        {'code': 'FIZ', 'name': 'ФЛ', 'fullname': 'Физические лица'},
        {'code': 'YUR', 'name': 'ЮЛ', 'fullname': 'Юридические лица'},
    ];

    positionName(code: string) {
        for (let x of this.positions) {
            if (x.code == code) {
                return x.name
            }
        }
        return ""
    }

    count: number = 0;
    alertList: Alert[] = [];
    selectedAlert: any;
    notifyList: boolean = false;


    // updateVisibleRange($event: any) {
    //   console.log('updateVisibleRange=', $event);
    // }
    //
    // update($event: any) {
    //   // console.log('update=', $event);
    // }

    changeNotifies() {
        setTimeout(
            () => this.notifyList = !this.notifyList
            , 100);
    }

    @HostListener('document:click', ['$event'])
    onClick($event: MouseEvent) {
        if (this.notifyList && !this.notifyPopup?.nativeElement.contains($event.target)) {
            // console.log('MouseEvent', this.notifyList, this.alertList)
            this.notifyList = false
            $event.preventDefault();
        }
    }


    addNotify() {
        this._dialog.open(AlertComponent, {
            width: '500px',
            disableClose: true,
            data: {
                'ticker': this.selectedTickerCode,
                'timeframe': this.selectedStep,
                'position': this._settings?.selectedPosition
            }
        });
    }

    enable(id: number, type: string) {
        if (this._settings) {
            let settings = this._settings;
            if (!settings.notify)
                settings.notify = [];
            for (let x of settings.notify) {
                if (x.id === id) {
                    if (type == 'email')
                        x.email = !x.email;
                    if (type == 'telegram') {
                        if (this.hasTelegram)
                            x.telegram = !x.telegram;
                        else
                            alert('Для использования Telegram необходимо настроить')

                    }

                }
            }
        }
        ///this._store.dispatch(SET_SETTING({settings: JSON.stringify(settings)}));
        this.changeNotifies();
    }

    delete_notify(id: number) {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: new ConfirmDialogModel("Подтвердите действие", "Вы действительно хотите удалить это оповещение?")
        });
        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
                if (this._settings) {
                    let settings = this._settings;
                    if (!settings.notify)
                        settings.notify = [];
                    for (let x of settings.notify) {
                        if (x.id === id) {
                            settings.notify.splice(settings.notify.indexOf(x), 1);
                        }
                    }
                }
                ///this._store.dispatch(SET_SETTING({settings: JSON.stringify(settings)}));
                // console.log(settings);
            }
        });
    }

    private changeSettings() {
        if (this._settings) {
            let settings = this._settings;
            let needUpdate = false;
            if (
                settings.selectedTickerCode != this.selectedTickerCode
                || settings.selectedStep != this.selectedStep
                || settings.auto != this.auto
            ) {
                needUpdate = true;
                settings.selectedTickerCode = this.selectedTickerCode;
                settings.selectedStep = this.selectedStep;
                settings.auto = this.auto;
            }

            if (needUpdate) {
                this._store.dispatch(SET_SETTING({settings: JSON.stringify(settings)}));
            }
        }
    }

}
