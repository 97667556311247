<div class="pay-form">
  <h1>Оплата подписки</h1>

  <form ngNoForm method="post" [formGroup]="payForm"
        action="https://securepay.tinkoff.ru/v2/Init"
        (ngSubmit)="onSubmit(payForm.value)" #pay>
    <input name="TerminalKey" formControlName="TerminalKey" type="hidden">
    <input name="Amount" formControlName="Amount" type="hidden">
    <input name="OrderId" formControlName="OrderId" type="hidden">
    <input name="OrderNum" formControlName="OrderNum" type="hidden">
    <input name="Description" formControlName="Description" type="hidden">
    <input name="Token" formControlName="Token" type="hidden">
    <input name="DATA" formControlName="DATA" type="hidden">

    <div class="important">Небольшое обращение и благодарность всем нашим подписчикам.<br><br>
      Наш проект старается развиваться, внедряя, как новый функционал, так и отлаживая уже имеющийся. Но иногда могут
      возникать ситуации, когда ресурс сбоит и что-то не работает или работает не так, как хотелось бы. Таких проблем мы
      пытаемся всячески избегать, но они возникают. По возможности стараемся устранять их как можно быстрее, а со
      временем мы обязательно минимизируем их появление.<br>
      Просим учитывать, что цена подписки указана с учетом этих особенностей работы ресурса.<br>
      Тем не менее, если вы оплатили подписку, но всё же решили, что наш ресурс вам не подходит в силу указанных выше
      причин, то мы вернём вам деньги за последний оплаченный период.<br>
      Помните, что вы всегда можете обратиться в техподдержку в <a href="https://t.me/betatrader_ru">Телеграм</a> или на
      <a href="mailto:info@betatrader.ru">электронную почту</a>.<br><br>
      Благодарим всех пользователей, кто вносит свой финансовый вклад в развитие проекта, несмотря на периодические
      неудобства. Мы это очень ценим.<br>
      С уважением, команда betatrader.ru
    </div>

    <mat-label>Выберите один из вариантов подписки и способ оплаты:</mat-label>
    <mat-radio-group class="radio-group" name="tariff" formControlName="tariff">
      <mat-radio-button class="radio-button" *ngFor="let tariff of tariffs" [value]="tariff.id"
                        (change)="change_tf($event)">
        <label>{{tariff.name}}</label> - <span>{{tariff.cost}} ₽</span><span class="tariff-full-cost"
                                                                             *ngIf="tariff.full_cost != 0"><s>{{tariff.full_cost}}
        ₽</s></span>
      </mat-radio-button>
    </mat-radio-group>

    <div class="form-describe">Нажимая на кнопку, вы соглашаетесь с условиями обработки персональных данных и <a
      href="https://add.betatrader.ru/privacy">политикой конфиденциальности</a></div>

    <mat-dialog-actions>

      <button mat-raised-button color="primary" [disabled]="payForm.invalid" type="submit">{{'Pay'|translate}}</button>
    </mat-dialog-actions>
    <div class="form-describe">
      <p>Продление подписки происходит автоматически после оплаты, но всё же рекомендуем проверять дату окончания
        подписки в <a href="#/lk">личном кабинете</a></p>
      Если возникли какие-то проблемы обращайтесь в Телеграм <a href="https://t.me/betatrader_ru">&#64;betatrader_ru</a>
      или на электронную почту <a href="mailto:info@betatrader.ru">info&#64;betatrader.ru</a><br>
    </div>
  </form>
</div>
