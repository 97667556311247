import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    UTCTimestamp,
} from "lightweight-charts";
import {Ticker} from "../../core/models/ticker";
import {Direction, Settings} from "../../core/models/settings";
import {SET_SETTING, UPDATE_SETTING} from "../../core/ngrx/store/action/setting.action";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/ngrx/app.state";
import {Title} from "@angular/platform-browser";
import {TickerGetService} from "../../core/ngrx/services/ticker/ticker-get.service";
import {UserGetService} from "../../core/ngrx/services/user/user-get.service";
import {Subject, Subscription} from "rxjs";
import {GetService, Param} from "../../core/ngrx/services/timerange/get.service";
import {UtilService} from "../../core/services/util.service";
import {CodeParam} from "../../core/models/code-param";
import {MaxGetService} from "../../core/ngrx/services/max/max-get.service";
import {MaxDtService} from "../../core/websocket/max-dt.service";
import {LOADER_SHOW} from "../../core/ngrx/store/action/loader.action";
import {TraderHubServiceService} from "../../core/signal-r/trader-hub-service.service";
import {FormControl, FormGroup} from "@angular/forms";
import {NEED_LOAD} from "../../core/ngrx/store/action/need-load.action";

@Component({
    selector: 'direction',
    templateUrl: './direction.component.html',
    styleUrls: ['./direction.component.scss'],
    providers: [UserGetService, TickerGetService, GetService, MaxGetService, MaxDtService]
})
export class DirectionComponent implements OnInit, OnDestroy {
    tickerList: Ticker[] = [];
    selectedTicker: Ticker = new Ticker();
    selectedTickerCode: string | undefined;
    lightWeightChartData: any;
    data: any;

    // directionStep: number | undefined;
    stepList: any[] = [
        {value: 1, name: 'Час'},
        {value: 24, name: 'День'},
    ];

    selectedPosition: string | undefined;
    selectedPositionName: string | undefined;
    positions: any = [
        {'code': 'FIZ', 'name': 'ФЛ', 'fullname': 'Физические лица'},
        {'code': 'YUR', 'name': 'ЮЛ', 'fullname': 'Юридические лица'},
    ];
    private _sub: Subscription | undefined;
    private _sub1: Subscription | undefined;
    private _sub2: Subscription | undefined;
    private _sub3: Subscription | undefined;
    private _sub4: Subscription | undefined;
    private _sub5: Subscription | undefined;
    private _sub6: Subscription | undefined;

    private _settings: Settings | undefined;
    isAuth: boolean = false;
    isPayed: boolean = false;
    watermark: string = '';
    chartType: string | undefined;
    timeVisible: boolean = true;
    private _loading: Subject<boolean> = new Subject<boolean>();

    private page: number = 1;
    private lastDt: number = 0;
    direction: Direction = new Direction();
    subWatermark: string = '';
    selectedTickerCodeControl: FormControl = new FormControl<string>('');
    selectedPositionControl: FormControl = new FormControl("YUR");
    selectedDirectionStepControl: FormControl = new FormControl<number>(24);
    chartTypeControl: FormControl = new FormControl("bar");
    private needLoad: boolean = false;

    constructor(private _store: Store<AppState>,
                private _titleService: Title,
                private _userGetService: UserGetService,
                private _timerange: GetService,
                private _maxGetService: MaxGetService,
                private _traderHubServiceService: TraderHubServiceService
    ) {


        this._userGetService.execute(Object);
    }

    ngOnInit() {
        setTimeout(() => {
            if (!this.isAuth) {
                this._store.dispatch(UPDATE_SETTING({settings: JSON.stringify(new Settings())}));
            }
            // else {
            //     this.populate();
            // }
        }, 500)
        // пописываемся на изменение пользователя
        if (!this._sub4)
            this._sub4 = this._store.select('user').subscribe(user => {
                this.isAuth = user.id > 0;
                let now = new Date();
                this.isPayed = user.active_to * 1000 >= now.getTime();
            });
        /*
            // пописываемся на изменение информации о загрузке
            if (!this._sub2)
              this._sub2 = this._loading.subscribe(value => {
                this.loading = value; // true or false

              })
           */
        // пописываемся на изменение настроек
        if (!this._sub5)
            this._sub5 = this._store.select('setting').subscribe(setting => {
                if (setting) {
                    this._settings = Object.assign(new Settings(), JSON.parse(setting)) //new Settings(setting);
                } else if (setting != undefined) {
                    this._settings = new Settings();
                }
                if (this._settings) {
                    // console.log(this._settings)

                    //this.populate();
                    this.selectedTickerCode = this._settings.selectedTickerCode;
                    this.selectedTickerCodeControl.setValue(this.selectedTickerCode);

                    this.timeVisible = (this.direction.step == 1);
                    this.selectedPosition = this._settings.selectedPosition;
                    this.selectedPositionControl.setValue(this.selectedPosition);

                    this.selectedPositionName = this.positions.find((p: any) => p.code == this.selectedPosition).name;

                    this.watermark = this.positions.find((p: any) => p.code == this.selectedPosition).fullname;
                    this.subWatermark = this.stepList.find((p: any) => p.value == this.direction.step).name;

                    this.direction = this._settings.direction;
                    this.selectedDirectionStepControl.setValue(this.direction.step);

                    this.chartType = this._settings.chartType;
                    this.chartTypeControl.setValue(this.chartType);

                    const prevDirection = this.direction;
                    this.direction = new Direction(this._settings.direction);

                    if (prevDirection.new) {
                        setTimeout(() => {
                            this.populate();
                        }, 0);
                    }
                    // console.log(this.direction)
                }

            });

        // пописываемся на изменение инструмента
        if (!this._sub)
            this._sub = this._store.select('tickers').subscribe(tickers => {
                this.tickerList = Object.assign([], tickers);
                this.selectedTicker = this.tickerList[0];
                this.selectedTickerCodeControl.setValue(this.selectedTickerCode);
                if (this.selectedTickerCode != '') {
                    let ticker = this.tickerList.find((t: Ticker) => t.code == this.selectedTickerCode);
                    if (ticker != undefined)
                        this.selectedTicker = ticker
                }
                if (this.selectedTicker != null) {
                    this.selectedTickerCode = this.selectedTicker.code;
                    if (this.isAuth && this.isPayed)
                        this._traderHubServiceService.connect();
                    else
                        this._maxGetService.execute(new CodeParam(this.selectedTickerCode))
                    // this.populate();
                }
            });

        // пописываемся на изменение таймфрейма
        if (!this._sub1)
            this._sub1 = this._store.select('timerange').subscribe(r => {
                this.lightWeightChartData = [{
                    type: this.direction.chartType,
                    data: r.table.map((row: any) => {
                        let t = UtilService.formatUtcFromSring(row.tradedate).getTime() / 1000;
                        if (row.tradetime) {
                            t = UtilService.formatUtcFromSring(row.tradedate + 'T' + row.tradetime).getTime() / 1000;
                        }
                        return {
                            time: t as UTCTimestamp,
                            open: row.open,
                            high: row.max,
                            low: row.min,
                            close: row.close
                        };
                    }).sort((a: any, b: any) => a.time - b.time)
                }];
                this._store.dispatch(LOADER_SHOW({'show': true}));
                this._loading.next(false);
            });

        // пописываемся на изменение даты
        if (!this._sub3)
            this._sub3 = this._store.select('dt').subscribe(d => {
                let dt = d.getTime();
                if (this.lastDt != dt) {
                    this.populate(this.page);
                }
                this.lastDt = dt;
            })

        // пописываемся на сигнал окончания загрузки
        if (!this._sub2)
            this._sub2 = this._store.select('needLoad').subscribe((d: boolean) => {
                this.needLoad = d;
                this._tryLoad();
            })

        if (!this._sub6)
            this._sub6 = this._store.select('loadedCount').subscribe((d: number) => {
                //this.page = d + 1;
                this._tryLoad();
            })

        this.selectedTickerCodeControl.valueChanges.subscribe((value) => {
            if (value != this.selectedTickerCode) {
                this.selectedTickerCode = value;
                setTimeout(() => {
                    this.changeSettings();
                    this.populate();
                }, 300)
            }
        });

        this.selectedPositionControl.valueChanges.subscribe((value) => {
            if (value != this.selectedPosition) {
                this.selectedPosition = value;
                setTimeout(() => {
                    this.changeSettings();
                    this.populate();
                }, 300)
            }
        });

        this.selectedDirectionStepControl.valueChanges.subscribe((value) => {
            if (value != this.direction.step) {
                this.direction.step = value;
                setTimeout(() => {
                    this.changeSettings();
                    this.populate();

                }, 300)
            }
        });

        this.chartTypeControl.valueChanges.subscribe((value) => {
            //if (value != this.chartType) {
            this.chartType = value;
            this.direction.chartType = value;
            setTimeout(() => {
                this.changeSettings();
                //this.populate(this.page);
            }, 300)
            //}
        })
    }

    ngOnDestroy(): void {
        this._sub?.unsubscribe();
        this._sub1?.unsubscribe();
        this._sub2?.unsubscribe();
        this._sub3?.unsubscribe();
        this._sub4?.unsubscribe();
        this._sub5?.unsubscribe();
        this._sub6?.unsubscribe();
        this._traderHubServiceService.disconnect();
    }

    private populate(page: number = 1) {
        this.page = page;
        if (this.selectedTickerCode && this.selectedPosition) {
            this.watermark = this.positions.find((p: any) => p.code == this.selectedPosition).fullname;
            this.selectedPositionName = this.positions.find((p: any) => p.code == this.selectedPosition).name;
            this._titleService.setTitle(`${this.selectedTickerCode} - Направление < Betatrader.ru`);
            this._loading.next(true);
            this._timerange.execute(new Param((this.direction?.step == 1 ? "hour" : "day"), this.selectedTickerCode, this.selectedPosition.toString(), page));
        }
    }

    changeSettings() {
        if (this._settings != undefined) {

            if (this.selectedTickerCode) {
                if (this._settings) {
                    let settings = this._settings;
                    let needUpdate = false;
                    if (settings.selectedTickerCode != this.selectedTickerCode
                        || settings.direction.step != this.direction.step
                        || settings.chartType != this.chartType
                        || settings.selectedPosition != this.selectedPosition) {
                        needUpdate = true;
                    }
                    settings.selectedTickerCode = this.selectedTickerCode;
                    settings.direction.step = this.direction.step ?? 1;
                    settings.chartType = this.chartType ?? "bar";
                    settings.selectedPosition = this.selectedPosition ?? 'YUR';
                    if (needUpdate) {
                        this._store.dispatch(SET_SETTING({settings: JSON.stringify(settings)}));
                    }
                }
            }
        }
    }

    selectedTickerName(ticker: string) {
        for (let x of this.tickerList) {
            if (x.code == ticker) {
                return x.name
            }
        }
        return ""
    }

    update($event: any) {
        if (this.isPayed) {
            this.needLoad = $event.from < 0;
            if (this.needLoad) {
                this.page ++;
            }
            //console.log($event);
            //this._store.dispatch(LOADER_SHOW({'show': false}));
            this._store.dispatch(NEED_LOAD({needLoad: this.needLoad}));
        }
    }

    updateVisibleRange($event: any) {
        if (this._settings) {
            let settings = this._settings;

            const from = this.lightWeightChartData[0].data[0].time;
            const length = this.lightWeightChartData[0].data.length;

            let needUpdate = false;
            if (settings.direction.from != $event.from
                || settings.direction.to != $event.to)
                needUpdate = true;
            settings.direction.from = $event.from;
            settings.direction.to = $event.to;
            if (needUpdate) {
                this._store.dispatch(LOADER_SHOW({'show': false}));
                this._store.dispatch(SET_SETTING({settings: JSON.stringify(settings)}));
            }
            //if ((from < $event.from)){
            //     this.page--;
            // }


            /*if (from >= $event.from) {
              console.log("updateVisibleRange=" + this.page)
              this.populate(this.page)
            }*/

        }
    }

    GetDirectionStepName(step: number) {
        if (step == 1) return "Ч";
        return "Д";
    }

    public autorenew() {

        // console.log(this.selectedTicker)
        if (this.selectedTicker != null) {
            this._store.dispatch(LOADER_SHOW({'show': true}));
            this.populate();
            this.selectedTickerCode = this.selectedTicker.code;
            if (this.isAuth && this.isPayed) {
                this._traderHubServiceService.connect();
            }
        }
    }

    private _tryLoad() {
        if (this.needLoad && this.page > 0) {
            console.log("loadedCount=", this.page);
            this.populate(this.page);
        }
    }
}
