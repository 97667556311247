import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {PAGE_INIT} from "../../store/action/page.action";
import {CodeParam} from "../../../models/code-param";

@Injectable()
export class PageGetService extends BaseService<CodeParam> {
    override execute(obj: CodeParam) {
        this.url = "/page?code={code}"
        super.execute(obj)
        this.get(obj)
            .subscribe(r => {
                let page = r.results
                this._store.dispatch(PAGE_INIT({page}))
            })
    }
}
