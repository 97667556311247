import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {Total} from "../../../models/total";
import {TOTAL_INIT} from "../../store/action/total.action";
import {Table1} from "../../../models/table1";
import {CodeDtParam} from "../../../models/code-dt-param";
import {UtilService} from "../../../services/util.service";

@Injectable()
export class TotalGetService extends BaseService<CodeDtParam> {
  override execute(obj: CodeDtParam) {
    this.url = "/total?ticker={code}&dt={dt}"
    super.execute(obj, false)
    this.get(obj)
      .subscribe((r: Table1[]) => {
        if (r.length > 0) {
          let time = new Date();
          // let time = r[0].datetime_int * 1000;
          // if (r.length > 0) time = UtilService.formatNumToNumDate(r[0].datetime_int)
          let total = new Total(time, obj.code, r)
          this._store.dispatch(TOTAL_INIT({total}))
        }
      })
  }
}
