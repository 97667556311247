import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {UtilService} from "../../../services/util.service";
import {Bar} from "../../../models/bar";
import {Table2} from "../../../models/table2";
import {BAR_INIT} from "../../store/action/bar.action";

@Injectable()
export class BarGetService extends BaseService<BarParam> {
  override execute(obj: BarParam) {
    this.url = "/bar?ticker={code}&dt={dt}&interval={step}";
    super.execute(obj);
    this.get(obj)
      .subscribe((r: Table2[]) => {
        let t = r.sort(function (a, b) {
          if (a.time < b.time) return 1
          if (a.time > b.time) return -1
          return 0
        })
        let time = new Date();
        let bar = new Bar(time, obj.code, r)
        this._store.dispatch(BAR_INIT({bar}))
      })
  }
}

export class BarParam {
  code: string = "";
  dt: string = "";
  t: number = 0;
  d: Date = new Date();
  step: number = 300;

  constructor(code: string, d: Date, step: number) {
    this.dt = UtilService.formatToSring(d)
    this.t = d.getTime()
    this.code = code
    this.step = step
  }
}
