import { createReducer, on } from "@ngrx/store";
import {Page} from "../../../models/page";
import {PAGE_INIT, PAGE_INITLIST} from "../action/page.action";

export const initialState: ReadonlyArray<Page> = []

export const pageReducer = createReducer(
    initialState,
    on(PAGE_INITLIST, (state, { pages }) => [...pages]),
    on(PAGE_INIT, (state, { page }) => [...state, page]),
)
