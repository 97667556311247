<div>
  <div class="open-card-BG" *ngIf='showForm'>
    <div class="h100">
      <div class="wrapper">
        <h2 mat-dialog-title>{{ 'Password Reset' |translate}}</h2>

        <p>Введите новый пароль:</p>

        <!-- <mat-dialog-content> -->
        <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">

          <mat-form-field appearance="outline">
            <mat-label>{{'Password'|translate}}</mat-label>
            <input matInput [placeholder]="'Password'|translate" formControlName="password" required type="password">
            <mat-error *ngIf="checkoutForm.controls['password'].invalid && checkoutForm.controls['password'].touched">
              <span *ngIf="checkoutForm.controls['password'].errors.required">{{'Required'|translate}}.</span>
              <span *ngIf="checkoutForm.controls['password'].errors.minlength">{{'MinLengthTemplate'|translate: {length: 5} }}.</span>
            </mat-error>
          </mat-form-field>

<!--          <p>Повторите пароль:</p>-->

<!--          <mat-form-field appearance="outline">-->
<!--            <mat-label>{{'Password'|translate}}</mat-label>-->
<!--            <input matInput [placeholder]="'Password'|translate" formControlName="password" required>-->
<!--            <mat-error *ngIf="checkoutForm.controls['password'].invalid && checkoutForm.controls['password'].touched">-->
<!--              <span *ngIf="checkoutForm.controls['password'].errors.required">{{'Required'|translate}}.</span>-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->

          <mat-dialog-actions>
            <button mat-raised-button color="primary" [disabled]="checkoutForm.invalid" type="submit">{{'Save'|translate}}</button>
          </mat-dialog-actions>

        </form>
        <!-- </mat-dialog-content> -->

      </div>
    </div>
  </div>
  <div class="open-card-BG" *ngIf="!showForm">
    <div class="h100">
      <div class="wrapper">
        <h2 mat-dialog-title>{{ 'Password Reset' |translate}}</h2>

        <p>Пожалуйста, заполните свой адрес электронной почты. На него будет отправлена ссылка для сброса пароля.</p>

        <!-- <mat-dialog-content> -->
        <form [formGroup]="emailCheckoutForm" (ngSubmit)="onSubmit(emailCheckoutForm.value)">

          <mat-form-field appearance="outline">
            <mat-label>{{'Email'|translate}}</mat-label>
            <input matInput [placeholder]="'Email'|translate" formControlName="email" required>
            <mat-error *ngIf="emailCheckoutForm.controls['email'].invalid && emailCheckoutForm.controls['email'].touched">
              {{'Invalid'|translate}}
            </mat-error>
          </mat-form-field>

          <mat-dialog-actions>
            <button #submitButton mat-raised-button color="primary" [disabled]="emailCheckoutForm.invalid" type="submit">{{SubmitButtonText}}</button>
          </mat-dialog-actions>

        </form>
        <!-- </mat-dialog-content> -->

      </div>
    </div>

  </div>
</div>
