import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {MESSAGE_SET} from "../../store/action/message-set.action";
import {LoginResult} from "../../../models/login-result";
import {AuthService, TOKEN_NAME} from "../../../services/auth.service";
import {Subscription} from "rxjs";

@Injectable()
export class LoginService extends BaseService<LoginParam> {
  override execute(obj: LoginParam) {
    this.url = "/auth";
    super.execute(obj);
    this._store.dispatch(MESSAGE_SET({message: ""}))
    this.post(obj)
      .subscribe((res: LoginResult) => {
        if (res) {
          AuthService.setCookie(TOKEN_NAME, res.access_token, 30 * 24);
          //localStorage.setItem(TOKEN_NAME, res.access_token)
          this._isAuthService.setIsAuth(true)
        }
      })
  }

  refresh() {
    this.url = "/refresh";
    super.execute(null);
    return this.get(null);
  }

  public logout() {
    if (this._isAuthService.isAuth) {
      //localStorage.removeItem(TOKEN_NAME)
      AuthService.deleteCookie(TOKEN_NAME)
      this._store.dispatch(MESSAGE_SET({message: "Выход успешен"}))
      this._isAuthService.setIsAuth(false);
    }
    /*this.url = "/logout";
    super.execute(new LoginParam);
    return this.get(new LoginParam).subscribe((res: LoginResult) =>{
        if(res) {
            this._store.dispatch(MESSAGE_SET({message: "Выход успешен"}))
            this._isAuthService.setIsAuth(false);
            localStorage.removeItem(TOKEN_NAME)
        }
    });*/
  }
}

export class LoginParam {
  email: string = ""
  password: string = ""
}
