import { createReducer, on } from "@ngrx/store";
import {Total} from "../../../models/total";
import {CUMULATIVE_INIT} from "../action/cumulative.action";
import {Cumulative} from "../../../models/cumulative";

export const initialState: ReadonlyArray<Cumulative> = []

export const cumulativeReducer = createReducer(
    initialState,
    on(CUMULATIVE_INIT, (state, { cumulative }) => {
        if (cumulative == null) return state;
        // if(state.indexOf(cumulative) == -1)
        if (!state.find(x => x.tickerCode == cumulative.tickerCode && x.dt == cumulative.dt))
            return [...state, cumulative];
        return state.map(x => x.tickerCode == cumulative.tickerCode && x.dt == cumulative.dt? cumulative : x);
    }),
)
