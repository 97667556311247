/*export class Alert {
  constructor(obj: Alert = Object())
  {
    this.timeframe = obj?.timeframe ?? 24;
    this.value = obj?.value ?? 0;
    this.name = obj?.name ?? 0;
    this.content = obj?.content ?? 0;
    this.tickerCode = obj?.tickerCode ?? 'RI';
    this.position = obj?.position ?? 'YUR';
    this.email = obj?.email ?? false;
    this.telegram = obj?.telegram ?? false;
  }
  name: string;
  content: string;
  position: string;
  tickerCode: string;
  timeframe: number;
  value: number;
  email: boolean;
  telegram: boolean;
}*/

import {Alert} from "./alert";

export class Direction {
  constructor(obj?: Direction) {
    this.new = !(obj);
    this.step = obj?.step ?? 24;
    this.from = obj?.from ?? 0;
    this.to = obj?.to ?? 0;
    this.chartType = obj?.chartType ?? "bar";
    this.crossHairMode = obj?.crossHairMode ?? 0;
  }

  new: boolean = true
  step: number;
  from: number;
  to: number;
  chartType: string;
  crossHairMode: number;
}

export class Settings {
  /*constructor(obj1: string) {
    const obj = JSON.parse(obj1);
    this.showGraph = obj?.showGraph ?? false;
    this.showTable = obj?.showTable ?? true;
    this.auto = obj?.auto ?? false;
    this.daysCount = obj?.daysCount ?? 10;
    this.selectedPosition = obj?.selectedPosition ?? 'YUR';
    this.selectedTickerCode = obj?.selectedTickerCode ?? 'RI';
    this.selectedTickersCode = obj?.selectedTickersCode ?? 'RI, Si'; //, BR, Eu, GD, SR';
    this.selectedStep = obj?.selectedStep ?? 3600;
    this.directionStep = obj?.directionStep ?? 24;
    this.isLightTheme = obj?.isLightTheme ?? true;
    this.chartType = obj?.chartType ?? 'bar';
    this.direction = obj?.direction ? new Direction(obj.direction) : new Direction();
    this.notify = [];
    if (obj?.notify) {
      for (let i = 0; i < obj.notify.length; i++)
        this.notify.push(new Alert(obj.notify[i]));
    }
  }*/

  directionStep: number = 24;
  selectedStep: number = 3600;
  showGraph: boolean = false;
  showTable: boolean = true;
  auto: boolean = true;
  daysCount: number = 10;
  selectedTickersCode: string = "RI, Si";
  selectedTickerCode: string = "RI";
  selectedPosition: string = "YUR";
  chartType: string = 'bar';
  isLightTheme: boolean = true;
  direction: Direction = new Direction();
  notify: Alert[] = [];
}

