<mat-toolbar class="footer text-muted">
    <div>
        <p class="float-left">Техподдержка: </p>
        <!-- <p class="float-left" style='margin-top: 22px;'><a href="https://t.me/betatrader_ru" target="_blank"><img src="https://img.icons8.com/ios-filled/26/5686e1/telegram.png" alt="Telegram-канал техподдержки"></a></p> -->
        <!-- <p class="float-left"><a href="https://vk.com/betatrader" target="_blank"><img src="https://img.icons8.com/5686E1/ios11/1x/vk-circled.png" alt="ВКонтакте"></a></p> -->
        <!-- <p class="float-left"><a href="mailto:info@betatrader.ru" target="_blank"><img src="https://img.icons8.com/5686E1/ios11/1x/secured-letter.png" alt="EMAIL"></a></p> -->
        <p class="float-left" matTooltip="Telegram-канал техподдержки"><a href="https://t.me/betatrader_ru" target="_blank"><mat-icon>telegram</mat-icon></a></p>
        <p class="float-left" matTooltip="Письмо в техподдержку"><a href="mailto:info@betatrader.ru" target="_blank"><mat-icon>email</mat-icon></a></p>
        <p class="float-left" matTooltip="Письмо в техподдержку"><a href="mailto:info@betatrader.ru" target="_blank">info&#64;betatrader.ru</a></p>
    </div>
    <div>
        <p class="float-right">ИП Полищук Д.А. ОГРНИП 323700000037172 ИНН 702401365530 © betatrader.ru {{currentYear()}}</p>
    </div>
    <div>
        <!-- <p class="float-right"><a routerLink="/lk/pay">Оплата</a></p> -->
        <!-- <p class="float-right"><a routerLink="/page/agreement">Пользовательское соглашение</a></p> -->
        <!-- <p class="float-right"><a routerLink="/page/privacy">Политика конфиденциальности</a></p> -->
        <p class="float-right"><a href="https://add.betatrader.ru/openinterest" target="_blank">Про ресурс</a></p>
        <p class="float-right"><a href="https://add.betatrader.ru/oi-tariffs" target="_blank">Тарифы</a></p>
        <p class="float-right"><a routerLink="/lk/pay">Оплата</a></p>
        <p class="float-right"><a href="https://add.betatrader.ru/agreement" target="_blank">Пользовательское соглашение</a></p>
        <p class="float-right"><a href="https://add.betatrader.ru/privacy" target="_blank">Политика конфиденциальности</a></p>
    </div>
</mat-toolbar>