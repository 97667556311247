import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import {Observable, of, Subscription, timer} from "rxjs";
import {skipWhile, map, catchError, take} from "rxjs/operators";
import {AuthService, TOKEN_NAME} from "./auth.service";
import {LoginService} from "../ngrx/services/auth/login.service";
import {LoginResult} from "../models/login-result";
import {USER_INIT} from "../ngrx/store/action/user.action";
import {UserGetService} from "../ngrx/services/user/user-get.service";
import {Store} from "@ngrx/store";
import {AppState} from "../ngrx/app.state";


@Injectable()
export class AuthGuardService  {
    private _sub: Subscription | undefined;



    constructor(
        private _router: Router,
        private _store: Store<AppState>,
        private _loginService: LoginService,
        private _isAuthService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        /*let remainingTime = this._isAuthService.getRemainingTime();
        let fullTime = this._isAuthService.getConstTimeToLife();
        if (!this._sub || this._sub.closed || remainingTime <= fullTime) {
            this.refresh(null)
        }*/
        this._store.select('user').subscribe(user => {
            let now = new Date();
            if (user.active_to*1000<now.getTime() && !route.url.some(u=>u.path=='lk' || u.path!='login') ){
                this._router.navigate(['lk/pay']);
            }
        });
        if (!this._isAuthService.isAuth) {
            this._router.navigate(['/login']);
        }
        return of(true);
    }

    private doRefresh() {
        if (this._sub != undefined) {
            this._sub.unsubscribe();
        }
        this._loginService.refresh().subscribe((res) => {
            this.refresh(res);
        }, (err) => {
            console.log('onerror: ', err);
            this._isAuthService.setIsAuth(false);
        });
    }

    private refresh(res: LoginResult | any) {
        if (this._sub != undefined) {
            this._sub.unsubscribe();
        }
        if (res) {
            localStorage.setItem(TOKEN_NAME, res.access_token)
        }

        let fullTime = this._isAuthService.getConstTimeToLife();
        const o = timer(0, 3000).pipe(
            take(fullTime)
        );

        this._sub = o.subscribe({
            complete: () => {
                this.doRefresh();
            }, next: (T)=>{
                if (this._isAuthService != null){
                    let remainingTime = this._isAuthService.getRemainingTime();
                    let fullTime = this._isAuthService.getConstTimeToLife();
                    let now = new Date();
                    if (remainingTime <= fullTime){
                        console.log(T, remainingTime, fullTime, now);
                        this.doRefresh();
                    }
                }
            }
        });
    }
}
