import { createAction, props } from "@ngrx/store";

export const MAX_DT_INIT = createAction(
  "[Date].MaxInit",
  props<{dt:Date}>()
)

export const MIN_DT_INIT = createAction(
  "[Date].MinInit",
  props<{dt:Date}>()
)

export const DT_INIT = createAction(
    "[Date].Init",
    props<{dt:Date}>()
)
