import {createReducer, on} from "@ngrx/store";
import {NEED_LOAD} from "../action/need-load.action";

export const initialState: boolean = false;

export const needLoadReducer = createReducer(
  initialState,
  on(NEED_LOAD, (state,{needLoad}) => {
    return needLoad
  })
)
