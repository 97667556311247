import { createReducer, on } from "@ngrx/store";
import {TARIFFS_INIT} from "../action/tariffs.action";
import {Tariff} from "../../../models/tariff";

export const initialState: ReadonlyArray<Tariff> = []

export const tariffReducer = createReducer(
    initialState,
    on(TARIFFS_INIT, (state, { tariffs }) => [...tariffs]),
)
