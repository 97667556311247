import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {UtilService} from "../../../services/util.service";
import {DT_INIT, MAX_DT_INIT} from "../../store/action/dt.action";
import {CodeParam} from "../../../models/code-param";

@Injectable()
export class MaxGetService extends BaseService<CodeParam> {
    override execute(obj: CodeParam) {
        this.url = "/max?ticker={code}"
        super.execute(obj)
        this.get(obj)
            .subscribe(r => {
                let dt = UtilService.formatFromSring(r)
                this._store.dispatch(MAX_DT_INIT({dt}));
                this._store.dispatch(DT_INIT({dt}))
            })
    }
}

