import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserGetService} from "../../core/ngrx/services/user/user-get.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/ngrx/app.state";
import {Title} from "@angular/platform-browser";
import {User} from "../../core/models/user";
import {AuthService} from "../../core/services/auth.service";
import {Tz} from "../../core/models/tz";
import {TzGetService} from "../../core/ngrx/services/tz/tz-get.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ConfirmParam, ConfirmService} from "../../core/ngrx/services/user/confirm.service";
import {MESSAGE_SET} from "../../core/ngrx/store/action/message-set.action";
import {Subscription} from "rxjs";
import {HttpService} from "../../core/services/http.service";


@Component({
  selector: 'lk',
  templateUrl: './lk.component.html',
  styleUrls: ['./lk.component.scss'],
  providers: [TzGetService, ConfirmService]
})
export class LkComponent implements OnInit, OnDestroy {
  public u: User | any;
  // private user: AuthInfo | any;
  tzList: Tz[] | any;
  userForm: any;
  isDisabled: boolean = false;
  private _sub1: Subscription | undefined;
  private _sub2: Subscription | undefined;

  constructor(
    private _store: Store<AppState>,
    private _titleService: Title,
    private _http: HttpService,
    private _tzGetService: TzGetService,
    private _confirmService: ConfirmService,
    private _isAuthService: AuthService,
    private _formBuilder: FormBuilder,
  ) {
    this.userForm = this._formBuilder.group({
      tz: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit(): void {
    // this.user = this._isAuthService.authUserInfo();
    this._sub1 = this._store.select('user').subscribe(user => {
      //console.log(user)
      this.u = user
      this.userForm.controls["tz"].setValue(+this.u?.tz)
    });

    this._sub2 = this._store.select('tz').subscribe(tz => {
      this.tzList = Object.assign([], tz)
      // console.log(tz)
    });
    this._titleService.setTitle("Мой профиль < Betatrader.ru");
    // this._tzGetService.execute(Object)
  }

  sendConfirm() {

    this._confirmService.execute(new ConfirmParam()).subscribe((res: any) => {
      this._store.dispatch(MESSAGE_SET({message: ""}))
      if (res.result) {
        this._store.dispatch(MESSAGE_SET({message: "Ссылка для подтверждения отправлена на указанный email"}))
      } else {
        this._store.dispatch(MESSAGE_SET({message: res.message}))
      }
      this.isDisabled = true
    })
  }

  onSubmit(value: any) {
    console.log(value)
  }

  ngOnDestroy(): void {
    this._sub1?.unsubscribe();
    this._sub2?.unsubscribe();
  }

  setTestDrive() {
    this._http.post("/set_test_drive", {}).subscribe(
      r=>window.location.reload()
    );
  }
}
