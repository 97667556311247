<mat-toolbar color="primary">
  <button mat-button matTooltip="Про функционал betatrader.ru"><a href="https://add.betatrader.ru/openinterest" target="_blank"><span class="logo"></span></a></button>

  <div class="pages-menu">
      <button mat-button routerLink="/daily" routerLinkActive="mat-accent"><mat-icon>trending_up</mat-icon>Дневной</button>
      <button mat-button routerLink="/direction" routerLinkActive="mat-accent"><mat-icon>swap_vert</mat-icon>Направление</button>
      <button mat-button routerLink="/bar" routerLinkActive="mat-accent"><mat-icon>bar_chart</mat-icon>Побарный</button>
      <button mat-button routerLink="/cumulative" routerLinkActive="mat-accent"><mat-icon>signal_cellular_alt</mat-icon>Накопительный</button>
      <!-- <button mat-button routerLink="/signup" routerLinkActive="mat-accent" *ngIf="!isAuth">{{'Signup'|translate}}</button>
      <button mat-button routerLink="/lk" routerLinkActive="mat-accent" *ngIf="isAuth" title="{{'Profile'|translate}}"><mat-icon>account_circle</mat-icon></button>
      <button mat-button routerLink="/logout" routerLinkActive="mat-accent" *ngIf="isAuth" title="{{'Logout'|translate}}"><mat-icon>exit_to_app</mat-icon></button>
      <button mat-button routerLink="/login" routerLinkActive="mat-accent" *ngIf="!isAuth">{{'Login'|translate}}</button> -->
  </div>
  <div class="profile">
    <button mat-button [matMenuTriggerFor]="menu" *ngIf="!isAuth">
      <mat-icon style=color:#d46f6c>account_circle</mat-icon>
      <button mat-button matTooltip="Функционал ограничен. Зарегистрируйтесь или войдите под своими учетными данными.">
        <mat-icon style="color:#edc356; width: 18px; height: 18px; font-size: 18px">warning</mat-icon></button>
    </button>
    <button matTooltip="{{email}}" mat-button [matMenuTriggerFor]="menu" *ngIf="isAuth"><mat-icon style=color:#77a45b>account_circle</mat-icon>
      <button *ngIf="!isPayed" mat-button matTooltip="Функционал ограничен. Полный доступ по подписке.">
        <mat-icon style="color:#edc356; width: 18px; height: 18px; font-size: 18px">warning</mat-icon></button>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="/login" *ngIf="!isAuth">Войти</button>
      <button mat-menu-item routerLink="/signup" *ngIf="!isAuth">Регистрация</button>

      <button mat-menu-item routerLink="/lk" *ngIf="isAuth">Профиль</button>
      <button mat-menu-item routerLink="/logout" *ngIf="isAuth">Выйти</button>
    </mat-menu>
  </div>
  <div class="add_feature">
    <button mat-button matTooltip="Дополнительные возможности от Betatrader" [matMenuTriggerFor]="add_feature"><mat-icon>widgets</mat-icon></button>
    <mat-menu #add_feature="matMenu">
      <a mat-menu-item matTooltip="Подписка на аналитическую платформу XTick Extreme" href="https://add.betatrader.ru/xtick" target="_blank"><span class="xtick"></span></a>
      <a mat-menu-item matTooltip="Индикаторы для торгово-аналитической платформы ATAS" href="https://add.betatrader.ru/atas" target="_blank"><span class="atas"></span></a>
      <a mat-menu-item matTooltip="Другие возможности и рекомендации" href="https://add.betatrader.ru/dmitry_polischuk_creator#partners-2" target="_blank">Другие возможности</a>
    </mat-menu>
  </div>
  <div class="theme-switcher">
    <button mat-button matTooltip="Вкл. темную тему" *ngIf="isLightTheme" (click)="onThemeSwitchChange()"><mat-icon>brightness_high</mat-icon></button>
    <button mat-button matTooltip="Вкл. светлую тему" *ngIf="!isLightTheme" (click)="onThemeSwitchChange()"><mat-icon>brightness_low</mat-icon></button>
  </div>
</mat-toolbar>
<notify></notify>
<!-- <div class="need_signup" *ngIf="!isPayed && isAuth">Вам доступен ограниченный функционал. Полный доступ по <a routerLink="/lk/pay">подписке</a>.</div> -->
<!-- <div class="need_signup" *ngIf="!isAuth">Вам доступен ограниченный функционал. Получите месяц полного доступа сразу после <a routerLink="/signup">регистрации</a>.</div> -->
<!-- <div class="need_signup" *ngIf="!isAuth">Ознакомиться с содержанием ресурса и примером использования побарной информации можно по <a target="_blank" href="https://youtu.be/dE6t2llt8FM">ВИДЕО</a>.</div> -->
<div *ngIf="loading" class="loading-container">
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>
<div class="page-content">
  <router-outlet></router-outlet>
</div>
<footer></footer>
