import {Table2} from "./table2";
import {IData} from "./i-data";

export class CumulativeNum implements IData {
    constructor(dt?: Date, tickerCode?: string, table?: Table2[]) {
        this.dt = dt ?? new Date();
        this.tickerCode = tickerCode ?? "";
        this.table = table ?? [];
    }
    dt: Date = new Date();
    tickerCode: string = "";
    table: Table2[] = []
}
