import {Table1} from "./table1";
import {IData} from "./i-data";

export class Total implements IData {
    constructor(dt?: Date, ticker?: string, table?: Table1[]) {
        this.dt = dt ?? new Date();
        this.tickerCode = ticker ?? "";
        this.table = table ?? [];
    }
    dt: Date = new Date();
    tickerCode: string = "";
    table: Table1[] = [];
}
