import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {MESSAGE_SET} from "../../store/action/message-set.action";

@Injectable()
export class ConfirmService extends BaseService<ConfirmParam> {
  override execute(obj: ConfirmParam) {
    this.url = "/confirm";
    super.execute(obj);
    return this.post(obj)
  }
}

export class ConfirmParam {
  url: string = window.location.origin
}
