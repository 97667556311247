import {Table3} from "./table3";

export class Timerange {
    constructor(clgroup?: string, ticker?:string, table?: Table3[], page?: number, type?: string) {
        this.type = type ?? "";
        this.clgroup = clgroup ?? "";
        this.tickerCode = ticker ?? "";
        this.table = table ?? [];
        this.page = page ?? 1;
    }
    type: string = "";
    clgroup: string = "";
    tickerCode: string = "";
    table: Table3[] = [];
    page: number = 1;
}
