import {UtilService} from "../services/util.service";

export class CodeDtParam {
    code: string = "";
    dt:  string = "";
    t: number = 0;
    d: Date = new Date();
    constructor(code: string, d: Date) {
        this.dt = UtilService.formatToSring(d);
        this.t = d.getTime()
        this.d = d
        this.code = code
    }

}
