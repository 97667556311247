import { createReducer, on } from "@ngrx/store";
import {Cumulative} from "../../../models/cumulative";
import {CUMULATIVE_NUM_INIT} from "../action/cumulative-num.action";
import {CumulativeNum} from "../../../models/cumulative-num";

export const initialState: ReadonlyArray<CumulativeNum> = []

export const numCumulativeReducer = createReducer(
    initialState,
    on(CUMULATIVE_NUM_INIT, (state, { cumulativeNum }) => {
        if (cumulativeNum == null) return state
        if (!state.find(x => x.tickerCode == cumulativeNum.tickerCode && x.dt == cumulativeNum.dt))
        // if(state.indexOf(cumulativeNum) == -1)
            return [...state, cumulativeNum];
        return state.map(x => x.tickerCode == cumulativeNum.tickerCode && x.dt == cumulativeNum.dt? cumulativeNum : x)
    }),
)
