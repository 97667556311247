import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import * as signalR from "@microsoft/signalr"
import {environment} from "../../../environments/environment";
import {UtilService} from "../services/util.service";
import {DT_INIT, MAX_DT_INIT} from "../ngrx/store/action/dt.action";
import {LOADER_SHOW} from "../ngrx/store/action/loader.action";
import {Store} from "@ngrx/store";
import {AppState} from "../ngrx/app.state";

@Injectable({
  providedIn: 'root'
})
export class TraderHubServiceService {
  private connection?: signalR.HubConnection;
  connectionEstablished = new Subject<string>();
  connect() {
    if (!this.connection) {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl( environment.baseUrl+ '/TraderHub')
        .build();

      this.connection?.start().then(() => {
        // console.log('Hub connection started');
        this.connectionEstablished.next("");
      }).catch((err: any) => {
        // console.log(err)
        }
      );

      this.connection?.on('UpdateLastDateTime', (dtStr:string) => {
        // console.log('Received', dtStr);
        let dt = UtilService.formatFromSring(dtStr);
        // console.log(dt)
        this._store.dispatch(DT_INIT({dt}));
        this._store.dispatch(MAX_DT_INIT({dt}));
        let show = false;
        this._store.dispatch(LOADER_SHOW({show}));
      });
    }
  }

  disconnect() {
    if (this.connection) {
      this.connection?.stop();
      this.connection = undefined;
    }
  }
  constructor(private _store: Store<AppState>) { }
}
