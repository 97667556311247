import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {MESSAGE_SET} from "../../store/action/message-set.action";

@Injectable()
export class SignupService extends BaseService<SignupParam> {
  override execute(obj: SignupParam) {
    this.url = "/register";
    super.execute(obj);
    this.post(obj)
      .subscribe((res: any) => {
        this._store.dispatch(MESSAGE_SET({message: ""}))
        if (res.result) {
          this._store.dispatch(MESSAGE_SET({message: "Регистрация прошла успешно. Подтвердите указанный email адрес"}))
        }else{
          this._store.dispatch(MESSAGE_SET({message: res.message}))
        }
      })
  }
}

export class SignupParam {
  constructor(email: string, password: string) {
    this.email = email;
    this.name = email;
    this.password = password;
  }

  name: string = ""
  email: string = ""
  password: string = ""
  url: string = window.location.origin
}
