import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {MESSAGE_SET} from "../../store/action/message-set.action";

@Injectable()
export class ChangeUserService extends BaseService<ChangeUserParams> {
  override execute(obj: ChangeUserParams) {
    this._store.dispatch(MESSAGE_SET({message:''}));
    console.log(obj)
    this.url = "/change_user"
    super.execute(obj)
    return this.put(obj);
  }
}

export class ChangeUserParams {
  constructor(password_reset_token: string, status: number, new_password: string = "") {
    this.password_reset_token = password_reset_token;
    this.status = status;
    this.new_password = new_password;
  }
  password_reset_token: string = "";
  status: number = 0;
  new_password: string = "";
}
