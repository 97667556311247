import { createAction, props } from "@ngrx/store";
import {Settings} from "../../../models/settings";

export const SET_SETTING = createAction(
  "[Settings].Init",
  props<{settings:string}>()
)

export const UPDATE_SETTING = createAction(
    "[Settings].Update",
    props<{settings:string}>()
)
