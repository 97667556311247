import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {USER_INIT} from "../../store/action/user.action";
import {TZ_INITLIST} from "../../store/action/tz.action";

@Injectable()
export class TzGetService extends BaseService<object> {
    override execute(obj: object) {
        this.url = "/tz"
        super.execute(obj)
        this.get(obj)
            .subscribe(r => {
                let tz = r
                this._store.dispatch(TZ_INITLIST({tz}))
            })
    }
}
