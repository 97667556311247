import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'numberWithSign'})
export class NumberWithSignPipe implements PipeTransform {
    transform(value: number | undefined, act: boolean = false) {
        if (act) {
            return Number(value) > 0 ? "+" + Number(value).toLocaleString() : Number(value).toLocaleString()
        }
        return Number(value).toLocaleString();
    }

    private addZero(i: number): string {
        return i < 9 ? "0" + i : i.toString();
    }
}
