import { createReducer, on } from "@ngrx/store";
import {ORDER_CREATE} from "../action/order.action";
import {Order} from "../../../models/order";

export const initialState: Readonly<Order> = new Order()

export const orderReducer = createReducer(
    initialState,
    on(ORDER_CREATE, (state, { order }) => {
        return order;
    }),
)
