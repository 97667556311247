import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../ngrx/app.state";
import {PasswordResetService} from "../../ngrx/services/password-reset/password-reset.service";
import {NotifyGetService} from "../../ngrx/services/notify/notify-get.service";
import {Notify} from "../../models/notify";
import {CookieService} from "ngx-cookie-service";
import {parseJson} from "@angular/cli/src/utilities/json-file";

@Component({
  selector: 'notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
  providers: [NotifyGetService, CookieService]
})
export class NotifyComponent implements OnInit {
  public notifyList: Array<Notify> = [];
  public notifyTempHideList: Map<number, boolean> = new Map<number, boolean>();
  public notifyHideList: Map<number, boolean> = new Map<number, boolean>();

  constructor(private _store: Store<AppState>,
              private _notifyGetService: NotifyGetService,
              private cookieService: CookieService) {

  }

  ngOnInit(): void {
    this.notifyHideList = new Map<number, boolean>();
    const notifyHideListStr=this.cookieService.get('notifyHideList');
    if (notifyHideListStr) {
      this.notifyHideList = new Map(JSON.parse(notifyHideListStr));

    }

    this._store.select('notifies').subscribe(ns => {
      let nList = Object.assign([], ns)
      for (const n in nList) {
        const nf: Notify = nList[n];
        if (!nf.is_closable || (this.notifyHideList && !this.notifyHideList.has(nf.id))) {
          this.notifyList.push(nf);
        }
      }

    });
    this._notifyGetService.execute(Object);
  }

  close(notify: Notify) {
    this.notifyTempHideList.set(notify.id, true)
    if (notify.is_closable) {
      this.notifyHideList.set(notify.id, true)
      const notifyHideListStr = JSON.stringify(Array.from(this.notifyHideList.entries()));
      this.cookieService.set('notifyHideList', notifyHideListStr);
    }
  }
}
