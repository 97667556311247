<script src="../bar/bar.component.ts"></script>
<mat-toolbar class="control-menu">
    <form class="d-flex">
        <!-- тикеры -->
        <div class="small-tickers-control flex-column">
            <section>
                <mat-select [formControl]="selectedTickersCodeControl" matTooltip="Инструменты (не более 10)" multiple>
                    <mat-select-trigger>
                        <ul style="padding-inline-start: 5px;margin: 0">
                            <li class="selection-choice" *ngFor="let selectedTicker of selectedTickersCode">
                                <!--                                                        <span class="selection-choice-remove" (click)="remove(selectedTicker)">×</span>-->
                                {{ selectedTicker }}
                            </li>
                        </ul>
                    </mat-select-trigger>
                    <mat-option *ngFor="let ticker of tickerList" [value]="ticker.code">
                        {{ ticker.code }} - {{ ticker['name'] }}
                    </mat-option>
                </mat-select>
            </section>
        </div>
        <div class="light-splitter"></div>
        <!-- дата -->
        <div class="small-date-control flex-column">
            <section class="date-section">
                <input matInput
                       [matDatepicker]="dp"
                       class="date-input"
                       autocomplete="off"
                       matTooltip="Дата"
                       (click)="dp.open()"
                       (dateChange)="dateChange($event)"
                       [min]="minDate"
                       [max]="maxDate"
                       [formControl]="tradeDateControl">
                <mat-datepicker-toggle class="date-icon" matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </section>
        </div>
        <div class="light-splitter"></div>
        <!-- количество дней -->
        <div class="small-days-control flex-column" *ngIf="show_graph">
            <section>
                <mat-icon>date_range</mat-icon>
                <input matInput matTooltip="Количество дней на графике" [formControl]="daysCountControl">
            </section>
        </div>
        <div class="light-splitter" *ngIf="show_graph"></div>
        <!-- ЮЛ/ФЛ -->
        <div class="small-pos-control flex-column" *ngIf="show_graph">
            <section>
                <mat-select [formControl]="selectedPositionControl" matTooltip="Позиции">
                    <mat-select-trigger class="relative-5">
                        <mat-icon>people</mat-icon>
                        <span>{{ selectedPositionName(selectedPosition) }}</span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let position of positions" [value]="position.code">
                        {{ position.fullname }}
                    </mat-option>
                </mat-select>
            </section>
        </div>
        <div class="light-splitter" *ngIf="show_graph"></div>

        <!-- график и таблица -->
        <div class="small-switch-control flex-column">
            <mat-button-toggle-group class="switch-control-group" name="display" aria-label="display"
                                     [formControl]="selectedDisplayControl" multiple>
                <mat-button-toggle value="show_table" matTooltip="{{'table'|translate}}">
                    <mat-icon>table_chart</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="show_graph" matTooltip="{{'graph'|translate}}">
                    <mat-icon>show_chart</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <!-- исторический режим -->
        <div class="small-select-time flex-column" *ngIf="isAuth && isPayed">
            <section>
                <mat-checkbox matTooltip="Исторический режим" [formControl]="historicalControl">
                    <mat-icon [class]="historicalControl.value ? 'active-item' : ''">history</mat-icon>
                </mat-checkbox>
            </section>
        </div>
        <!-- обновить -->
        <div class="small-refresh flex-column">
            <section>
                <button mat-icon-button matTooltip="Обновить" (click)="autorenew()">
                    <mat-icon aria-setsize="16">autorenew</mat-icon>
                </button>
            </section>
        </div>
    </form>
</mat-toolbar>

<div class="daily-content" style="padding-top: 60px">
    <div class="complex-table" *ngFor="let total of dataList">
        <div class="total-table" *ngIf="show_table">
            <table class="table1">
                <tbody>
                <tr>
                    <th rowspan="2" class="td-info">
                        <div class="td-text-uptime">{{ total.total.table[0].datetime | date: 'dd.MM.yyyy HH:mm' }}
                        </div>
                        <div class="td-text-ticker">{{ total.total.tickerCode }} фьючерс</div>
                        <div>{{ selectedTickerName(total.total.tickerCode) }}</div>
                    </th>
                    <th [ngClass]="{'selectedth': (selectedPosition == 'FIZ' && show_graph)}" colspan="2">Физические
                        лица
                    </th>
                    <th [ngClass]="{'selectedth': (selectedPosition == 'YUR' && show_graph)}" colspan="2">Юридические
                        лица
                    </th>
                    <th rowspan="2" class="td-summ">Сумма</th>
                </tr>
                <tr>
                    <th class="td-longshort">Лонг</th>
                    <th class="td-longshort">Шорт</th>
                    <th class="td-longshort td-green">Лонг</th>
                    <th class="td-longshort td-red">Шорт</th>
                </tr>
                <tr *ngFor="let row of total.total?.table; let even = even">
                    <td>{{ row?.name }}</td>
                    <td [class]="{'font-green': row.fpos_long>0 && !even, 'font-red': row.fpos_long<0 && !even}">
                        {{ row.fpos_long | numberWithSign: !even }}
                    </td>
                    <td [class]="{'font-green': row.fpos_short>0 && !even, 'font-red': row.fpos_short<0 && !even}">
                        {{ row.fpos_short | numberWithSign: !even }}
                    </td>
                    <td class="td-green"
                        [class]="{'font-green': row.ypos_long>0 && !even, 'font-red': row.ypos_long<0 && !even}">
                        {{ row.ypos_long | numberWithSign: !even }}
                    </td>
                    <td class="td-red"
                        [class]="{'font-green': row.ypos_short>0 && !even, 'font-red': row.ypos_short<0 && !even}">
                        {{ row.ypos_short | numberWithSign: !even }}
                    </td>
                    <td [class]="{'font-green': row.spos>0 && !even, 'font-red': row.spos<0 && !even}">{{
                            row.spos |
                                numberWithSign: !even
                        }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="charts" *ngIf="show_graph">
            <light-weight-chart
                watermark='фьючерс на {{selectedTickerName(total.total.tickerCode)}}'
                subWatermark="{{selectedPositionFullName(selectedPosition)}}"
                [handleScroll]="true"
                [data]='total.graph'
                [toolTipWithDiff]="true"
                (onChanged)="update($event)"
                [height]="chartHeight" [width]="chartWidth">
            </light-weight-chart>
        </div>

    </div>
</div>



