import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {USER_INIT} from "../../store/action/user.action";

@Injectable()
export class UserByResetGetService extends BaseService<ResetToken> {
    override execute(obj: ResetToken) {
        this.url = "/user_by_reset?reset_token={token}"
        super.execute(obj)
        this.get(obj)
            .subscribe(r => {
                console.log(r)
                let user = r
                user.settings = JSON.parse(user.settings)
                this._store.dispatch(USER_INIT({user}))
            })
    }




}

export class ResetToken {
  constructor(token: string) {
    this.token = token;
  }
  token: string = ""
}
