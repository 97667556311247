import {MainComponent} from "./main.component";
import {NgModule} from "@angular/core";
import {HomeComponent} from "./home/home.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {PageComponent} from "./page/page.component";
import {MaterialModule} from "../core/shared/modules/material.module";
import {AppRoutingModule} from "../app-routing.module";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../core/shared/shared.module";
import {ComponentsModule} from "../core/components/components.module";
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LkComponent} from './lk/lk.component';
import {SignupComponent} from './signup/signup.component';
import {DailyComponent} from './daily/daily.component';
import {BarComponent} from './bar/bar.component';
import {CumulativeComponent} from './cumulative/cumulative.component';
import {NumberToDtStrPipe} from "../core/pipes/number-to-dt-str.pipe";
import {NumberWithSignPipe} from "../core/pipes/number-with-sign";
import {PayComponent} from './pay/pay.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {ConfirmComponent} from './confirm/confirm.component';
import {DirectionComponent} from './direction/direction.component';
import { AlertComponent } from './alert/alert.component';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from "@angular/material/snack-bar";
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from "ng-recaptcha-2";

const RECAPTCHA_V2_DUMMY_KEY = '6LefX9ojAAAAAJkk5KnqYv_Z8UMcXSaDdDZHiW9i';

const COMPONENTS = [
  HomeComponent,
  NotFoundComponent,
  MainComponent,
  PageComponent,
  LkComponent,
  LoginComponent,
  SignupComponent,
  DailyComponent,
  BarComponent,
  DirectionComponent,
  AlertComponent,
  CumulativeComponent,
  NumberToDtStrPipe,
  NumberWithSignPipe,
  PayComponent,
  PasswordResetComponent,
  ConfirmComponent
];

@NgModule({
  imports: [
    ComponentsModule,
    SharedModule,
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  exports: [COMPONENTS],
  declarations: [COMPONENTS],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: RECAPTCHA_V2_DUMMY_KEY
      } as RecaptchaSettings
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500} }
  ],
})
export class MainModule {
}
