<div class="lk-contact">
  <h1>Мой профиль</h1>

  <div class="row">
    <div style="display:flex">
      <mat-icon matTooltip="Email не подтверждён" *ngIf="u?.status==9" class="font-red">check_circle</mat-icon>
      <mat-icon matTooltip="Email подтверждён" *ngIf="u?.status==10" class="font-green">check_circle</mat-icon>
      <div class="bold"><label>Email</label>: {{u?.email}}</div>
      <a *ngIf="u?.status==9 && !isDisabled" [class]="{'isDisabled': isDisabled}" (click)="sendConfirm()"
         title="Будет отправлена ссылка для подтверждения на указанные Email">Подтвердить</a>
    </div>
    <div class="lk-license">
      <div><label>Подписка до</label>: {{u?.active_to | numberToDtStr: 'date'}}</div>
      <div class="d-flex justify-space-between" [style]="u?.canTestDrive?'min-width: 220px':''">
        <button *ngIf="u?.status==10" mat-raised-button color="primary" type="button"
                routerLink="pay">{{'Pay'|translate}}</button>
        <button *ngIf="u?.canTestDrive" mat-raised-button color="primary" type="button"
                (click)="setTestDrive()">{{'Test Drive'|translate}}</button>
      </div>
    </div>
    <!--        <form [formGroup]="userForm" (ngSubmit)="onSubmit(userForm.value)">-->
    <!--            <mat-form-field appearance="fill">-->
    <!--                <mat-label>Часовой пояс</mat-label>-->
    <!--                <mat-select formControlName="tz">-->
    <!--                    <mat-option *ngFor="let tz of tzList" [value]="tz.id">-->
    <!--                        ({{tz.code}}) {{tz.name}}-->
    <!--                    </mat-option>-->
    <!--                </mat-select>-->
    <!--            </mat-form-field>-->
    <!--            <mat-dialog-actions>-->
    <!--                <button mat-raised-button color="primary" type="submit" [disabled]="userForm.invalid">-->
    <!--                    {{'Save'|translate}}-->
    <!--                </button>-->
    <!--            </mat-dialog-actions>-->
    <!--        </form>-->
  </div>
</div>
