import {Injectable} from '@angular/core';
import {UtilService} from "../services/util.service";
import {CodeParam} from "../models/code-param";
import {DT_INIT, MAX_DT_INIT} from "../ngrx/store/action/dt.action";
import {BaseWebsocketService} from "./base-websocket.service";
import {LOADER_SHOW} from "../ngrx/store/action/loader.action";

@Injectable()
export class MaxDtService extends BaseWebsocketService<CodeParam> {
  private status: boolean = false;

  run(obj: CodeParam) {
    this.url = "/max/{code}"
    this.status = true;
    super.execute(obj).subscribe(r => {
      let dt = UtilService.formatFromSring(r.data);
      // console.log(dt)
      this._store.dispatch(DT_INIT({dt}));
      this._store.dispatch(MAX_DT_INIT({dt}));
      let show = false;
      this._store.dispatch(LOADER_SHOW({show}));
    })
  }

  override stop(obj: any): void {
    this.status = false;
    this.url = "/max/{code}"
    let show = true;
    this._store.dispatch(LOADER_SHOW({show}));
    super.stop(obj);
  }
}
