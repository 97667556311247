import { createReducer, on } from "@ngrx/store";
import {Page} from "../../../models/page";
import {PAGE_INIT, PAGE_INITLIST} from "../action/page.action";
import {User} from "../../../models/user";
import {USER_INIT} from "../action/user.action";
import {Tz} from "../../../models/tz";
import {TZ_INITLIST} from "../action/tz.action";

export const initialState: ReadonlyArray<Tz> = []

export const tzReducer = createReducer(
    initialState,
    on(TZ_INITLIST, (state, { tz }) => [...tz]),
)
