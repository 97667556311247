import { createAction, props } from "@ngrx/store";
import {Total} from "../../../models/total";

export const TOTAL_INIT = createAction(
    "[Total].Init",
    props<{total:Total}>()
)

export const TOTAL_CLEAR = createAction(
  "[Total].Clear"
)
