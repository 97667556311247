import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {Table2} from "../../../models/table2";
import {CUMULATIVE_NUM_INIT} from "../../store/action/cumulative-num.action";
import {CumulativeNum} from "../../../models/cumulative-num";
import {CodeDtParam} from "../../../models/code-dt-param";
import {UtilService} from "../../../services/util.service";

@Injectable()
export class CumulativeNumGetService extends BaseService<CodeDtParam> {
  override execute(obj: CodeDtParam) {
    this.url = "/cumulative_num?ticker={code}&dt={dt}"
    super.execute(obj)
    this.get(obj)
      .subscribe((r: Table2[]) => {
        let t = r.sort(function (a, b) {
          if (a.time < b.time) return 1
          if (a.time > b.time) return -1
          return 0
        })
        let time = new Date();
        // let time = obj.d;
        // if (r.length > 0) time = UtilService.formatFromSring(r[0].datetime.toString())
        let cumulativeNum = new CumulativeNum(time, obj.code, r)
        this._store.dispatch(CUMULATIVE_NUM_INIT({cumulativeNum}))
      })
  }
}
