<h2 mat-dialog-title>
    {{title}}
</h2>

<mat-dialog-content>
    <p>{{message}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button (click)="onDismiss()">Нет</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Да</button>
</mat-dialog-actions>