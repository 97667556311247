<mat-toolbar class="control-menu">
  <form class="d-flex">
    <!-- инструмент -->
    <div class="small-ticker-control flex-column">
        <section>
            <mat-select matTooltip="Инструмент" [formControl]="selectedTickerCodeControl">
                <mat-select-trigger><span class="active-ticker">{{selectedTickerCode}}</span></mat-select-trigger>
                <mat-option *ngFor="let ticker of tickerList" [value]="ticker.code">{{ticker.code}}
                    - {{ticker['name']}}</mat-option>
            </mat-select>
        </section>
    </div>
    <div class="light-splitter"></div>
    <div class="small-tf-control flex-column">
        <section>
            <mat-select matTooltip="Таймфрейм" [formControl]="selectedTimeFrameControl">
                <mat-option *ngFor="let step of stepList" [value]="step.value">{{step.name}}</mat-option>
            </mat-select>
        </section>
    </div>
    <div class="light-splitter"></div>
    <!-- дата -->
    <div class="small-date-control flex-column">
        <section class="date-section">
            <input matInput [matDatepicker]="dp"
                   class="date-input"
                   autocomplete="off"
                   matTooltip="Дата"
                   (click)="dp.open()"
                   (dateChange)="dateChange($event)"
                   [min]="minDate" [max]="maxDate"
                   [formControl]="tradeDateControl">
            <mat-datepicker-toggle class="date-icon" matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
        </section>
    </div>
    <div class="light-splitter" *ngIf="isAuth && isPayed"></div>
    <!-- уведомления -->
    <div class="small-notify-control" *ngIf="isAuth && isPayed">
        <button (click)="addNotify()" matTooltip="Добавить оповещение">
            <mat-icon>add_alert</mat-icon>
        </button>
        <section>
            <div class="mat-mdc-select-arrow">
                <svg viewBox="0 0 24 24" width="24px" height="24px" focusable="false" class="ng-tns-c177-35"
                     (click)="changeNotifies()"
                     matTooltip="Список настроенных оповещений">
                    <path d="M7 10l5 5 5-5z" class="ng-tns-c177-35"></path>
                </svg>
            </div>
            <div *ngIf="notifyList" #notifyPopup class="notify-list">
                <mat-list role="list">
                    <mat-list-item role="listitem" *ngFor="let alert of alertList">
                        <div class="notify-row">
                            <div class="notify-ticker">{{alert.ticker}}</div>
                            <div class="light-splitter"></div>
                            <div>{{stepName(alert.timeframe)}}</div>
                            <div class="light-splitter"></div>
                            <div>{{positionName(alert.position)}}</div>
                            <div class="light-splitter"></div>
                            <div [class]="{'font-green': alert.direction === 'L', 'font-red': alert.direction === 'S'}">{{alert.direction}}</div>
                            <div class="light-splitter"></div>
                            <div class="notify-sum"
                                 [class]="{'font-green': alert.value>=0, 'font-red': alert.value<0}">{{alert.value | numberWithSign: true}}</div>
                            <div class="light-splitter"></div>
                            <div class="notify-how">
                                <mat-icon
                                        [matTooltip]="alert.email ? 'Откл. оповещение на Email' : 'Вкл. оповещение на Email'"
                                        (click)="enable(alert.id, 'email')" [class]="{'active-icon':alert.email}">email
                                </mat-icon>
                                <mat-icon
                                        [matTooltip]="alert.telegram ? 'Откл. оповещение в Telegram' : 'Вкл. оповещение в Telegram'"
                                        (click)="enable(alert.id, 'telegram')" [class]="{'active-icon':alert.telegram}">
                                    send
                                </mat-icon>
                            </div>
                            <div class="notify-delete" (click)="delete_notify(alert.id)"
                                 [matTooltip]="'Удалить оповещение'">
                                <mat-icon>clear</mat-icon>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </section>
      </div>
    <div class="light-splitter" *ngIf="isAuth && isPayed"></div>
    <!-- исторический режим -->
    <div class="small-select-time flex-column">
        <section>
            <mat-checkbox matTooltip="Исторический режим"  [formControl]="historicalControl"
                          *ngIf="isAuth && isPayed">
              <mat-icon [class]="historicalControl.value ? 'active-item' : ''">history</mat-icon>
            </mat-checkbox>
        </section>
    </div>
  <!-- обновить -->
  <div class="small-refresh flex-column">
    <section>
      <button mat-icon-button matTooltip="Обновить" (click)="autorenew()">
        <mat-icon aria-setsize="16">autorenew</mat-icon>
      </button>
    </section>
  </div>
  </form>
</mat-toolbar>


<div class="total-table" style="padding-top: 60px">
    <table class="table1" *ngIf="total.table[0]">
        <tbody>
        <tr>
            <th rowspan="2" class="td-info">
                <div class="td-text-uptime">{{total.table[0].datetime | date: 'dd.MM.yyyy HH:mm'}}</div>
                <div class="td-text-ticker">{{total.tickerCode}} фьючерс</div>
                <div>{{selectedTickerName(total.tickerCode)}}</div>
            </th>
            <th colspan="2">Физические лица</th>
            <th colspan="2">Юридические лица</th>
            <th rowspan="2" class="td-summ">Сумма</th>
        </tr>
        <tr>
            <th class="td-longshort">Лонг</th>
            <th class="td-longshort">Шорт</th>
            <th class="td-longshort td-green">Лонг</th>
            <th class="td-longshort td-red">Шорт</th>
        </tr>
        <tr *ngFor="let row of total?.table; let even = even">
            <td>{{row?.name}}</td>
            <td [class]="{'font-green': row.fpos_long>0 && !even, 'font-red': row.fpos_long<0 && !even}">{{row.fpos_long | numberWithSign: !even}}</td>
            <td [class]="{'font-green': row.fpos_short>0 && !even, 'font-red': row.fpos_short<0 && !even}">{{row.fpos_short | numberWithSign: !even}}</td>
            <td class="td-green"
                [class]="{'font-green': row.ypos_long>0 && !even, 'font-red': row.ypos_long<0 && !even}">{{row.ypos_long | numberWithSign: !even}}</td>
            <td class="td-red"
                [class]="{'font-green': row.ypos_short>0 && !even, 'font-red': row.ypos_short<0 && !even}">{{row.ypos_short | numberWithSign: !even}}</td>
            <td [class]="{'font-green': row.spos>0 && !even, 'font-red': row.spos<0 && !even}">{{row.spos | numberWithSign: !even}}</td>
        </tr>
        </tbody>
    </table>
</div>

<div class="complex-table">
    <div class="table-scroller">
        <table class="table1" *ngIf="total.table[0]">
            <tbody>
            <tr>
                <th colspan="5" style="text-align: center">
                    {{total.tickerCode}} фьючерс - {{total.table[0].datetime | date: 'dd.MM.yyyy HH:mm'}}
                </th>
            </tr>
            <tr>
                <th colspan="2">Физические лица</th>
                <th rowspan="2" class="td-time">Бар</th>
                <th colspan="2">Юридические лица</th>
            </tr>
            <tr>
                <th class="td-longshort">Лонг</th>
                <th class="td-longshort">Шорт</th>
                <th class="td-longshort td-green">Лонг</th>
                <th class="td-longshort td-red">Шорт</th>
            </tr>
            <tr *ngFor="let row of bar?.table">
                <td [class]="{'font-green': row.fpos_long>0, 'font-red': row.fpos_long<0}">{{row.fpos_long | numberWithSign: true}}</td>
                <td [class]="{'font-green': row.fpos_short>0, 'font-red': row.fpos_short<0}">{{row.fpos_short | numberWithSign: true}}</td>
                <td>{{row?.datetime | date: 'HH:mm'}}</td>
                <td class="td-green"
                    [class]="{'font-green': row.ypos_long>0, 'font-red': row.ypos_long<0}">{{row.ypos_long | numberWithSign: true}}</td>
                <td class="td-red"
                    [class]="{'font-green': row.ypos_short>0, 'font-red': row.ypos_short<0}">{{row.ypos_short | numberWithSign: true}}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="charts">
        <div class="chart">
            <light-weight-chart
                    [data]='yDataLong'
                    [watermark]="'Юридические лица'"
                    [subWatermark]="'Длинные позиции'"
                    [showToolTip]="true"
                    [timeVisible]="true"
                    [bgColor]="'#d7ffe21f'"
                    [height]="300" [width]="1200"
                    [itemsCount]="count"
                    [priceMaxValue]="priceMaxValue" [priceMinValue]="priceMinValue"
            ></light-weight-chart>
        </div>
        <div class="chart">
            <light-weight-chart
                    [data]='yDataShort'
                    [watermark]="'Юридические лица'"
                    [subWatermark]="'Короткие позиции'"
                    [showToolTip]="true"
                    [timeVisible]="true"
                    [bgColor]="'#ffdada1f'"
                    [height]="300" [width]="1200"
                    [itemsCount]="count"
                    [priceMaxValue]="priceMaxValue" [priceMinValue]="priceMinValue"
            ></light-weight-chart>
        </div>
        <br>
        <div class="chart">
            <light-weight-chart
                    [data]='fDataLong'
                    [watermark]="'Физические лица'"
                    [subWatermark]="'Длинные позиции'"
                    [showToolTip]="true"
                    [timeVisible]="true"
                    [height]="300" [width]="1200"
                    [itemsCount]="count"
                    [priceMaxValue]="priceMaxValue" [priceMinValue]="priceMinValue"
            ></light-weight-chart>
        </div>
        <div class="chart">
            <light-weight-chart
                    [data]='fDataShort'
                    [watermark]="'Физические лица'"
                    [subWatermark]="'Короткие позиции'"
                    [showToolTip]="true"
                    [timeVisible]="true"
                    [height]="300" [width]="1200"
                    [itemsCount]="count"
                    [priceMaxValue]="priceMaxValue" [priceMinValue]="priceMinValue"
            ></light-weight-chart>
        </div>
        <!--<bar-chart [data]='yDataLong' yTitle="Длинные позиции" legendTitle="Юридические лица" bgColor='#F7FFF7'></bar-chart>
        <bar-chart [data]='yDataShort' yTitle="Короткие позиции" legendTitle="Юридические лица" bgColor='#FFF8F8'></bar-chart>
        <br>
        <bar-chart [data]='fDataLong' yTitle="Длинные позиции" legendTitle="Физические лица" bgColor='transparent'></bar-chart>
        <bar-chart [data]='fDataShort' yTitle="Короткие позиции" legendTitle="Физические лица" bgColor='transparent'></bar-chart>-->
    </div>
</div>
