import { createReducer, on } from "@ngrx/store";
import {Page} from "../../../models/page";
import {PAGE_INIT, PAGE_INITLIST} from "../action/page.action";
import {Ticker} from "../../../models/ticker";
import {TICKERS_INITLIST} from "../action/ticker.action";

export const initialState: ReadonlyArray<Ticker> = []

export const tickerReducer = createReducer(
    initialState,
    on(TICKERS_INITLIST, (state, { tickers }) => [...tickers]),
)
