import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {MESSAGE_SET} from "../../store/action/message-set.action";

@Injectable()
export class PasswordResetService extends BaseService<PasswordResetParam> {
  override execute(obj: PasswordResetParam) {
    this._store.dispatch(MESSAGE_SET({message:''}));
    this.url = "/password_reset";
    super.execute(obj);
    return this.post(obj);
  }
}


export class PasswordResetParam {
  constructor(email: string) {
    this.email = email;
  }
  email: string = ""
  url: string = window.location.origin
}

