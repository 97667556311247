import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../core/ngrx/app.state";
import {ActivatedRoute} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {Ticker} from "../../core/models/ticker";
import {TotalGetService} from "../../core/ngrx/services/total/total-get.service";
import {FormControl} from "@angular/forms";
import {Total} from "../../core/models/total";
import {UtilService} from "../../core/services/util.service";
import {MaxGetService} from "../../core/ngrx/services/max/max-get.service";
import {CumulativeGetService} from "../../core/ngrx/services/cumulative/cumulative-get.service";
import {Cumulative} from "../../core/models/cumulative";
import {CumulativeNumGetService} from "../../core/ngrx/services/cumulative/cumulative-num-get.service";
import {CodeParam} from "../../core/models/code-param";
import {MaxDtService} from "../../core/websocket/max-dt.service";
import {DT_INIT} from "../../core/ngrx/store/action/dt.action";
import {CodeDtParam} from "../../core/models/code-dt-param";
import {CumulativeNum} from "../../core/models/cumulative-num";
import {Settings} from "../../core/models/settings";
import {SET_SETTING, UPDATE_SETTING} from "../../core/ngrx/store/action/setting.action";
import {MinGetService} from "../../core/ngrx/services/min/min-get.service";
import {UserGetService} from "../../core/ngrx/services/user/user-get.service";
import {LOADER_SHOW} from "../../core/ngrx/store/action/loader.action";
import {TraderHubServiceService} from "../../core/signal-r/trader-hub-service.service";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";


@Component({
    selector: 'cumulative',
    templateUrl: './cumulative.component.html',
    styleUrls: ['./cumulative.component.scss'],
    providers: [UserGetService, TotalGetService, CumulativeGetService, CumulativeNumGetService, MinGetService, MaxGetService]
})
export class CumulativeComponent implements OnInit, OnDestroy {
    // private _tradedate: any;
    // private _ticker: any;
    private _cumulativeSub: Subscription | undefined;
    private _cumulativeSub1: Subscription | undefined;
    private _cumulativeSub2: Subscription | undefined;
    private _cumulativeSub3: Subscription | undefined;
    private _cumulativeSub4: Subscription | undefined;
    private _cumulativeSub5: Subscription | undefined;
    private _cumulativeSub6: Subscription | undefined;
    private _cumulativeSub7: Subscription | undefined;
    private _cumulativeSub8: Subscription | undefined;
    fdata: any = {};
    ydata: any = {};
    tickerList: Ticker[] | any;
    auto: boolean = true;

    selectedTicker: Ticker = new Ticker();
    selectedTickerCode: string = "";
    tradeDateControl: FormControl = new FormControl<Date>(new Date());
    selectedDt: Date = new Date();
    total: Total = new Total();
    cumulative: Cumulative = new Cumulative();
    fLegendTitle: string = "";
    yLegendTitle: string = "";
    private _settings: Settings | undefined;
    maxDate: Date = new Date();
    minDate: Date = new Date();
    isPayed: boolean = false;
    isAuth: boolean = false;
    greenColor: string = '#77a45b';
    redColor: string = '#d46f6c';
    selectedTickerCodeControl: FormControl = new FormControl<string>('');
    historicalControl: FormControl = new FormControl(false);


    constructor(
        private _store: Store<AppState>,
        private _activatedRoute: ActivatedRoute,
        private _titleService: Title,
        // private _tickerGetService: TickerGetService,
        private _totalGetService: TotalGetService,
        private _cumulativeGetService: CumulativeGetService,
        private _cumulativeNumGetService: CumulativeNumGetService,
        private _maxGetService: MaxGetService,
        private _minGetService: MinGetService,
        private _userGetService: UserGetService,
        private _traderHubServiceService: TraderHubServiceService,
    ) {


        this._userGetService.execute(Object);
    }

    ngOnInit(): void {

        setTimeout(() => {
            if (!this.isAuth)
                this._store.dispatch(UPDATE_SETTING({settings: JSON.stringify(new Settings())}));
            else
                this.populate();
        }, 500)

        if (!this._cumulativeSub5)
            this._cumulativeSub5 = this._store.select('user').subscribe(user => {
                this.isAuth = user.id > 0;
                let now = new Date();
                this.isPayed = (this.isAuth) ? user.active_to * 1000 >= now.getTime() : false;
            });

        if (!this._cumulativeSub6)
            this._cumulativeSub6 = this._store.select('setting').subscribe(setting => {
                if (setting) {
                    this._settings = Object.assign(new Settings(), JSON.parse(setting)) //new Settings(setting);
                } else if (setting != undefined) {
                    this._settings = new Settings();
                }
                if (this._settings) {
                    this.auto = this._settings.auto;
                    this.historicalControl.setValue(!(this._settings.auto))
                    this.selectedTickerCode = this._settings.selectedTickerCode;
                    this.selectedTickerCodeControl.setValue(this._settings.selectedTickerCode);
                }

            });

        // пописываемся на изменение данных для графиков
        if (!this._cumulativeSub4)
            this._cumulativeSub4 = this._store.select('numCumulatives').subscribe(cumulatives => {
                let c1 = UtilService.getLast<CumulativeNum>(cumulatives.filter((t: CumulativeNum) => t.tickerCode == this.selectedTickerCode), new CumulativeNum());
                if (c1 != null) {
                    this.fLegendTitle = 'Физические лица';
                    this.yLegendTitle = 'Юридические лица';
                    let c: Cumulative = c1;
                    const unique = c.table.filter((obj, index, self) => index ===
                        self.findIndex((o) => JSON.stringify(o) == JSON.stringify(obj))
                    );

                    this.fdata = [
                        {
                            name: 'Лонг',
                            type: 'line',
                            color: this.greenColor,
                            data: unique.map((t: any) => {
                                return {
                                    time: UtilService.formatUtcFromSring(t.datetime).getTime() / 1000,
                                    value: t.fpos_long
                                };
                            }).sort((a: any, b: any) => a.time - b.time)
                        },
                        {
                            name: 'Шорт',
                            type: 'line',
                            color: this.redColor,
                            data: unique.map((t: any) => {
                                return {
                                    time: UtilService.formatUtcFromSring(t.datetime).getTime() / 1000,
                                    value: t.fpos_short
                                };
                            }).sort((a: any, b: any) => a.time - b.time)
                        }
                    ];

                    this.ydata = [
                        {
                            name: 'Лонг',
                            type: 'line',
                            color: this.greenColor,
                            data: unique.map((t: any) => {
                                return {
                                    time: UtilService.formatUtcFromSring(t.datetime).getTime() / 1000,
                                    value: t.ypos_long
                                };
                            }).sort((a: any, b: any) => a.time - b.time)
                        },
                        {
                            name: 'Шорт',
                            type: 'line',
                            color: this.redColor,
                            data: unique.map((t: any) => {
                                return {
                                    time: UtilService.formatUtcFromSring(t.datetime).getTime() / 1000,
                                    value: t.ypos_short
                                };
                            }).sort((a: any, b: any) => a.time - b.time)
                        }
                    ];

                }
            });

        // пописываемся на изменение данных cumulative
        if (!this._cumulativeSub3)
            this._cumulativeSub3 = this._store.select('cumulatives').subscribe(cumulatives => {
                this.cumulative = UtilService.getLast<Cumulative>(cumulatives.filter((t: Cumulative) => t.tickerCode == this.selectedTickerCode), new Cumulative());
            });

        // подписываемся на изменение данных total
        if (!this._cumulativeSub2)
            this._cumulativeSub2 = this._store.select('totals').subscribe(totals => {
                let totalList = Object.assign([], totals);
                let totalListFiltered = totalList.filter((t: Total) => t.tickerCode == this.selectedTickerCode);
                this.total = UtilService.getLast<Total>(totalListFiltered, new Total());
            });

        // пописываемся на изменение даты
        if (!this._cumulativeSub1)
            this._cumulativeSub1 = this._store.select('dt').subscribe(dt => {
                if (dt.getTime() == this.selectedDt.getTime())
                    return
                this.selectedDt = Object.assign(dt);
                this.maxDate = Object.assign(dt);
                this.tradeDateControl = new FormControl(this.selectedDt);
                if (this.auto) {
                    this.tradeDateControl.disable();
                }
                this.populate();
            })

        // подписываемся на изменение списка тикеров
        if (!this._cumulativeSub)
            this._cumulativeSub = this._store.select('tickers').subscribe(tickers => {
                this.tickerList = Object.assign([], tickers);
                this.selectedTicker = this.tickerList[0];
                if (this.selectedTickerCode != '')
                    this.selectedTicker = this.tickerList.find((t: Ticker) => t.code == this.selectedTickerCode);

                if (this.selectedTicker != null) {
                    this.selectedTickerCode = this.selectedTicker.code;
                    this._minGetService.execute(new CodeParam(this.selectedTickerCode))
                    if (!this.selectedDt || this.selectedDt.getUTCDate() == new Date().getUTCDate())
                        this._maxGetService.execute(new CodeParam(this.selectedTickerCode))
                }
            });

        if (!this._cumulativeSub7)
            this._cumulativeSub7 = this._store.select('maxDt').subscribe(dt => {
                this.maxDate = Object.assign(dt);
            })

        if (!this._cumulativeSub8)
            this._cumulativeSub8 = this._store.select('minDt').subscribe(dt => {
                this.minDate = Object.assign(dt);
            })

        // this.tradeDateControl.valueChanges.subscribe((value) => {
        //     this.selectedDt = value;
        //     this._store.dispatch(DT_INIT({dt: this.selectedDt}))
        //     this.populate();
        // });

        this.selectedTickerCodeControl.valueChanges.subscribe((value) => {
            this.selectedTickerCode = value;
            this.populate();
            setTimeout(() => {
                this.changeSettings();
            }, 300)
        });

        this.historicalControl.valueChanges.subscribe((value) => {
            this.auto = !value;
            if (this.auto) {
                if (this.tickerList.length > 0)
                    this._traderHubServiceService.connect();
                this.tradeDateControl.disable();
            } else {
                if (this.tickerList.length > 0)
                    this._traderHubServiceService.disconnect();
                this.tradeDateControl.enable();
            }
            setTimeout(() => {
                this.changeSettings();
            })
        })
    }

    public autorenew() {
        this._store.dispatch(LOADER_SHOW({'show': true}));
        this.populate()
        if (this.auto && this.selectedTickerCode) {
            // this._maxDtService.run(new CodeParam(this.selectedTickerCode));
            this._traderHubServiceService.connect();
        }
    }

    private populate() {
        if (this.selectedDt && this.selectedTickerCode) {
            this._titleService.setTitle(`${this.selectedTickerCode} - Накопительный - ${this.selectedDt.toLocaleDateString()} < Betatrader.ru`);
            this._totalGetService.execute(new CodeDtParam(this.selectedTickerCode, this.selectedDt));
            this._cumulativeGetService.execute(new CodeDtParam(this.selectedTickerCode, this.selectedDt));
            this._cumulativeNumGetService.execute(new CodeDtParam(this.selectedTickerCode, this.selectedDt));
        }
    }

    ngOnDestroy(): void {
        this._cumulativeSub?.unsubscribe();
        this._cumulativeSub1?.unsubscribe();
        this._cumulativeSub2?.unsubscribe();
        this._cumulativeSub3?.unsubscribe();
        this._cumulativeSub4?.unsubscribe();
        this._cumulativeSub5?.unsubscribe();
        this._cumulativeSub6?.unsubscribe();
        this._cumulativeSub7?.unsubscribe();
        this._cumulativeSub8?.unsubscribe();
        this._traderHubServiceService.disconnect();
    }

    selectedTickerName(ticker: string) {
        for (let x of this.tickerList) {
            if (x.code == ticker) {
                return x.name
            }
        }
        return ""
    }

    changeSettings() {
        if (this._settings != undefined) {
            if (this.selectedTickerCode) {
                if (this._settings) {
                    let settings = this._settings;
                    let needUpdate = false;
                    if (settings.selectedTickerCode != this.selectedTickerCode
                        || settings.auto != this.auto) {
                        needUpdate = true;
                    }
                    settings.selectedTickerCode = this.selectedTickerCode;
                    settings.auto = this.auto ?? false;
                    if (needUpdate) {
                        this._store.dispatch(SET_SETTING({settings: JSON.stringify(settings)}));
                    }
                }
            }
        }
    }


    dateChange($event: MatDatepickerInputEvent<any, any>) {
        this.selectedDt = $event.value;
        this._store.dispatch(DT_INIT({dt: this.selectedDt}))
        this.populate();
    }
}
