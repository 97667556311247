import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {CodeParam} from "../../core/models/code-param";
import {DT_INIT} from "../../core/ngrx/store/action/dt.action";
import {Ticker} from "../../core/models/ticker";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/ngrx/app.state";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {TotalGetService} from "../../core/ngrx/services/total/total-get.service";
import {MaxGetService} from "../../core/ngrx/services/max/max-get.service";
import {TickerGetService} from "../../core/ngrx/services/ticker/ticker-get.service";
import {MaxDtService} from "../../core/websocket/max-dt.service";
import {retryWhen, Subscription, timeout} from "rxjs";
import {MESSAGE_SET} from "../../core/ngrx/store/action/message-set.action";
import {CodeDtParam} from "../../core/models/code-dt-param";
import {Total} from "../../core/models/total";
import {DailyGetService, DailyParam} from "../../core/ngrx/services/daily/daily-get.service";
import {Daily} from "../../core/models/daily";
import {UtilService} from "../../core/services/util.service";
import {Settings} from "../../core/models/settings";
import {SET_SETTING, UPDATE_SETTING} from "../../core/ngrx/store/action/setting.action";
import {MinGetService} from "../../core/ngrx/services/min/min-get.service";
import {UserGetService} from "../../core/ngrx/services/user/user-get.service";
import {MatSelect, MatSelectChange} from "@angular/material/select";
import {LOADER_SHOW} from "../../core/ngrx/store/action/loader.action";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {TraderHubServiceService} from "../../core/signal-r/trader-hub-service.service";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";

@Component({
    selector: 'daily',
    templateUrl: './daily.component.html',
    styleUrls: ['./daily.component.scss'],
    providers: [UserGetService, TickerGetService, DailyGetService, TotalGetService, MinGetService, MaxGetService]
})

export class DailyComponent implements OnInit, OnDestroy {
    @ViewChild('tickerSelect') tickerSelect: MatSelect | undefined;
    tickerList: Ticker[] = [];
    selectedTicker: Ticker | undefined;
    selectedTickersCode: string[] = [];
    selectedDt: Date = new Date();
    auto: boolean = false;
    public daysCount: number = 10;


    private _daily_sub: Subscription | undefined;
    private _daily_sub1: Subscription | undefined;
    private _daily_sub2: Subscription | undefined;
    private _daily_sub3: Subscription | undefined;
    private _daily_sub4: Subscription | undefined;
    private _daily_sub5: Subscription | undefined;
    private _daily_sub6: Subscription | undefined;
    private _daily_sub7: Subscription | undefined;
    private _daily_sub8: Subscription | undefined;
    selectedPosition: any = "YUR";
    positions: any = [
        {'code': 'FIZ', 'name': 'ФЛ', 'fullname': 'Физические лица'},
        {'code': 'YUR', 'name': 'ЮЛ', 'fullname': 'Юридические лица'},
    ];
    show_table: boolean = false;
    show_graph: boolean = false;
    totals: Total[] = [];
    dailies: Daily[] = [];
    dataList: any[] = [];
    private _settings: Settings | undefined;
    maxDate: Date = new Date();
    minDate: Date = new Date();
    isPayed: boolean = false;
    isAuth: boolean = false;
    chartHeight: number = 220;
    chartWidth: number = 900;
    private loading: boolean = false;
    private endLoad: boolean = false;
    selectedDisplay: string[] = [];

    selectedDisplayControl: FormControl = new FormControl(["show_table"]);
    selectedTickersCodeControl: FormControl = new FormControl([]);
    selectedPositionControl: FormControl = new FormControl("YUR");
    daysCountControl: FormControl = new FormControl(10);
    historicalControl: FormControl = new FormControl(true);
    tradeDateControl: FormControl = new FormControl<Date>(new Date());


    constructor(private _router: Router,
                private _store: Store<AppState>,
                private _titleService: Title,
                private _totalGetService: TotalGetService,
                private _dailyGetService: DailyGetService,
                private _maxGetService: MaxGetService,
                private _minGetService: MinGetService,
                private _userGetService: UserGetService,
                private _traderHubServiceService: TraderHubServiceService) {


        /*
            if (!this._daily_sub8)
              // пописываемся на сигнал окончания загрузки
              this._daily_sub8 = this._store.select('endLoad').subscribe(d => {
                this.endLoad = d;
              })
        */

        //this.selectedPosition = this.positions[0];

        this._userGetService.execute(Object);
    }

    public autorenew() {
        this._store.dispatch(LOADER_SHOW({'show': true}));
        this.populate()
        this._traderHubServiceService.connect();
    }

    ngOnInit(): void {
        setTimeout(() => {
            if (!this.isAuth)
                this._store.dispatch(UPDATE_SETTING({settings: JSON.stringify(new Settings())}));
            //this.populate();
        }, 300)

        // this.tradeDateControl.valueChanges.subscribe((value) => {
        //     this.selectedDt = value;
        //     this._store.dispatch(DT_INIT({dt: this.selectedDt}))
        //     this.populate();
        // });

        this.selectedTickersCodeControl.valueChanges.subscribe((value) => {
            this.selectedTickersCode = value;

            setTimeout(() => {
                this.populate();
                this.changeSettings();
            }, 300)
        });

        this.selectedPositionControl.valueChanges.subscribe((value) => {
            this.selectedPosition = value;
            this.populate();
            setTimeout(() => {
                this.changeSettings();
            }, 300)
        });

        this.selectedDisplayControl.valueChanges.subscribe((value) => {
            if (value.length > 0) {
                this.show_table = false;
                this.show_graph = false;
                value.forEach((element: string) => {
                    if (element == "show_table") {
                        this.show_table = true;
                        this.chartWidth = this.show_graph ? 920 : 1500;
                        this.chartHeight = this.show_graph ? 220 : 300;
                    }
                    if (element == "show_graph") {
                        this.show_graph = true;
                        this.chartWidth = this.show_table ? 920 : 1500;
                        this.chartHeight = this.show_table ? 220 : 300;
                    }
                })
            }
            setTimeout(() => {
                this.changeSettings();
            }, 300)
        });

        this.historicalControl.valueChanges.subscribe((value) => {
            this.auto = !value;
            if (!value) {
                this.tradeDateControl.disable();
            } else
                this.tradeDateControl.enable()

            setTimeout(() => {
                this.changeSettings();
            }, 300)
        });

        this.daysCountControl.valueChanges.subscribe((value) => {
            this.daysCount = value;
            setTimeout(() => {
                this.changeSettings();
            }, 300)
        });

        if (!this._daily_sub4)
            this._daily_sub4 = this._store.select('user').subscribe(user => {
                if (user) {
                    this.isAuth = user.id > 0;
                    let now = new Date();
                    this.isPayed = user.active_to * 1000 >= now.getTime();
                }
            });

        if (!this._daily_sub5)
            this._daily_sub5 = this._store.select('setting').subscribe(setting => {
                if (setting) {
                    this._settings = Object.assign(new Settings(), JSON.parse(setting)) //new Settings(setting);
                } else if (setting != undefined) {
                    this._settings = new Settings();
                }
                if (this._settings) {
                    this.auto = this._settings.auto;
                    this.historicalControl.setValue(!this.auto);
                    this.show_graph = this._settings.showGraph;
                    this.show_table = this._settings.showTable;
                    this.selectedDisplay = [];
                    if (this.show_graph) this.selectedDisplay.push("show_graph")
                    if (this.show_table) this.selectedDisplay.push("show_table")
                    this.chartWidth = this.show_table ? 900 : 1500;
                    this.chartHeight = this.show_table ? 220 : 300;
                    this.daysCount = this._settings.daysCount;
                    this.selectedPosition = this._settings.selectedPosition;
                    this.selectedTickersCode = this._settings.selectedTickersCode?.split(',').map(s => s.trim());
                    this.selectedTickersCodeControl.setValue(this.selectedTickersCode);
                    this.selectedDisplayControl.setValue(this.selectedDisplay);
                    this.daysCountControl.setValue(this.daysCount);

                }
            });

        if (!this._daily_sub)
            // подписываемся на изменение списка тикеров
            this._daily_sub = this._store.select('tickers').subscribe(tickers => {
                if (tickers.length > 0) {
                    this.tickerList = Object.assign([], tickers);
                    this.selectedTicker = this.tickerList[0];
                    if (this.selectedTicker != null) {
                        //this.selectedTickerCode = this.selectedTicker.code;
                        this._minGetService.execute(new CodeParam(this.tickerList[0].code))
                        if (!this.selectedDt || this.selectedDt.getUTCDate() == new Date().getUTCDate())
                            this._maxGetService.execute(new CodeParam(this.tickerList[0].code))
                    }
                }
            });


        if (!this._daily_sub3)
            // подписываемся на изменение данных daily
            this._daily_sub3 = this._store.select('dailies').subscribe(dailies => {
                // console.log(this.totals,dailies)
                this.dailies = Object.assign([], dailies);
                this.dataList = [];
                for (let x of this.totals) {
                    const d = UtilService.getLast<Daily>(dailies.filter((d: Daily) => d.tickerCode == x.tickerCode), new Daily());
                    //console.log(d)
                    let data = [
                        {
                            type: 'line',
                            name: 'Лонг',
                            color: '#77a45b',
                            data: d.table.map((t: any) => {
                                return {
                                    time: UtilService.formatUtcFromSring(t.datetime).getTime() / 1000,
                                    // time: UtilService.formatToSring(UtilService.formatFromSring(t.datetime)),
                                    value: (this.selectedPosition != 'YUR' ? t.fpos_long : t.ypos_long)
                                };
                            }).sort((a: any, b: any) => a.datetime_int - b.datetime_int)
                        },
                        {
                            type: 'line',
                            name: 'Шорт',
                            color: '#d46f6c',
                            data: d.table.map((t: any) => {
                                return {
                                    time: UtilService.formatUtcFromSring(t.datetime).getTime() / 1000,
                                    value: (this.selectedPosition == 'FIZ' ? t.fpos_short : t.ypos_short)
                                };
                            }).sort((a: any, b: any) => a.datetime_int - b.datetime_int)
                        }
                    ];
                    //console.log(data)
                    this.dataList.push({
                        'total': x,
                        'graph': data
                    })
                }
                this._store.dispatch(LOADER_SHOW({'show': true}));
                this.loading = false;
            })

        if (!this._daily_sub2)
            // подписываемся на изменение данных total
            this._daily_sub2 = this._store.select('totals').subscribe(totals => {
                let totalList = Object.assign([], totals);
                this.dataList = [];
                this.totals = [];
                this.selectedTickersCode?.forEach((selectedTickerCode: string) => {

                    let totalListFiltered = totalList.filter((t: Total) => t.tickerCode == selectedTickerCode
                        && UtilService.formatDtToNumDate(UtilService.formatFromSring(t.table[0].datetime.toString())) ==
                        UtilService.formatDtToNumDate(this.selectedDt));

                    if (totalListFiltered.length > 0) {
                        let total = UtilService.getLast<Total>(totalListFiltered, new Total());
                        this.totals.push(total);
                        this.dataList.push({
                            'total': total,
                            'graph': [[0]]
                        });
                    }
                });
            });

        if (!this._daily_sub1)
            // пописываемся на изменение даты
            this._daily_sub1 = this._store.select('dt').subscribe(dt => {
                if (dt.getTime() != this.selectedDt.getTime()) {
                    this.selectedDt = Object.assign(dt);
                    this.tradeDateControl = new FormControl(this.selectedDt);
                    if (this.auto) {
                        this.tradeDateControl.disable();
                    }
                    this.populate();
                }
            })

        if (!this._daily_sub6)
            this._daily_sub6 = this._store.select('maxDt').subscribe(dt => {
                this.maxDate = Object.assign(dt);
            })

        if (!this._daily_sub7)
            this._daily_sub7 = this._store.select('minDt').subscribe(dt => {
                this.minDate = Object.assign(dt);
            })
    }

    dateChange($event: MatDatepickerInputEvent<any, any>) {
        this.selectedDt = $event.value;
        this._store.dispatch(DT_INIT({dt: this.selectedDt}))
        this.populate();
    }

    private populate() {
        if (this.daysCount > 10 && !this.isPayed) {
            this.daysCount = 10;
            this._store.dispatch(MESSAGE_SET({message: "Нельзя выбрать больше 10"}))
        }
        if (this.selectedTickersCode?.length > 0) {
            this.selectedDt = this.tradeDateControl.value;
            if (this.selectedDt) {
                this._titleService.setTitle(`${this.selectedTickersCode.join(', ')} - Дневной - ${this.selectedDt.toLocaleDateString()} < Betatrader.ru`);
                this.selectedTickersCode.forEach((selectedTickerCode: string) => {
                    if (selectedTickerCode) {
                        this._totalGetService.execute(new CodeDtParam(selectedTickerCode, this.selectedDt));
                        if (this.show_graph)
                            this._dailyGetService.execute(new DailyParam(selectedTickerCode, this.selectedDt, this.daysCount))
                    }
                })
            }
        }
    }

    ngOnDestroy(): void {
        this._daily_sub?.unsubscribe();
        this._daily_sub1?.unsubscribe();
        this._daily_sub2?.unsubscribe();
        this._daily_sub3?.unsubscribe();
        this._daily_sub4?.unsubscribe();
        this._daily_sub5?.unsubscribe();
        this._daily_sub6?.unsubscribe();
        this._daily_sub7?.unsubscribe();
        this._daily_sub8?.unsubscribe();
        this._traderHubServiceService.disconnect();
    }

    selectedPositionName(position: string) {
        for (let x of this.positions) {
            if (x.code == position) {
                return x.name
            }
        }
        return ""
    }

    selectedPositionFullName(position: string) {
        for (let x of this.positions) {
            if (x.code == position) {
                return x.fullname
            }
        }
        return ""
    }

    selectedTickerName(ticker: string) {
        for (let x of this.tickerList) {
            if (x.code == ticker) {
                return x.name
            }
        }
        return ""
    }


    private changeSettings() {
        if (this._settings) {
            let settings = this._settings;
            let needUpdate = false;
            if (
                settings.selectedTickersCode != this.selectedTickersCode?.join(', ')
                || settings.daysCount != this.daysCount
                || settings.selectedPosition != this.selectedPosition
                || settings.auto != this.auto
                || settings.showTable != this.show_table
                || settings.showGraph != this.show_graph
            ) {
                needUpdate = true;
                settings.selectedTickersCode = this.selectedTickersCode?.join(', ');
                settings.daysCount = this.daysCount;
                settings.selectedPosition = this.selectedPosition;
                settings.showTable = this.show_table;
                settings.showGraph = this.show_graph;
                settings.auto = this.auto;
            }

            if (needUpdate) {
                this._store.dispatch(SET_SETTING({settings: JSON.stringify(settings)}));
            }
        }
    }


    update($event: any) {
        // console.log($event)
        if (!this.loading && this.isPayed /*&& !this.endLoad*/) {
            this.loading = true;
            this._store.dispatch(LOADER_SHOW({show: false}));
            this.daysCount = Number(this.daysCount) + Math.round(Math.abs($event.from) + 0.5);
            this.daysCountControl.setValue(this.daysCount);
            // this.changeParams();
            this.populate();
        }
    }

    remove(selectedTicker: any) {
        this.selectedTickersCode = this.selectedTickersCode.filter((c: string) => c != selectedTicker)
        if (this.tickerSelect) {
            this.tickerSelect.close()
        }
    }
}
