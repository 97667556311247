import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoginParam, LoginService} from "../../core/ngrx/services/auth/login.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../../core/services/auth.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    LoginService,
  ]
})

export class LoginComponent implements OnInit, OnDestroy {
  checkoutForm;
  cnt: number = 1;
  private _sub1: Subscription|undefined;


  constructor(
    private _formBuilder: FormBuilder,
    private _loginService: LoginService,
    private _isAuthService: AuthService,
    private _router: Router
  ) {
    this.checkoutForm = this._formBuilder.group({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
      recaptchaReactive: new FormControl(null),
    });
  }

  ngOnInit(): void {

    this._loginService.logout()
    this._sub1 = this._isAuthService.item$.subscribe(u => {
      if (u) {
        this._router.navigate(['daily'])
      } else {
        this._router.navigate(['login'])
      }
    })
  }

  onSubmit(customerData: any) {
    this.cnt++;
    if (this.cnt>4){
      this.checkoutForm.controls.recaptchaReactive.setValidators([Validators.required])
    }
    if (this.checkoutForm.valid)
      this._loginService.execute(customerData)
  }

  ngOnDestroy(): void {
    this._sub1?.unsubscribe();
  }
}
