import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {USER_INIT} from "../../store/action/user.action";
import {User} from "../../../models/user";
import {UPDATE_SETTING} from "../../store/action/setting.action";
import {Settings} from "../../../models/settings";

@Injectable()
export class UserGetService extends BaseService<object> {
  override execute(obj: object) {
    this.url = "/me"
    super.execute(obj)
    this.get(obj).subscribe(u => {
      this._store.dispatch(USER_INIT({user: u}));
    });
  }

  checkTestDrive(obj: object, user: User) {
    this.url = "/can_test_drive"
    super.execute(obj)
    this.get(obj).subscribe(b => {
      user.canTestDrive = b;
      this._store.dispatch(USER_INIT({user: user}))
    })
  }
}
