import { createAction, props } from "@ngrx/store";
import {Page} from "../../../models/page";

export const PAGE_INITLIST = createAction(
    "[Page].InitList",
    props<{pages:Page[]}>()
)

export const PAGE_INIT = createAction(
    "[Page].Init",
    props<{page:Page}>()
)
