import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, skipWhile, tap} from 'rxjs/operators';

import {HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/core/ngrx/app.state';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {AuthService, TOKEN_NAME} from "./auth.service";
import {MESSAGE_SET} from "../ngrx/store/action/message-set.action";
import {LOADER_REMOVE} from "../ngrx/store/action/loader.action";

// import { LoaderService } from './loader/loader.service';
@Injectable()
export class HttpService {

  private _apiUrl: string = "";

  constructor(
    private _http: HttpClient,
    private _store: Store<AppState>,
    private _isAuthService: AuthService,
    private _router: Router,
    private _translateService: TranslateService
  ) {
  }

  public setApiUrl(apiurl: string) {
    this._apiUrl = apiurl;
  }

  /**
   * Sends a GET request to the specified URL with the provided body and options.
   * Shows a loader while the request is being sent.
   * Calls the onSuccess() method when the request is successful.
   * Calls the onError() method when the request encounters an error.
   * Calls the onEnd() method when the request completes.
   *
   * @param url - The URL to send the GET request to
   * @param body - The body of the request
   * @param options - Additional options for the request
   * @returns An Observable that emits the response from the request
   */
  get(url: string, body: any, options?: any): Observable<any> {
    this.showLoader();
    return this.sendRequest('get', url, null, options).pipe(tap({
      next: (res) => this.onSuccess(res),
      error: (e) => this.onError(e),
      complete: () => this.onEnd()
    }));
  }

  post(url: string, body: any, options?: any): Observable<any> {

    this.showLoader();

    return this.sendRequest('post', url, body, options)
      .pipe(tap({
        next: (res) => this.onSuccess(res),
        error: (e) => this.onError(e),
        complete: () => this.onEnd()
      }));
  }

  put(url: string, body: any, options?: any): Observable<any> {

    this.showLoader();

    return this.sendRequest('put', url, body, options)
      .pipe(tap({
        next: (res) => this.onSuccess(res),
        error: (e) => this.onError(e),
        complete: () => this.onEnd()
      }));
  }

  delete(url: string, body: any, options?: any): Observable<any> {

    this.showLoader();
    /*if(options == undefined) {
        options = new RequestOptions({body:body});
    }else{
        options.body = body;
    }*/

    return this.sendRequest('delete', url, body, options)
      .pipe(tap({
        next: (res) => this.onSuccess(res),
        error: (e) => this.onError(e),
        complete: () => this.onEnd()
      }));
  }

  private requestOptions(options?: any): any {

    if (options == null) {
      //options = new AngularReduxRequestOptions();
      // let token = localStorage.getItem(TOKEN_NAME)
      let token = AuthService.getCookie(TOKEN_NAME);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, PUT, GET, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
        responceType: 'json',
        // observe: 'response'
      });
      if (token != null && token.length > 0) {
        headers = headers.set('Authorization', 'Bearer ' + token)
      }

      options = {
        headers: headers,
        withCredentials: true
      };
    }

    // if (options.headers == null) {
    //     //console.log("headers is empty");
    //     options.headers = new HttpHeaders();
    // }
    //console.log(options.headers);
    return options;
  }

  private _sendRequest(method: 'get' | 'post' | 'put' | 'delete', url: any, body?: any, options?: any): Observable<any> {
    const req = new HttpRequest(method, url, body, this.requestOptions(options));
    return this._http.request(req);
  }

  private sendRequest(method: 'get' | 'post' | 'put' | 'delete', url: any, body?: any, options?: any): Observable<any> {
    return this._sendRequest(method, this.getFullUrl(url), body, options).pipe(
      skipWhile(res => res == undefined || res.body == undefined),
      map(r => r.body)
    );
  }

  private getFullUrl(url: string): string {
    return this._apiUrl + url;
  }

  /*

    private onCatch(error: any, caught: Observable<any>): Observable<any> {
      throw throwError(error);
    }
  */

  private onSuccess(res: ArrayBuffer): void {
    // console.log('Request successful');
  }

  private onError(res: HttpErrorResponse): void {
    this.hideLoader();
    let message = ""
    /*if (res.status == 401) {
        message = "User Is Not Authenticated"
        this._isAuthService.setIsAuth(false);
        this._router.navigate(['login'])
    } else*/
    if (res.status == 403) {
      message = "Доступ запрещен";
    } else if (res.status == 401) {
      message = "Не верный логин или пароль";
    } else {
      console.log('Error, status code: ' + res.status);
      console.log(res);
      if (res.error && typeof (res.error) == 'string')
        message = res.error
      else if (res.error && res.error.hasOwnProperty('innerException') && res.error.innerException)
        message = res.error.innerException
      else if (res.error && res.error.hasOwnProperty('message') && res.error.message)
        message = res.error.message
      else if (res.error && res.error.hasOwnProperty('title') && res.error.title)
        message = res.error.title
      else if (res.error && res.error.hasOwnProperty('detail') && res.error.detail) {
        if (Array.isArray(res.error.detail)) {
          message = res.error.detail[0].msg
        } else {
          message = res.error.detail
        }

      } else if (res.status == 0) {
        message = "API not avaliable";
        if (this._translateService)
          message = this._translateService.instant("API not avaliable");

      } else
        message = "Error ocured on request"
    }

    this._store.dispatch(MESSAGE_SET({message}))
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    // this._store.dispatch(LOADER_ADD())
  }

  private hideLoader(): void {
    this._store.dispatch(LOADER_REMOVE())
  }

}
