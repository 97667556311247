import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {AppState} from "./core/ngrx/app.state";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  private _sub1: Subscription | undefined;
  private _sub2: Subscription | undefined;

  constructor(
    public translate: TranslateService,
    private _store: Store<AppState>,
    private _snackBar: MatSnackBar,
    private router: Router,
    private titleService: Title
  ) {
    this.translate.use('ru');
  }

  ngOnInit() {
    this._sub1 = this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
              if (route.firstChild)
                  route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`${title} < Betatrader.ru`);
        } else {
          this.titleService.setTitle(`Betatrader.ru`);
        }
      });
    this._sub2 = this._store.select('message').subscribe(m => {
      if (m != "")
        this._snackBar.open(
          this.translate.instant(m),
          this.translate.instant('Закрыть'),
          {duration: 7000});
    })
  }

  ngOnDestroy(): void {
    this._sub1?.unsubscribe();
    this._sub2?.unsubscribe();
  }
}
