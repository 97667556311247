import { createReducer, on } from "@ngrx/store";
import {Bar} from "../../../models/bar";
import {BAR_INIT} from "../action/bar.action";

export const initialState: ReadonlyArray<Bar> = []

export const barReducer = createReducer(
    initialState,
    on(BAR_INIT, (state, { bar }) => {
        if (bar == null) return state;
        // if (!state.find(x => x.tickerCode == bar.tickerCode && x.dt == bar.dt))
        if(state.indexOf(bar) == -1)
            return [...state, bar];
        return state.map(x => x.tickerCode == bar.tickerCode && x.dt == bar.dt? bar : x);
    }),
)
