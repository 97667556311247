import { createReducer, on } from "@ngrx/store";
import {Notify} from "../../../models/notify";
import {NOTIFY_INIT} from "../action/notify.action";

export const initialState: ReadonlyArray<Notify> = []

export const notifyReducer = createReducer(
    initialState,
    on(NOTIFY_INIT, (state, { notifies }) => {
      return notifies;
    }),
)
