import { createReducer, on } from "@ngrx/store";
import { LOADER_ADD, LOADER_REMOVE } from "../action/loader.action";

export const initialState: number = 0;


function Mutex(this: any) {
    let current = Promise.resolve();
    this.lock = () => {
        let _resolve: () => void;
        const p = new Promise<void>(resolve => {
            _resolve = () => resolve();
        });
        // Caller gets a promise that resolves when the current outstanding
        // lock resolves
        const rv = current.then(() => _resolve);
        // Don't allow the next request until the new promise is done
        current = p;
        // Return the new promise
        return rv;
    };
}

export const loaderReducer = createReducer(
    initialState,
    on(LOADER_ADD, (state) => {
        let r = state + 1
        // console.log("LOADER_ADD",state)
        return r
    }),
    on(LOADER_REMOVE, (state) => {
        let r = state - 1
        // console.log("LOADER_REMOVE",state)
        return r
    })
)
