import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../core/ngrx/app.state";
import {ActivatedRoute, Router} from "@angular/router";
import {ChangeUserParams, ChangeUserService} from "../../core/ngrx/services/user/change-user.service";
import {MESSAGE_SET} from "../../core/ngrx/store/action/message-set.action";

@Component({
  selector: 'confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  providers: [ChangeUserService]

})
export class ConfirmComponent implements OnInit{
  token: string = "";

  constructor(private _store: Store<AppState>,
              private _activatedRoute: ActivatedRoute,
              private _changeUser: ChangeUserService,
              private _router: Router
  ) {
    this._activatedRoute.params.subscribe(p => {
      this.token = p['token'];
    });
  }

  ngOnInit(): void {
    this._changeUser.execute(new ChangeUserParams(this.token, 10)).subscribe(r => {
      if (r.result) {
        this._store.dispatch(MESSAGE_SET({message: "Email подтвержден"}));
        this._router.navigate(['/login']).then();
      } else {
        this._store.dispatch(MESSAGE_SET({message: r.message}))
      }
    })
  }
}
