import { createReducer, on } from "@ngrx/store";
import {Page} from "../../../models/page";
import {PAGE_INIT, PAGE_INITLIST} from "../action/page.action";
import {User} from "../../../models/user";
import {USER_INIT} from "../action/user.action";

export const initialState: Readonly<User> = new User()

export const userReducer = createReducer(
    initialState,
    on(USER_INIT, (state, { user }) => user),
)
