import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PageGetService} from "../../core/ngrx/services/page/page-get.service";
import {AppState} from "../../core/ngrx/app.state";
import {Store} from "@ngrx/store";
import {Title} from "@angular/platform-browser";
import {CodeParam} from "../../core/models/code-param";
import {Subscription} from "rxjs";

@Component({
  selector: 'page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  providers: [PageGetService]
})
export class PageComponent implements OnInit, OnDestroy {
  public tmpl: string = "";
  private _sub1: Subscription | undefined;
  private _sub2: Subscription | undefined;
  private _sub3: Subscription | undefined;
  private _code: any;

  constructor(
    private _store: Store<AppState>,
    private _router: Router,
    private _route: ActivatedRoute,
    private _titleService: Title,
    private _pageService: PageGetService) {
    this._sub1 = _route.params.subscribe(params => {
      this._code = params['code'];
      this.populate(this._code);
    })

    this._sub2 = _router.events.subscribe((val: any) => {
      if (val.url != null) {
        if (this._code != val.url.split('/')[2]) {
          this._code = val.url.split('/')[2];
          this.populate(this._code);
        }
      }
    });
  }

  ngOnInit(): void {
    this._sub3 = this._store.select('pages').subscribe(pages => {
      console.log(pages);
      let page = pages.find(pp => pp.code == this._code)
      if (page != null) {
        this.tmpl = page.tmpl
        this._titleService.setTitle(`${page.title} < Betatrader.ru`);
      }
    });
  }

  populate(code: string): void {

    this._pageService.execute(new CodeParam(this._code))
  }

  ngOnDestroy(): void {
    this._sub1?.unsubscribe();
    this._sub2?.unsubscribe();
    this._sub3?.unsubscribe();
  }

}
