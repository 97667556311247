<div class="h100">
  <div class="wrapper">
    <h2 mat-dialog-title>{{ 'Signup' |translate}}</h2>

    <p>Пожалуйста, заполните все поля для регистрации на сайте:</p>

    <!-- <mat-dialog-content> -->
    <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">

      <mat-form-field appearance="outline">
        <mat-label>{{'Email'|translate}}</mat-label>
        <input matInput [placeholder]="'Email'|translate" formControlName="email" required>
        <mat-error *ngIf="checkoutForm.controls['email'].invalid && checkoutForm.controls['email'].touched">
          {{'Invalid'|translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'Password'|translate}}</mat-label>
        <input matInput [placeholder]="'Password'|translate" formControlName="password" required type="password"  autocomplete="on">
        <mat-error *ngIf="checkoutForm.controls['password'].invalid && checkoutForm.controls['password'].touched">
          <span *ngIf="checkoutForm.controls['password'].errors.required">{{'Required'|translate}}.</span>
          <span *ngIf="checkoutForm.controls['password'].errors.minlength">{{'MinLengthTemplate'|translate: {length: 5} }}.</span>
        </mat-error>
      </mat-form-field>

      <div>Регистрируясь на сайте Вы принимаете условия <a routerLink="/page/agreement">Пользовательского соглашения</a>.</div>

      <mat-dialog-actions>
        <button mat-raised-button color="primary" type="submit">{{'Signup'|translate}}</button>
      </mat-dialog-actions>

    </form>

    <div class="gray">После регистрации Вам необходимо будет подтвердить указанный email. Ссылка для подтверждения придёт в письме.</div>
    <!-- </mat-dialog-content> -->

  </div>
</div>
