import {createReducer, on, UPDATE} from "@ngrx/store";
import {Settings} from "../../../models/settings";
import {SET_SETTING, UPDATE_SETTING} from "../action/setting.action";

export const initialState: Readonly<string> = "";
export const settingReducer = createReducer(
  initialState,
  on(SET_SETTING, (state, {settings}) => {
    return settings;
  }),
  on(UPDATE_SETTING, (state, {settings}) => {
    return settings;
  }),
)
