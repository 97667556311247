import { Component, OnInit } from '@angular/core';
import {LoginService} from "../../core/ngrx/services/auth/login.service";
import {Title} from "@angular/platform-browser";
import {SignupParam, SignupService} from "../../core/ngrx/services/signup/signup.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {AuthService} from "../../core/services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [SignupService]
})
export class SignupComponent implements OnInit {
  checkoutForm: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _titleService: Title,
    private _signupService: SignupService,
    private _router: Router) {
      this.checkoutForm = this._formBuilder.group({
        email: new FormControl("", [Validators.required, Validators.email]),
        password: new FormControl("", [Validators.required, Validators.minLength(5)]),
      });
    }

  ngOnInit(): void {
    this._titleService.setTitle(`Регистрация < Betatrader.ru`);
  }

  onSubmit(value: any) {
    this._signupService.execute(new SignupParam(value.email, value.password));
  }
}
