import {createReducer, on} from "@ngrx/store";
import {Timerange} from "../../../models/timerange";
import {TIMERANGE_INIT} from "../action/timerange.action";
import {Table3} from "../../../models/table3";

export const initialState: Readonly<Timerange> = new Timerange()
export const timerangeReducer = createReducer(
  initialState,
  on(TIMERANGE_INIT, (state, {timerange}) => {
    if (timerange == null) return state;

    if (state.tickerCode == timerange.tickerCode && state.clgroup == timerange.clgroup && state.type == timerange.type && timerange.page !== 1) {
      let array = Object.assign([], state.table);
      timerange.table.forEach(x => {
        // let found: Table3 = Object.assign({Table3}, array.find((y: Table3) => y.tradedate === x.tradedate && y.tradetime === x.tradetime));
        let found =  array.find((y: Table3) => y.tradedate === x.tradedate && y.tradetime === x.tradetime);

        if (!found)
          array.push(x);
        // else {
        //   found.open = x.open;
        //   found.min = x.min;
        //   found.max = x.max;
        //   found.close = x.close;
        // }
      })
      let result = new Timerange(timerange.clgroup, timerange.tickerCode, array.sort((a: Table3,b: Table3) => a.id - b.id), timerange.page, timerange.type);
      // console.log(result);
      return result;
    }
    // console.log("timerangeReducer2: ", timerange);
    return timerange;
  }),
)
