import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from './modules/material.module';
import {TranslateModule} from '@ngx-translate/core';
import {MessageDialogComponent} from "./components/message-dialog/message-dialog.component";

@NgModule({
    declarations: [MessageDialogComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MaterialModule
    ],
    exports: [MaterialModule, TranslateModule]
})
export class SharedModule {
}
