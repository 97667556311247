import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { AppState } from "../ngrx/app.state";
import { AuthService } from "./auth.service";
import { HttpService } from "./http.service";
import { Router } from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

function httpServiceFactory(httpClient: HttpClient, store: Store<AppState>, isAuthService:AuthService, router:Router, translateService: TranslateService) {
    return new HttpService(httpClient, store, isAuthService, router, translateService);
}

export { httpServiceFactory };
