import {Table2} from "./table2";
import {IData} from "./i-data";

export class Bar implements IData {
  table: Table2[] = [];

  constructor(dt?: Date, ticker?: string, table?: Table2[]) {
    this.dt = dt ?? new Date();
    this.tickerCode = ticker ?? "";
    this.table = table ?? [];
  }

  dt: Date = new Date();
  tickerCode: string = "";
}
