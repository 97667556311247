import { createReducer, on } from "@ngrx/store";
import {Page} from "../../../models/page";
import {PAGE_INIT, PAGE_INITLIST} from "../action/page.action";
import {PAGE_LOADED} from "../action/need-load.action";

export const initialState: Readonly<number> = 0

export const loadedCountReducer = createReducer(
    initialState,
    on(PAGE_LOADED, (state, { loadedCount }) => loadedCount),
)
