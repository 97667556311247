import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import {SET_SETTING} from "../ngrx/store/action/setting.action";
import {Actions, createEffect, ofType} from "@ngrx/effects";

@Injectable()
export class ChageSettingsEffect {
    
    constructor(
      private _actions: Actions
    ) { }

  chageSettingsEffect = createEffect(() =>
        this._actions.pipe(
            ofType(SET_SETTING),
            exhaustMap(action => {
                return of(action);
            })
        ),
        { dispatch: false }
    );

}
