import {
  AfterContentChecked,
  AfterContentInit, AfterViewChecked,
  AfterViewInit, ChangeDetectorRef,
  Component, DoCheck, ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ColorType,
  createChart,
  CrosshairMode,
  IChartApi,
  ISeriesApi,
  LineStyle,
  UTCTimestamp
} from "lightweight-charts";
import {MatSelect} from "@angular/material/select";
import {UtilService} from "../../../services/util.service";
import {fromEvent} from "rxjs";

@Component({
  selector: 'light-weight-chart',
  templateUrl: './light-weight-chart.component.html',
  styleUrls: ['./light-weight-chart.component.scss']
})
export class LightWeightChartComponent implements OnDestroy, OnChanges, AfterViewInit, AfterContentChecked {
  @ViewChild('charContainer') charContainer: ElementRef | undefined;
  @ViewChild('tooltip') tooltipContainer: ElementRef | undefined;
  @Input() crosshairMode: CrosshairMode = CrosshairMode.Magnet;
  @Input() height: any = 200;
  @Input() width: any = 900;
  @Input() data: any;
  @Input() type: string | undefined;
  @Input() watermark: string = '';
  @Input() subWatermark: string = '';
  @Input() timeVisible: boolean = false;
  @Input() toolTipWithDiff: boolean = false;
  @Input() showToolTip: boolean = false;
  @Input() handleScroll: boolean = false;
  @Input() itemsCount: number | undefined;
  @Input() bgColor: string = 'transparent';
  @Input() from: number = 0;
  @Input() to: number = 0;
  @Input() upColor: string = '#77a45b';
  @Input() downColor: string = '#d46f6c';
  private chart: IChartApi | undefined;
  private series: ISeriesApi<any>[] | undefined;
  @Output() onChanged = new EventEmitter();
  @Output() updateVisibleRange = new EventEmitter();
  private changed: boolean = false;
  subWatermarkStyle: any;
  watermarkStyle: any;
  private _timeScaleUpdated: number = 0;
  private _subscribeCrosshairMove: void | undefined;
  private _subscribeVisibleLogicalRangeChange: void | undefined;
  private _subscribeVisibleTimeRangeChange: void | undefined;
  private _myPriceFormatter = Intl.NumberFormat(window.navigator.languages[0], {
    minimumFractionDigits: 0,
  }).format;

  public firstTime: boolean = true;
  @Input() showMarkers: boolean = false;
  @Input() priceMaxValue: number | undefined;
  @Input() priceMinValue: number | undefined;

  private unsubscribeAll() {
    if (this._subscribeCrosshairMove) {
      this.chart?.unsubscribeCrosshairMove(this._subscribeCrosshairMove);
    }
    if (this._subscribeVisibleTimeRangeChange) {
      this.chart?.timeScale().unsubscribeVisibleTimeRangeChange(this._subscribeVisibleTimeRangeChange);
    }
    if (this._subscribeVisibleLogicalRangeChange) {
      this.chart?.timeScale().unsubscribeVisibleLogicalRangeChange(this._subscribeVisibleLogicalRangeChange);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  ngAfterContentChecked() {
    if (this.changed && this.chart) {
      this.series?.forEach((item: any) => {
        if (item)
          this.chart?.removeSeries(item);
      });
      this.series = [];
      if (this.data?.length > 0) {
        this.data.forEach((item: any) => {
          if (item.data?.length > 0) {
            if (this.firstTime) {
              if (this.from > 0 && this.to > 0) {
                let from = item.data.find((i: any) => i.time == this.from)
                let ifrom = item.data.indexOf(from);
                if (ifrom == -1) {
                  this.onChanged?.emit({from: this.from, to: this.to});
                }
                let to = item.data.find((i: any) => i.time == this.to);
                let ito = item.data.indexOf(to);
                if (ifrom > -1 && ito > -1) {
                  ifrom = ifrom == -1 ? 0 : ifrom;
                  ito = ito == -1 ? item.data.length - 1 : ito;
                  setTimeout(() => {
                    let timeScale = this.chart?.timeScale();
                    timeScale?.setVisibleLogicalRange({from: ifrom, to: ito});
                    this.firstTime = false;
                  }, 0);
                }
              } else {
                this.fitScale(item);
                this.firstTime = false;
              }
            }

            let series: ISeriesApi<any> | undefined;
            if (this.type)
              item.type = this.type;
            if (item.type == 'line') {
              series = this.chart?.addLineSeries({
                // lineType: 1,
                // lineStyle: LineStyle.Dotted,
                // crosshairMarkerRadius: 10,
                lineWidth: 2,
                // title: item?.name ?? '',
                color: item?.color ?? 'lightblue'
              });
            } else if (item.type == 'area') {
              series = this.chart?.addAreaSeries({
                title: item?.name ?? ''
              });
            } else if (item.type == 'bar') {
              series = this.chart?.addBarSeries({
                thinBars: true,
                title: item?.name ?? '',
                upColor: this.upColor,
                downColor: this.downColor,
              });

            } else if (item.type == 'candleStick') {
              series = this.chart?.addCandlestickSeries({
                upColor: this.upColor,
                borderUpColor: this.upColor,
                wickUpColor: this.upColor,
                downColor: this.downColor,
                borderDownColor: this.downColor,
                wickDownColor: this.downColor,
              });
            } else if (item.type == 'histogram') {
              series = this.chart?.addHistogramSeries();
              if (this.priceMaxValue && this.priceMinValue) {
                series?.applyOptions({
                  autoscaleInfoProvider: () => ({
                    priceRange: {
                      maxValue: this.priceMinValue,
                      minValue: this.priceMaxValue,
                    },
                    AutoScaleMargins: {
                      above: 0,
                      below: 0
                    }
                  })
                });
                series?.applyOptions({
                  autoscaleInfoProvider: () => ({
                    priceRange: {
                      minValue: this.priceMaxValue,
                      maxValue: this.priceMinValue,
                    },
                    AutoScaleMargins: {
                      above: 0,
                      below: 0
                    }
                  })
                });
                this.chart?.applyOptions({
                  rightPriceScale: {
                    scaleMargins: {
                      top: 0.05,
                      bottom: 0.05,
                    },
                    invertScale: true,
                  },
                });
              }
            }
            if (series) {
              series.setData(item.data);
              this.series?.push(series);
              if (item.type == 'line' && this.showMarkers) { //set marker
                const markers: any = [];
                item.data.forEach((i: any) => {
                  markers.push({
                    time: i.time,
                    position: "inBar",
                    color: item?.color,
                    shape: 'circle',
                    size: 0.1,
                  })
                });
                series.setMarkers(markers);
              }
              if (this.itemsCount) {
                let timeScale = this.chart?.timeScale();
                timeScale?.setVisibleLogicalRange({from: 0, to: this.itemsCount});
              }


            }

          }
        });
      }

      this.chart.applyOptions({

        timeScale: {
          timeVisible: this.timeVisible,
          rightBarStaysOnScroll: true,
          borderColor: '#a9a9a9',
        },
        rightPriceScale: {
          borderColor: '#a9a9a9',
        },
        layout: {
          background: {
            color: this.bgColor,
          },
          textColor: '#a9a9a9',
        },
        handleScroll: {
          mouseWheel: this.handleScroll,
          pressedMouseMove: this.handleScroll,
          horzTouchDrag: this.handleScroll,
          vertTouchDrag: this.handleScroll
        },
        handleScale: {
          axisPressedMouseMove: this.handleScroll,
          mouseWheel: this.handleScroll,
          pinch: this.handleScroll,
        },
        crosshair: {
          mode: this.crosshairMode,
        },
        grid: {
          horzLines: {
            color: '#a9a9a9',
            style: 4,
          },
          vertLines: {
            color: '#a9a9a9',
            style: 4,
          },
        },
      });
      this.changed = false;
    }

  }

  public fitScale(i: any) {
    let timeScale = this.chart?.timeScale();
    if (timeScale) {
      if (i) {
        if (i.data?.length > 1)

          timeScale?.setVisibleLogicalRange({from: 1, to: i.data?.length - 1});
      } else {
        this.data?.forEach((item: any) => {
          if (item.data?.length > 0)
            timeScale?.setVisibleLogicalRange({from: 1, to: item.data?.length - 1});
        });
      }
    }
  }

  ngOnChanges(): void {
    this.changed = true;
    let watermarkWidth = this.watermark.length * 14;
    let subWatermarkWidth = this.subWatermark.length * 12;
    this.watermarkStyle = {
      'padding-top': (this.height - 100) / 2 - 24 + 'px',
      'padding-left': (this.width - watermarkWidth) / 2 + 'px',
    }
    this.subWatermarkStyle = {
      'padding-top': (this.height - 100) / 2 + 'px',
      'padding-left': (this.width - subWatermarkWidth) / 2 + 'px',
    }
  }

  ngAfterViewInit(): void {
    this.unsubscribeAll();
    if (this.charContainer?.nativeElement) {
      this.chart = createChart(this.charContainer?.nativeElement, {
        width: this.width,
        height: this.height,
      });
      // console.log('ngOnInit chart=', this.chart);


      this.chart.applyOptions({
        localization: {
          priceFormatter: this._myPriceFormatter,
        }
      });


      if (this.showToolTip || this.toolTipWithDiff) {
        let toolTip = this.tooltipContainer?.nativeElement
        toolTip.className = 'floating-tooltip-2';
        this._subscribeCrosshairMove = this.chart?.subscribeCrosshairMove((param) => {
          let parent = this.charContainer?.nativeElement.getBoundingClientRect()
          if (param.point === undefined || !param.time || param.point.x < 0 || param.point.x > this.width || param.point.y < 0 || param.point.y > this.height) {
            toolTip.style.display = 'none';
          } else {
            toolTip.style.display = 'block';
            toolTip.style.left = (parent.left + param.point.x) + 'px';
            toolTip.style.top = (parent.top + window.scrollY + param.point.y) + 'px';
            let d = UtilService.formatTo(new Date(<number>param.time * 1000), "dateWithYear+time") + '<br>';
            if (!this.timeVisible) {
              d = UtilService.formatTo(new Date(<number>param.time * 1000), "dateWithYear").toLocaleString() + '<br>';
            }
            // console.log('data=', this.data);
            this.data?.forEach((item: any) => {
              // console.log('item=', item);
              let diffStr = ''
              let idx = item.data.findIndex((x: any) => x.time == param.time);
              //let color = 'green';
              let color = '#77a45b';
              if (item.data[idx].value < 0) {
                //color = 'red';
                color = '#d46f6c';
              }
              if (idx > -1 && idx < item.data.length) {
                if (idx > 0 && this.toolTipWithDiff) {
                  let diff = item.data[idx].value - item.data[idx - 1].value;
                  if (diff > 0) {
                    //diffStr = "(<span style='color: lightseagreen'>+" + diff.toLocaleString() + "</span>)"
                    diffStr = "(<span class='font-green'>+" + diff.toLocaleString() + "</span>)"
                  } else if (diff < 0) {
                    //diffStr = "(<span style='color: red'>" + diff.toLocaleString() + "</span>)"
                    diffStr = "(<span class='font-red'>" + diff.toLocaleString() + "</span>)"
                  }
                }
                toolTip.style.display = 'block';
                d += '<div style="display: flex; justify-content: left; color: ' + (item.type == 'line' ? '#a9a9a9' : color) + '">' +
                  '<div style="margin-right: 10px; background-color:' + item.color + ';-webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px; width: 10px;height: 10px;margin-top:5px"></div>' +
                  '<span style="padding-right: 10px">' + (item?.name ?? '') + '</span>' +
                  //'<span style="padding-right: 10px">' + (item.data[idx].value>0?'+':'') + item.data[idx].value.toLocaleString() + ' <span>' + diffStr + '</span></span>' +
                  '<span style="padding-right: 10px">' + item.data[idx].value.toLocaleString() + ' <span>' + diffStr + '</span></span>' +
                  '</div>';
              }
            });
            toolTip.innerHTML = d;
          }
        });
      }

      if (!this._subscribeVisibleTimeRangeChange)
        this._subscribeVisibleTimeRangeChange = this.chart?.timeScale().subscribeVisibleTimeRangeChange((timeRange) => {
          if (timeRange) {
            this._timeScaleUpdated++;
            if (this._timeScaleUpdated > 2) {
              this.updateVisibleRange?.emit(timeRange);
            }
          }
        });
      if (!this._subscribeVisibleLogicalRangeChange)
        this._subscribeVisibleLogicalRangeChange = this.chart?.timeScale().subscribeVisibleLogicalRangeChange((range) => {
          if (range && this.data.length > 0 && !this.firstTime) {
            if (range.from < 0 && this._timeScaleUpdated > 2)
              this.onChanged?.emit(range);
              //this.onChanged?.emit({from: range.from, to: range.to});
          }
        });
      this.firstTime = true;
    }
  }
}
