import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./features/home/home.component";
import {NotFoundComponent} from "./features/not-found/not-found.component";
import {MainComponent} from "./features/main.component";
import {PageComponent} from "./features/page/page.component";
import {LoginComponent} from "./features/login/login.component";
import {LkComponent} from "./features/lk/lk.component";
import {AuthGuardService} from "./core/services/auth-guard.service";
import {SignupComponent} from "./features/signup/signup.component";
import {BarComponent} from "./features/bar/bar.component";
import {DailyComponent} from "./features/daily/daily.component";
import {CumulativeComponent} from "./features/cumulative/cumulative.component";
import {PayComponent} from "./features/pay/pay.component";
import {PasswordResetComponent} from "./features/password-reset/password-reset.component";
import {ConfirmComponent} from "./features/confirm/confirm.component";
import {DirectionComponent} from "./features/direction/direction.component";
// определение маршрутов
const appRoutes: Routes = [
  {
    path: '', component: MainComponent, children: [
      {path: '', component: DailyComponent},
      {path: 'lk', component: LkComponent, data: {title: 'Мой профиль'}, canActivate: [AuthGuardService]},
      {path: 'login', component: LoginComponent, data: {title: 'Вход'}},
      {path: 'logout', component: LoginComponent, data: {title: 'Выход'}, canActivate: [AuthGuardService]},
      {path: 'lk/pay', component: PayComponent, data: {title: 'Оплата'}, canActivate: [AuthGuardService]},
      {path: 'signup', component: SignupComponent},
      {path: 'direction', component: DirectionComponent},
      {path: 'daily', component: DailyComponent},
      {path: 'bar', component: BarComponent},
      {path: 'cumulative', component: CumulativeComponent},
      {path: 'page/:code', component: PageComponent},
      {path: 'confirm/:token', component: ConfirmComponent},
      {path: 'password-reset/:token', component: PasswordResetComponent},
      {path: 'password-reset', component: PasswordResetComponent},
      {path: '**', component: NotFoundComponent}
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
