import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {TARIFFS_INIT} from "../../store/action/tariffs.action";


@Injectable()
export class TariffService extends BaseService<object> {
  override execute(obj: object) {
    this.url = "/tariff";
    super.execute(obj);
    this.get(obj)
      .subscribe((res: any) => {
        if (res.results) {
          let tariffs = res.results
          this._store.dispatch(TARIFFS_INIT({tariffs}))
        }
      })
  }
}
