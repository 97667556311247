import {Injectable} from "@angular/core";
import {BaseService} from "src/app/core/services/base.service";
import {CodeDtParam} from "../../../models/code-dt-param";
import {Cumulative} from "../../../models/cumulative";
import {CUMULATIVE_INIT} from "../../store/action/cumulative.action";
import {Table2} from "../../../models/table2";
import {UtilService} from "../../../services/util.service";

@Injectable()
export class CumulativeGetService extends BaseService<CodeDtParam> {
    override execute(obj: CodeDtParam) {
        this.url = "/cumulative?ticker={code}&dt={dt}"
        super.execute(obj)
        this.get(obj)
            .subscribe((r: Table2[]) => {
                let t = r.sort(function (a, b) {
                    if (a.time < b.time) return 1
                    if (a.time > b.time) return -1
                    return 0
                })
              let time = new Date();
              // let time = obj.d;
              // if (r.length > 0) time = UtilService.formatFromSring(r[0].datetime.toString())
              let cumulative = new Cumulative(time, obj.code, r)
                this._store.dispatch(CUMULATIVE_INIT({cumulative}))
            })
    }
}
