import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from "./core/shared/shared.module";
import {Store, StoreModule} from "@ngrx/store";
import {loaderReducer} from "./core/ngrx/store/reducer/loader.reducer";
import {pageReducer} from "./core/ngrx/store/reducer/page.reducer";
import {messageSetReducer} from "./core/ngrx/store/reducer/message-set.reducer";
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {Router, Routes} from "@angular/router";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MainModule} from "./features/main.module";
import {HttpService} from "./core/services/http.service";
import {httpServiceFactory} from "./core/services/http-service.factory";
import {AuthService} from "./core/services/auth.service";
import {HttpRequestInterceptor} from "./core/components/loader/http-request.interceptor";
import {ComponentsModule} from "./core/components/components.module";
import {AuthGuardService} from "./core/services/auth-guard.service";
import {userReducer} from "./core/ngrx/store/reducer/user.reducer";
import {tzReducer} from "./core/ngrx/store/reducer/tz.reducer";
import {tickerReducer} from "./core/ngrx/store/reducer/ticker.reducer";
import {LoginService} from "./core/ngrx/services/auth/login.service";
import {totalReducer} from "./core/ngrx/store/reducer/total.reducer";
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import {CustomDateAdapter} from "./core/shared/custom.date.adapter";
import {dtReducer} from "./core/ngrx/store/reducer/dt.reducer";
import {cumulativeReducer} from "./core/ngrx/store/reducer/cumulative.reducer";
import {registerLocaleData} from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import {numCumulativeReducer} from "./core/ngrx/store/reducer/cumulative-num.reducer";
import {barReducer} from "./core/ngrx/store/reducer/bar.reducer";
import {WebsocketService} from "./core/websocket/websocket.service";
import {tariffReducer} from "./core/ngrx/store/reducer/tariff.reducer";
import {orderReducer} from "./core/ngrx/store/reducer/order.reducer";
import {dailyReducer} from "./core/ngrx/store/reducer/daily.reducer";
import {MaxDtService} from "./core/websocket/max-dt.service";
import {UserGetService} from "./core/ngrx/services/user/user-get.service";
import {showLoaderReducer} from "./core/ngrx/store/reducer/show-loader.reducer";
import {notifyReducer} from "./core/ngrx/store/reducer/notify.reducer";
import {settingReducer} from "./core/ngrx/store/reducer/setting.reducer";
import {ChangeUserSettingsService} from "./core/ngrx/services/user/change-user-settings.service";
import {EffectsModule} from "@ngrx/effects";
import {ChageSettingsEffect} from "./core/shared/chage-settings.effect";
import {minDtReducer} from "./core/ngrx/store/reducer/min-dt.reducer";
import {maxDtReducer} from "./core/ngrx/store/reducer/max-dt.reducer";
import {timerangeReducer} from "./core/ngrx/store/reducer/timerange.reducer";
import {needLoadReducer} from "./core/ngrx/store/reducer/need-load.reducer";
import {TraderHubServiceService} from "./core/signal-r/trader-hub-service.service";
import {loadedCountReducer} from "./core/ngrx/store/reducer/loaded.count.reducer";

registerLocaleData(localeRu)

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    TranslateModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule,
    MainModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({
      loader: loaderReducer,
      message: messageSetReducer,
      pages: pageReducer,
      user: userReducer,
      tz: tzReducer,
      tickers: tickerReducer,
      totals: totalReducer,
      cumulatives: cumulativeReducer,
      dt: dtReducer,
      numCumulatives: numCumulativeReducer,
      bars: barReducer,
      tariffs: tariffReducer,
      order: orderReducer,
      dailies: dailyReducer,
      showLoader: showLoaderReducer,
      notifies: notifyReducer,
      setting: settingReducer,
      minDt: minDtReducer,
      maxDt: maxDtReducer,
      timerange: timerangeReducer,
      needLoad: needLoadReducer,
      loadedCount: loadedCountReducer
    }),
    EffectsModule.forRoot([ChageSettingsEffect])
  ],
  providers: [
    AuthService,
    AuthGuardService,
    LoginService,
    UserGetService,
    TranslateService,
    WebsocketService,
    ChangeUserSettingsService,
    MaxDtService,
    TraderHubServiceService,
    provideHttpClient(),
    {
      provide: HttpService,
      useFactory: httpServiceFactory,
      deps: [HttpClient, Store, AuthService, Router]
    },
    {provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'ru'},
    {provide: DateAdapter, useClass: CustomDateAdapter},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
