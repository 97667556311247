import {createReducer, on} from "@ngrx/store";
import {DAILY_INIT} from "../action/daily.action";
import {Daily} from "../../../models/daily";

export const initialState: ReadonlyArray<Daily> = []

export const dailyReducer = createReducer(
  initialState,
  on(DAILY_INIT, (state, {daily}) => {
    if (daily == null) return state;
    for (const st of state){
      if (st.tickerCode == daily.tickerCode) {
        return state.map(x => x.tickerCode == daily.tickerCode ? daily : x);
      }
    }
    return [...state, daily];
  }),
)
